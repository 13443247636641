import React, { useMemo, useState } from 'react'
import { gql } from '@apollo/client'

import { useTranslation } from 'react-i18next'
import { ClipboardCopyIcon } from '@heroicons/react/outline'
import useAdminQuery from '../hooks/useAdminQuery'
import useFetchSystemVendorReviews from '../hooks/useFetchVendorSystemReviews'

import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import SystemsReviewsFilter from '../components/reviews/your-reviews/filter'
import ReviewList from '../components/reviews/your-reviews/list'
import ReviewerList from '../components/reviews/your-reviews/reviewer/list'
import Tabs from '../components/shared/tabs'
import Flag from '../components/shared/flag'
import useOverlayNotification from '../hooks/useOverlayNotification'

const GET_SYSTEMS = gql`
  query SystemsData($companyId: ID, $userId: ID, $systemId: ID) {
    appSystemsOnVendor(companyId: $companyId, userId: $userId, systemId: $systemId) {
      id
      systemSiteData {
        id
        locale
        systemName
        systemNameUrl
        site {
          domain
        }
      }
    }
  }
`

const LangLink = ({ locale, domain, systemNameUrl }) => {
  const { showSuccessNotification } = useOverlayNotification()

  const systemProfileUrl = `https://${domain}/system/${systemNameUrl}`
  const writerReviewUrl = `${systemProfileUrl}/write-review`

  return (
    <div className="flex gap-2 mr-5">
      <a
        className="hover:underline pointer"
        href={writerReviewUrl}
        target="_blank"
        rel="noreferrer"
        title="Go to your review page"
      >
        <Flag locale={locale} height={'40px'} title="Go to your review page" />
      </a>
      <button
        type="button"
        className="relative flex border-0 z-1 hover:bg-transparent focus:bg-transparent"
        title="Copy link to clipboard"
        onClick={() => {
          const cb = navigator.clipboard
          cb.writeText(writerReviewUrl).then(
            () => {
              showSuccessNotification({
                titleTid: 'content:success.successfullyCopied'
              })
            },
            (err) => {
              console.error('error with copy: ', err)
            }
          )
        }}
      >
        <ClipboardCopyIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </button>
    </div>
  )
}

function SystemsReviewsPage() {
  const { t } = useTranslation(['content'])
  const [filteredSystemsReviews, setFilteredSystemsReviews] = useState(null)
  const [activeTab, setActiveTab] = useState('REVIEWS')

  const { vendorSystemReviews } = useFetchSystemVendorReviews()

  const { data: availableSystemsData, loading: availableSystemsLoading } =
    useAdminQuery(GET_SYSTEMS)
  const availableSystems = availableSystemsData?.appSystemsOnVendor

  const allSystemsReviews = useMemo(() => {
    if (!vendorSystemReviews || !availableSystems) {
      return []
    }

    const systemGrouppedReviewsMap = vendorSystemReviews.reduce((acc, review) => {
      const systemId = review.systemV2Id

      if (!acc[systemId]) {
        acc[systemId] = []
      }

      acc[systemId].push(review)

      return acc
    }, {})

    return availableSystems.map((system) => {
      return {
        ...system,
        reviews: systemGrouppedReviewsMap[system.id] || []
      }
    })
  }, [availableSystems, vendorSystemReviews])

  const reviewLinksSystemData = useMemo(() => {
    return availableSystems?.map((system) => {
      return {
        ...system,
        systemSiteData: Array.from(system?.systemSiteData)?.sort((a, b) =>
          a.locale.localeCompare(b.locale)
        )
      }
    })
  }, [availableSystems])

  const reviewerData = useMemo(() => {
    if (!vendorSystemReviews) {
      return []
    }

    let reviewers = []

    vendorSystemReviews.forEach((review) => {
      reviewers.push({ email: review.email, verification: review.verification })
    })

    // Randomize the emails
    for (var i = reviewers.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1))
      let temp = reviewers[i]
      reviewers[i] = reviewers[j]
      reviewers[j] = temp
    }

    return reviewers.map((reviewer) => ({ ...reviewer }))
  }, [vendorSystemReviews])

  const handleTabChange = ({ value }) => {
    setActiveTab(value)
  }

  const tabs = useMemo(() => {
    return reviewerData.length > 0
      ? [
          { label: t('reviews:tabs:reviews'), value: 'REVIEWS' },
          { label: t('reviews:tabs.reviewer'), value: 'REVIEWER' }
        ]
      : [{ label: t('reviews:tabs:reviews'), value: 'REVIEWS' }]
  }, [reviewerData, t])

  return (
    <>
      <PageHeader pageName={t('reviews:header.title')}>
        <div className="flex flex-col gap-4">
          <div>
            <span className="text-md font-bold">{t('reviews:header.subtitle')}</span>
            <br />
            <span className="text-md">{t('reviews:header.description')}</span>
          </div>
          <table className="table-auto w-full border-collapse">
            <tbody>
              {reviewLinksSystemData?.map((system) => (
                <tr key={system.id}>
                  <td className="px-4 py-2 text-lg truncate">
                    {system?.systemSiteData[0]?.systemName}
                  </td>
                  <td className="px-4 py-2">
                    <div className="flex flex-wrap gap-2">
                      {system?.systemSiteData?.map((systemSiteData) => (
                        <LangLink
                          key={systemSiteData.id}
                          locale={systemSiteData.locale}
                          domain={systemSiteData.site?.domain}
                          systemNameUrl={systemSiteData.systemNameUrl}
                        />
                      ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </PageHeader>

      <PageBody loading={availableSystemsLoading}>
        {tabs?.length > 1 && (
          <Tabs tabs={tabs} activeTab={activeTab} onChange={handleTabChange} />
        )}

        {activeTab === 'REVIEWS' && (
          <>
            {!!allSystemsReviews?.length && (
              <SystemsReviewsFilter
                allSystemsWithReviews={allSystemsReviews}
                onReviewsFiltered={setFilteredSystemsReviews}
              />
            )}

            <ReviewList
              systemsWithReviews={filteredSystemsReviews}
              loading={availableSystemsLoading || !filteredSystemsReviews}
            />
          </>
        )}
        {activeTab == 'REVIEWER' && (
          <ReviewerList reviewers={reviewerData} loading={availableSystemsLoading} />
        )}
      </PageBody>
    </>
  )
}

export default SystemsReviewsPage
