import { gql, useMutation } from '@apollo/client'

const CONNECT_SYSTEM_TO_BADGE = gql`
  mutation ConnectBadgeToSystem($badgeId: ID!, $systemId: ID!, $locale: String) {
    appAdminDisconnectBadgeToSystem(
      badgeId: $badgeId
      systemId: $systemId
      locale: $locale
    )
  }
`

/**
 * Disconnects badge from system
 * @returns {{disconnectBadgeToSystem: (function({badgeId: *, systemId: *}): Promise<(function(*, *, *): Promise<void>)|*>), data: any, loading: boolean, error: ApolloError}}
 */
export function useDisconnectBadgeFromSystem() {
  const [appAdminDisconnectBadgeToSystem, { data, loading, error }] = useMutation(
    CONNECT_SYSTEM_TO_BADGE
  )

  const disconnectBadgeToSystem = async ({ badgeId, systemId, locale }) => {
    const response = await appAdminDisconnectBadgeToSystem({
      variables: {
        badgeId,
        systemId,
        locale
      }
    })
    return response?.data?.appAdminConnectBadgeToSystem
  }
  return {
    disconnectBadgeToSystem,
    data,
    loading,
    error
  }
}
