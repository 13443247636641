import React from 'react'
import { useTranslation } from 'react-i18next'
import LinkedInIcon from '../../../icons/linkedInIcon'

const ReviewerItem = ({ reviewer }) => {
  const { t } = useTranslation()

  const illegalDomainList = ['@surveymonkey.com']
  if (illegalDomainList.some((domain) => reviewer.email.includes(domain))) {
    return
  }

  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">{t('content:email')}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <p>
          {reviewer.email}
          {reviewer.verification == 'LINKEDIN' && <LinkedInIcon />}
        </p>
      </dd>
    </div>
  )
}

const ReviewerList = ({ reviewers }) => {
  const { t } = useTranslation()

  if (!reviewers?.length) {
    return null
  }

  return (
    <div className="mt-6">
      <h2 className="text-lg font-medium text-gray-700 mb-3">
        {t('reviews:reviewer.header')}
      </h2>

      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <dl className="sm:divide-y sm:divide-gray-200">
          {reviewers.map((reviewer, index) => {
            return <ReviewerItem key={`${reviewer.email}-${index}`} reviewer={reviewer} />
          })}
        </dl>
      </div>
    </div>
  )
}

export default ReviewerList
