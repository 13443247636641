import React, {
  useImperativeHandle,
  useCallback,
  useEffect,
  useMemo,
  useState,
  forwardRef
} from 'react'
import { bool, func, string } from 'prop-types'
import { fixBoolean } from '../../helpers/general.helper'

import { useQuery, gql } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import useFieldsSystemsValues from '../../hooks/fields/useSystemsValues'
import { useFormFields } from '../../hooks'
import useFetchOperationalCountries from '../../hooks/useFetchOperationalCountries'
import useCategoriesOptions from '../../hooks/useCategoriesOptions'

import Button from '../shared/button'
import Label from '../shared/label'
import Loader from '../loader'
import { MultiSelect, ListItemBase } from '../shared/select'
import { Select } from '../form/inputs'
import Fields from '../form/fields'
import { Link } from 'react-router-dom'
import { VENDOR_FORM } from '../../constants/vendor-form-fields'
import { ExternalLinkIcon } from '@heroicons/react/solid'

const GET_VENDORS = gql`
  query VendorsAndSystems {
    appAdminCompanies {
      id
      company
      accessRights
      userName
      systemsV2 {
        id
        defaultCategory
        systemSiteData {
          locale
          systemName
        }
      }
      appLocales
      buyingIntentBoost
      buyingIntentBoostCategories
      pipedriveId
    }
  }
`

const GET_SYSTEMS = gql`
  query Systems {
    appSystems {
      id
      defaultCategory
      systemSiteData {
        systemName
      }
    }
  }
`

const VendorForm = forwardRef(
  ({ selectedVendorId, onChangeVendorId, loading, onSubmit }, ref) => {
    const isCreatorMode = !onChangeVendorId

    const { t } = useTranslation('adminPages')
    const [allSystems, setAllSystems] = useState([])
    const [allVendors, setAllVendors] = useState([])
    const [formInputs, setFormInputs] = useState(VENDOR_FORM)
    const [fieldsData, handleFieldChange, saveInitialState, setValues, changedFields] =
      useFormFields({})
    const { categoriesOptions } = useCategoriesOptions()
    const { getSortedSystemValues } = useFieldsSystemsValues()
    const { usersMarketRegionsValues } = useFetchOperationalCountries()
    const noChanges = !Object.keys(changedFields).length
    const [outboundHref, setOutbondHref] = useState(null)
    // Reset form helper ref method
    useImperativeHandle(
      ref,
      () => ({
        resetForm: () => {
          saveInitialState({})
        }
      }),
      [saveInitialState]
    )
    useEffect(() => {
      if (!usersMarketRegionsValues.length) {
        return
      }

      const updatedInputs = VENDOR_FORM.map((inputObj) => {
        const updatedObj = { ...inputObj }

        if (updatedObj.id === 'appLocales') {
          updatedObj.values = usersMarketRegionsValues
        }

        return updatedObj
      })

      setFormInputs(updatedInputs)
    }, [usersMarketRegionsValues])

    useEffect(() => {
      if (!selectedVendorId) {
        return
      }

      const selectedVendorObj = allVendors?.find(
        (vendorObj) => vendorObj.id === selectedVendorId
      )

      const updatedSelectedVendorObj = { ...selectedVendorObj }

      updatedSelectedVendorObj.systems = selectedVendorObj?.systemsV2?.map(
        (systemObj) => systemObj.id
      )
      setOutbondHref(`/admin-vendor-outbounds/${selectedVendorId}`)
      saveInitialState(updatedSelectedVendorObj)
    }, [selectedVendorId, allVendors, saveInitialState])

    const {
      loading: fetchVendorsLoading,
      error: fetchVendorsError,
      refetch: refetchVendors
    } = useQuery(GET_VENDORS, {
      skip: isCreatorMode,
      onCompleted: (data) => {
        const allVendors = data.appAdminCompanies.map((vendorObj) => {
          return {
            ...vendorObj,
            title: vendorObj.company
          }
        })

        setAllVendors(allVendors)
      }
    })

    const { loading: fetchSystemsLoading, error: fetchSystemsError } = useQuery(
      GET_SYSTEMS,
      {
        onCompleted: (data) => {
          setAllSystems(data.appSystems)
        }
      }
    )

    const fetchDataLoading = fetchVendorsLoading || fetchSystemsLoading
    const fetchDataErrors = fetchVendorsError || fetchSystemsError

    const allSystemsOptionsMemo = useMemo(() => {
      const sortedSystems = getSortedSystemValues(allSystems)

      return sortedSystems.map((system) => {
        return {
          label: system.title,
          value: system.id,
          company: system.text
        }
      })
    }, [getSortedSystemValues, allSystems])

    const handleChangeModern = useCallback(
      (fieldName, value) => {
        const fakeEventObject = {
          target: {
            id: fieldName,
            value
          }
        }

        handleFieldChange(fakeEventObject)
      },
      [handleFieldChange]
    )

    const handleFormSubmit = (e) => {
      e.preventDefault()

      const {
        company,
        pipedriveId,
        accessRights,
        systems,
        appLocales,
        buyingIntentBoost,
        buyingIntentBoostCategories
      } = fieldsData

      onSubmit(
        {
          company,
          pipedriveId,
          accessRights,
          systemsV2: systems,
          appLocales,
          buyingIntentBoost: fixBoolean(buyingIntentBoost),
          buyingIntentBoostCategories
        },
        refetchVendors
      )
    }

    const showForm = isCreatorMode || (formInputs && !!Object.keys(fieldsData).length)

    return (
      <>
        {fetchDataErrors?.graphQLErrors.map(({ message }, i) => (
          <span key={i}>{message}</span>
        ))}

        {fetchDataLoading && (
          <div className="text-center">
            <Loader />
          </div>
        )}
        <div className={'flex justify-between items-center'}>
          {outboundHref && (
            <Link
              to={outboundHref}
              className={'flex items-center gap-1 hover:text-blue-600'}
            >
              {t('outboundLink')} <ExternalLinkIcon className={'w-5 h-5'} />
            </Link>
          )}
          {allVendors?.length && (
            <p>
              {t('totalNumberOfCompanies')}:{' '}
              <span className={'font-semibold text-lg text-blue-600'}>
                {allVendors.length}
              </span>
            </p>
          )}
        </div>
        <div>
          {!fetchDataLoading && !fetchDataErrors && (
            <div>
              <form
                className="space-y-8 divide-y divide-gray-200"
                onSubmit={handleFormSubmit}
              >
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="pt-8">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {isCreatorMode
                          ? t('adminAddVendor.header.title')
                          : t('adminEditVendor.editVendor')}
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        {isCreatorMode
                          ? t('adminAddVendor.header.subtitle')
                          : t('adminEditVendor.editVendorSubtitle')}
                      </p>

                      {!isCreatorMode && (
                        <Select
                          field={{
                            id: 'vendor',
                            name: t('content:company'),
                            text: t('adminPages:adminEditVendor.whichCompanyToEdit'),
                            type: 'select',
                            values: allVendors
                          }}
                          data={selectedVendorId}
                          handleFieldChange={(e) => {
                            onChangeVendorId(e.target.value)
                          }}
                        />
                      )}

                      {showForm && (
                        <>
                          <Fields
                            inputFields={formInputs}
                            fieldsData={fieldsData}
                            handleFieldChange={handleFieldChange}
                          />

                          <Label
                            title={t(
                              'adminPages:sharedVendorForm.buyingIntentBoostCategoriesText'
                            )}
                          >
                            <MultiSelect
                              options={categoriesOptions}
                              onChange={handleChangeModern.bind(
                                null,
                                'buyingIntentBoostCategories'
                              )}
                              value={fieldsData.buyingIntentBoostCategories}
                            />
                          </Label>

                          <Label
                            title={t('content:system')}
                            subTitle={t('adminPages:sharedVendorForm.systemsText')}
                          >
                            <MultiSelect
                              options={allSystemsOptionsMemo}
                              onChange={handleChangeModern.bind(null, 'systems')}
                              value={fieldsData.systems}
                              renderListItem={({
                                option,
                                focusedItemId,
                                handleSelect
                              }) => {
                                return (
                                  <ListItemBase
                                    key={option.value}
                                    onClick={handleSelect.bind(null, option.value)}
                                    active={option.value === focusedItemId}
                                  >
                                    <div className="flex flex-col">
                                      <span className="text-base">{option.label}</span>
                                      <span className="text-sm italic">
                                        {option.company}
                                      </span>
                                    </div>
                                  </ListItemBase>
                                )
                              }}
                            />
                          </Label>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="pt-5">
                  <div className="flex justify-end">
                    <Button type="submit" loading={loading} disabled={noChanges}>
                      {t('content:save')}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </>
    )
  }
)

VendorForm.displayName = 'VendorForm'
VendorForm.propTypes = {
  selectedVendorId: string,
  onChangeVendorId: func,
  loading: bool,
  onSubmit: func.isRequired
}

VendorForm.defaultProps = {}

export default VendorForm
