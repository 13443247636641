import React, { useRef, useState } from 'react'
import { useFailedWebhookEvent } from '../../hooks/useFailedWebhookEvent'
import WebhookRetryButton from './webhook-retry-button'

const WebhookFailedEventAlert = ({ eventType, t = {} }) => {
  const { failedWebhook, loading, refetch } = useFailedWebhookEvent({
    eventType
  })
  const alertRef = useRef(null)
  const [error, setError] = useState('')
  const onSuccessResend = (data) => {
    if (data) {
      alertRef.current.remove()
    } else {
      setError('TODO: Failed to send event')
      refetch()
    }
  }

  if (!failedWebhook) {
    return null
  }
  const { status, name, whenItFailed } = failedWebhook
  const shouldRetry = status === 'ABANDONED' || status === 'FAILED'

  let wrapperClasses = 'border-gray-200 bg-gray-100 text-gray-700'
  if (status === 'FAILED') {
    wrapperClasses = 'border-red-600 bg-red-100 text-gray-700'
  } else if (status === 'ABANDONED') {
    wrapperClasses = 'border-orange-200 bg-orange-100 text-gray-700'
  }

  return (
    <div
      ref={alertRef}
      className={`p-2 border-2 rounded-md my-2 flex justify-between ${wrapperClasses}`}
    >
      <div className="m-2">
        {t('webhooks:abandoned.message', {
          name,
          failedAt: new Date(whenItFailed).toLocaleString(),
          eventType: t(`vendorSettings:webhooks.triggers.${eventType}`)
        })}
      </div>
      <div className={'flex flex-col items-center m-2'}>
        {shouldRetry && !loading && (
          <WebhookRetryButton
            className={
              'p-2 w-28 hover:bg-gray-600 bg-gray-500 text-white flex items-center justify-center'
            }
            eventId={failedWebhook?.eventId}
            onSuccess={onSuccessResend}
          >
            {t('content:resend')}
          </WebhookRetryButton>
        )}
        {error && (
          <p
            className={
              'text-red-600 text-xs uppercase font-medium p-1 bg-red-100 mt-1 rounded-md'
            }
          >
            {error}
          </p>
        )}
      </div>
    </div>
  )
}

export default WebhookFailedEventAlert
