import React from 'react'
import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import BadgeVendorList from '../components/badges/partials/BadgeVendorList'
import { useTranslation } from 'react-i18next'
import { useUserContext } from '../contexts'
import { isAdmin } from '../utils'

const Badges = () => {
  const { t } = useTranslation('badges')
  const { user } = useUserContext()
  const admin = isAdmin(user?.role)
  const hasAccessToBadges = admin || user?.accessRights?.includes('BADGES')

  if (!hasAccessToBadges) {
    return null
  }
  return (
    <>
      <PageHeader pageName={t('heading')} />
      <PageBody>
        <BadgeVendorList />
      </PageBody>
    </>
  )
}
export default Badges
