import React from 'react'

const Star = ({ fill }) => {
  return (
    <svg>
      <defs>
        <linearGradient id="halfGradient">
          <stop offset="50%" stopColor="#FEC84B" />
          <stop offset="50%" stopColor="#d3d3d3" />
        </linearGradient>
      </defs>
      <path
        d="M138.326 320.267C139.438 318.078 142.564 318.078 143.675 320.267L145.509 323.878C145.931 324.709 146.715 325.297 147.632 325.468L151.697 326.23C154.01 326.663 154.946 329.468 153.358 331.203L150.323 334.522C149.725 335.175 149.446 336.06 149.562 336.939L150.142 341.341C150.458 343.737 147.957 345.504 145.804 344.406L142.365 342.65C141.508 342.213 140.494 342.213 139.638 342.65L136.195 344.406C134.043 345.504 131.542 343.738 131.858 341.342L132.438 336.939C132.554 336.06 132.275 335.175 131.677 334.522L128.642 331.203C127.054 329.468 127.99 326.663 130.303 326.23L134.369 325.468C135.285 325.297 136.069 324.709 136.491 323.878L138.326 320.267Z"
        fill={
          fill[0] === 'full'
            ? '#FEC84B'
            : fill[0] === 'empty'
            ? '#d3d3d3'
            : 'url(#halfGradient)'
        }
      />
      <path
        d="M175.659 320.267C176.771 318.078 179.897 318.078 181.009 320.267L182.842 323.878C183.264 324.709 184.049 325.297 184.965 325.468L189.03 326.23C191.343 326.663 192.28 329.468 190.692 331.203L187.656 334.522C187.058 335.175 186.779 336.06 186.895 336.939L187.475 341.341C187.791 343.737 185.29 345.504 183.138 344.406L179.698 342.65C178.841 342.213 177.827 342.213 176.971 342.65L173.529 344.406C171.376 345.504 168.875 343.738 169.191 341.342L169.771 336.939C169.887 336.06 169.609 335.175 169.01 334.522L165.975 331.203C164.387 329.468 165.324 326.663 167.636 326.23L171.702 325.468C172.618 325.297 173.402 324.709 173.824 323.878L175.659 320.267Z"
        fill={
          fill[1] === 'full'
            ? '#FEC84B'
            : fill[1] === 'empty'
            ? '#d3d3d3'
            : 'url(#halfGradient)'
        }
      />
      <path
        d="M212.993 320.267C214.104 318.078 217.231 318.078 218.342 320.267L220.176 323.878C220.598 324.709 221.382 325.297 222.298 325.468L226.364 326.23C228.676 326.663 229.613 329.468 228.025 331.203L224.99 334.522C224.391 335.175 224.113 336.06 224.229 336.939L224.809 341.341C225.125 343.737 222.623 345.504 220.471 344.406L217.031 342.65C216.175 342.213 215.161 342.213 214.304 342.65L210.862 344.406C208.71 345.504 206.209 343.738 206.524 341.342L207.105 336.939C207.221 336.06 206.942 335.175 206.344 334.522L203.308 331.203C201.72 329.468 202.657 326.663 204.97 326.23L209.035 325.468C209.952 325.297 210.736 324.709 211.158 323.878L212.993 320.267Z"
        fill={
          fill[2] === 'full'
            ? '#FEC84B'
            : fill[2] === 'empty'
            ? '#d3d3d3'
            : 'url(#halfGradient)'
        }
      />
      <path
        d="M250.326 320.267C251.438 318.078 254.564 318.078 255.675 320.267L257.509 323.878C257.931 324.709 258.715 325.297 259.632 325.468L263.697 326.23C266.01 326.663 266.946 329.468 265.358 331.203L262.323 334.522C261.725 335.175 261.446 336.06 261.562 336.939L262.142 341.341C262.458 343.737 259.957 345.504 257.804 344.406L254.365 342.65C253.508 342.213 252.494 342.213 251.638 342.65L248.195 344.406C246.043 345.504 243.542 343.738 243.858 341.342L244.438 336.939C244.554 336.06 244.275 335.175 243.677 334.522L240.642 331.203C239.054 329.468 239.99 326.663 242.303 326.23L246.369 325.468C247.285 325.297 248.069 324.709 248.491 323.878L250.326 320.267Z"
        fill={
          fill[3] === 'full'
            ? '#FEC84B'
            : fill[3] === 'empty'
            ? '#d3d3d3'
            : 'url(#halfGradient)'
        }
      />
      <path
        d="M287.659 320.267C288.771 318.078 291.897 318.078 293.009 320.267L294.842 323.878C295.264 324.709 296.049 325.297 296.965 325.468L301.03 326.23C303.343 326.663 304.28 329.468 302.692 331.203L299.656 334.522C299.058 335.175 298.779 336.06 298.895 336.939L299.475 341.341C299.791 343.737 297.29 345.504 295.138 344.406L291.698 342.65C290.841 342.213 289.827 342.213 288.971 342.65L285.529 344.406C283.376 345.504 280.875 343.738 281.191 341.342L281.771 336.939C281.887 336.06 281.609 335.175 281.01 334.522L277.975 331.203C276.387 329.468 277.324 326.663 279.636 326.23L283.702 325.468C284.618 325.297 285.402 324.709 285.824 323.878L287.659 320.267Z"
        fill={
          fill[4] === 'full'
            ? '#FEC84B'
            : fill[4] === 'empty'
            ? '#d3d3d3'
            : 'url(#halfGradient)'
        }
      />
    </svg>
  )
}

const StarRating = ({ rating }) => {
  // Create an array representing the fill state for each star.
  // For example, if rating = 3.5, then we want: ["full", "full", "full", "half", "empty"]
  const stars = Array(5)
    .fill(0)
    .map((_, i) => {
      if (rating >= i + 1) return 'full'
      if (rating > i) return 'half'
      return 'empty'
    })
  return <Star fill={stars} />
}

export default StarRating
