import React, { useState } from 'react'
import Filters from '../shared/filters'
import PropTypes from 'prop-types'
import { useBuyingIntentFilters } from './hooks/useBuyingIntentFilters'

const BuyingIntentFilters = ({
  buyingIntents,
  applyFilters,
  resetFilters,
  onActiveFiltersChange,
  isAdmin
}) => {
  const { filters } = useBuyingIntentFilters(buyingIntents, isAdmin)
  const [activeFilters, setActiveFilters] = useState([])

  const handleFiltersChanged = (newActiveFilters) => {
    setActiveFilters(newActiveFilters)
    if (onActiveFiltersChange) {
      onActiveFiltersChange(newActiveFilters)
    }
    applyFilters(newActiveFilters)
  }

  const handleFilterRemoval = (removedFilter, remainingFilters = []) => {
    setActiveFilters(remainingFilters)
    if (onActiveFiltersChange) {
      onActiveFiltersChange(remainingFilters)
    }
    if (remainingFilters.length === 0) {
      resetFilters()
    } else {
      applyFilters(remainingFilters)
    }
  }

  return (
    <Filters
      onRemovedFilter={handleFilterRemoval}
      filters={filters}
      onChangeFilters={handleFiltersChanged}
      isFlex={false}
      activeFilters={activeFilters}
    />
  )
}

BuyingIntentFilters.propTypes = {
  buyingIntents: PropTypes.array.isRequired,
  applyFilters: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  onActiveFiltersChange: PropTypes.func
}

export default BuyingIntentFilters
