import React from 'react'
import { array, bool, func, string } from 'prop-types'

import { useManageSystemContext } from '../../context'

import Tile, { TileHeader, TileBody, TileFooter, TileHeaderDocument } from './tile'
import CountriesCheckboxes from './countries-checkboxes'
import ManageAssetControls from './manage-asset-controls'
import ColorPickerInput from '../../../shared/color-picker-input'
import Checkbox from '../../../shared/checkbox'

const AssetsRenderer = ({
  assetType,
  assets,
  onChange,
  hideOrderControls,
  hideCountries,
  bannerConfig,
  onColorChange,
  onDefaultChange,
  children
}) => {
  const { fieldsData, setPreviewImageUrl } = useManageSystemContext()
  const systemSiteData = fieldsData.systemSiteData || []

  if (!assets) {
    return null
  }

  const handlePreviewImage = (asset) => {
    if (asset.file?.src) {
      setPreviewImageUrl(asset.file?.src)
    } else if (asset.url) {
      setPreviewImageUrl(
        `https://ik.imagekit.io/businesswith/${assetType.toLowerCase()}/${asset.url}`
      )
    }
  }

  const handlePreviewVideo = (asset) => {
    if (asset.subType === 'YOUTUBE') {
      window.open(`https://www.youtube.com/watch?v=${asset.url}`, '_blank')
    } else if (asset.subType === 'VIMEO') {
      window.open(`https://vimeo.com/${asset.url}`, '_blank')
    }
  }

  const handlePreviewDocument = (asset) => {
    window.open(
      `https://ik.imagekit.io/businesswith/${assetType.toLowerCase()}/${asset.url}`,
      '_blank'
    )
  }

  return assets.map((asset, index) => {
    let imageSrc
    let onClick
    const numOfAssets = assets.length

    if (['LOGO', 'SCREENSHOT', 'BANNER'].includes(assetType)) {
      onClick = handlePreviewImage.bind(null, asset)
    } else if (['VIDEO', 'PITCH'].includes(assetType)) {
      onClick = handlePreviewVideo.bind(null, asset)
    } else if (assetType === 'DOCUMENT') {
      onClick = handlePreviewDocument.bind(null, asset)
    }

    if (asset.file?.src && assetType !== 'DOCUMENT') {
      imageSrc = asset.file.src // Uploading...
    } else if (['SCREENSHOT', 'LOGO', 'BANNER'].includes(assetType)) {
      imageSrc = `https://ik.imagekit.io/businesswith/tr:w-80,h-40,cm-pad_resize,dpr-2/${assetType.toLowerCase()}/${
        asset.url
      }`
    } else if (['VIDEO', 'PITCH'].includes(assetType) && asset.url) {
      if (asset.subType === 'YOUTUBE') {
        imageSrc = `https://img.youtube.com/vi/${asset.url}/0.jpg`
      } else if (asset.subType === 'VIMEO') {
        imageSrc = asset.imageUrl || 'MISSING'
      }
    }

    return (
      <Tile key={asset.id}>
        {imageSrc && <TileHeader image={imageSrc} onClick={onClick} />}
        {assetType === 'DOCUMENT' && (
          <TileHeaderDocument text={asset.url || asset?.file?.name} onClick={onClick} />
        )}

        {!hideCountries && (
          <TileBody>
            <CountriesCheckboxes
              assetId={asset.id}
              assetType={assetType}
              locales={asset.locales || []}
              systemSiteData={systemSiteData}
              onChange={onChange}
            />
          </TileBody>
        )}

        {assetType === 'BANNER' && (
          <TileBody>
            <div className={'flex flex-col gap-3'}>
              <ColorPickerInput
                className={'right-1'}
                defaultColor={bannerConfig[index]?.color}
                label={'TODO: Select background color'}
                onChange={(color) => onColorChange(index, color)}
              />
              <Checkbox
                checked={bannerConfig[index]?.default}
                text={'TODO: Use image as background'}
                onChange={(e) => onDefaultChange(index, e)}
              />
            </div>
          </TileBody>
        )}

        <TileFooter>
          <ManageAssetControls
            assetId={asset.id}
            assetType={assetType}
            hideOrderControls={hideOrderControls}
            moveLeftDisabled={numOfAssets === 1 || asset.id === assets[0].id}
            moveRightDisabled={numOfAssets === 1 || numOfAssets - 1 === index}
            onReorder={onChange}
            onDelete={onChange}
          />
        </TileFooter>
      </Tile>
    )
  })
}

AssetsRenderer.propTypes = {
  assets: array.isRequired,
  assetType: string.isRequired,
  onChange: func.isRequired,
  hideOrderControls: bool
}

AssetsRenderer.defaultProps = {
  assets: []
}

export default AssetsRenderer
