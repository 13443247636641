import { gql } from '@apollo/client'
import useAdminQuery from './useAdminQuery'

const GET_APP_WEBHOOK_FAILED_EVENT = gql`
  query getAppWebhookFailedEvent($companyId: ID, $userId: ID, $eventType: String) {
    appWebhookFailedEvent(companyId: $companyId, userId: $userId, eventType: $eventType)
  }
`

/**
 * Custom hook that fetches the most recent failed/abandoned
 * webhook event for a given eventType.
 *
 * @param {Object} params
 * @param {string} params.eventType - e.g. 'EVENT_NEW_GUIDE_LEAD'
 * @returns {Object} { failedWebhook, loading, error }
 */
export function useFailedWebhookEvent({ eventType }) {
  const { data, loading, error, refetch } = useAdminQuery(GET_APP_WEBHOOK_FAILED_EVENT, {
    variables: {
      eventType
    }
  })
  const failedWebhook = data?.appWebhookFailedEvent || null
  return {
    failedWebhook,
    loading,
    error,
    refetch
  }
}
