import React from 'react'
import PropTypes from 'prop-types'
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  YAxis,
  LabelList,
  Rectangle,
  XAxis
} from 'recharts'

import { ChartColors } from './helpers/chartcolors'
/**
 * Renders a custom top bar label within a chart or graphical element.
 * Draws a rectangle with a centered text value at the specified position.
 *
 * @param {Object} props - The properties for the label rendering.
 * @param {number} props.x - The x-coordinate of the label.
 * @param {number} props.y - The y-coordinate of the label.
 * @param {number} props.width - The width of the element the label is associated with.
 * @param {string|number} props.value - The value to display within the label.
 * @returns {JSX.Element} A group (g) element containing a rectangle and centered text.
 */
const renderTopBarLabel = (props) => {
  const { x, y, width, value } = props
  const rectHeight = 35

  return (
    <g className="relative top-11">
      <text
        x={x + width / 2}
        y={y - rectHeight / 2}
        fill="#4b5563"
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ fontSize: '15px', fontWeight: 'bold' }}
      >
        {value}
      </text>
    </g>
  )
}

/**
 * Renders a vertical label as an SVG text element.
 *
 * @param {Object} props - The properties object for the label.
 * @param {number} props.x - The x-coordinate of the label's position.
 * @param {number} props.y - The y-coordinate of the label's position.
 * @param {number} props.width - The width of the label container.
 * @param {number} props.height - The height of the label container.
 * @param {string} props.value - The text content of the label.
 * @param {string} props.fill - The fill color of the label text.
 * @returns {JSX.Element} A JSX element representing the vertical label.
 */
const renderVerticalLabel = (props) => {
  const { x, y, width, height, value, fill } = props

  return (
    <text
      x={x + (Number.isNaN(width) ? 0 : width) / 2}
      y={y + (Number.isNaN(height) ? 0 : height) / 2}
      textAnchor="middle"
      dominantBaseline="middle"
      transform={`rotate(-90, ${x + width / 2}, ${y + height / 2})`}
      fill={fill}
      style={{ fontSize: '15px', fontWeight: 'bold', color: '#4b5563' }}
    >
      {value}
    </text>
  )
}

/**
 * CustomizedXAxisTick is a functional React component that represents a custom tick
 * renderer for the x-axis in a chart. It customizes the appearance and positioning
 * of the axis ticks based on the passed properties.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {number} props.x - The x-coordinate for positioning the tick.
 * @param {number} props.y - The y-coordinate for positioning the tick.
 * @param {Object} props.payload - The data payload associated with the tick, typically containing a `value` property.
 * @returns {JSX.Element} A group (`<g>`) SVG element containing the customized x-axis tick.
 */
const CustomizedXAxisTick = (props) => {
  const { x, y, payload, rotateXAxisLabels } = props

  if (rotateXAxisLabels) {
    return (
      <g transform={`translate(${x},${y}) rotate(-45)`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#333"
          style={{ fontSize: '12px', fontWeight: 'bold', color: '#4b5563' }}
        >
          {payload.value}
        </text>
      </g>
    )
  }
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#333"
        style={{ fontSize: '15px', fontWeight: 'bold' }}
      >
        {payload.value}
      </text>
    </g>
  )
}

/**
 * CustomizedYAxisTick is a functional component that renders a custom tick for the Y-axis of a chart.
 * It is used to customize the appearance and positioning of the Y-axis labels.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {number} props.x - The x-coordinate of the tick.
 * @param {number} props.y - The y-coordinate of the tick.
 * @param {Object} props.payload - The payload containing the data for the tick.
 * @param {string|number} props.payload.value - The value to be displayed for the tick.
 * @returns {JSX.Element} A group element (`<g>`) containing a text element for the custom Y-axis tick label.
 */
const CustomizedYAxisTick = (props) => {
  const { x, y, payload } = props
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={-10}
        y={0}
        dy={4}
        textAnchor="end"
        fill="#333"
        style={{ fontSize: '14px', fontWeight: 'bold' }}
      >
        {payload.value}
      </text>
    </g>
  )
}

/**
 * Renders a bar chart component displaying buying intent data.
 *
 * @param {Object} props - Component properties.
 * @param {Array} props.data - Array of data points used to render the bar chart.
 * @param {string} [props.title] - Optional title text to be displayed above the chart.
 * @param {string} [props.barColor='#3b82f6'] - Color of the bars in the chart.
 * @param {string} [props.barColorText='#fff'] - Color of the text displayed on the bars.
 * @param {string} props.barKey - Key from the data set used as the value for the bar heights.
 * @param {string} props.dataKey - Key from the data set used for categories or labels.
 * @param {boolean} [props.showBarKeyXAxis=false] - Determines whether the bar key is displayed on the X-Axis.
 * @param {boolean} [props.showBarKeyInBar=false] - Determines whether the bar key values are shown inside the bars.
 * @returns {JSX.Element} A styled bar chart component.
 */
const BarChartGraph = ({
  data,
  title,
  barColor = '#3b82f6',
  barColorText = '#fff',
  barHeight = 350,
  barSize = 40,
  gapBottom = 40,
  gapTop = 40,
  barKey,
  dataKey,
  minPointSize,
  showBarKeyXAxis = false,
  showBarKeyYAxis = false,
  showBarKeyInBar = false,
  rotateXAxisLabels = false,
  showAllXAxisLabels = false,
  sorted = false
}) => {
  const random = Math.floor(ChartColors.length * Math.random())
  const coloredData = data
    .map((d, index) => ({
      ...d,
      fill: ChartColors[(random + index) % ChartColors?.length]
    }))
    .sort((data1, data2) => {
      return sorted ? 0 : data1[dataKey].localeCompare(data2[dataKey])
    })

  const xAxisProps = {
    dataKey,
    hide: !showBarKeyXAxis,
    interval: showAllXAxisLabels ? 0 : 'preserve',
    tick: (tickProps) => (
      <CustomizedXAxisTick {...tickProps} rotateXAxisLabels={rotateXAxisLabels} />
    )
  }

  return (
    <div className={'shadow-lg rounded-lg pb-5 bg-white'}>
      {title && (
        <h3
          className={
            'shadow bg-white font-semibold rounded-t-lg capitalize text-sm text-gray-500 p-3'
          }
        >
          {title}
        </h3>
      )}
      <ResponsiveContainer width={'100%'} height={barHeight}>
        {!!data.length ? (
          <BarChart
            data={coloredData}
            margin={{
              top: gapTop,
              right: 40,
              left: 40,
              bottom: rotateXAxisLabels ? gapBottom : 20
            }}
          >
            <Bar
              radius={[7, 7, 0, 0]}
              dataKey={barKey}
              fill={barColor}
              minPointSize={minPointSize || 1}
              activeBar={<Rectangle fill="#4f46e5" />}
              barSize={barSize}
            >
              <LabelList
                fill={barColorText}
                position={'centerTop'}
                dataKey={barKey}
                content={renderTopBarLabel}
              />
              {showBarKeyInBar && (
                <LabelList
                  fill={barColorText}
                  position={'centerTop'}
                  dataKey={dataKey}
                  content={renderVerticalLabel}
                />
              )}
            </Bar>
            <YAxis hide={!showBarKeyYAxis} tick={<CustomizedYAxisTick />} />
            <XAxis {...xAxisProps} />
          </BarChart>
        ) : (
          <div
            className={'flex items-center flex-col text-gray-400 font-semibold text-sm'}
          >
            No Insight available at the moment, come back later.
          </div>
        )}
      </ResponsiveContainer>
    </div>
  )
}

BarChartGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  barColor: PropTypes.string,
  barColorText: PropTypes.string,
  barKey: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired,
  barHeight: PropTypes.number,
  gapBottom: PropTypes.number,
  gapTop: PropTypes.number,
  minPointSize: PropTypes.number,
  showBarKeyXAxis: PropTypes.bool,
  showBarKeyYAxis: PropTypes.bool,
  showBarKeyInBar: PropTypes.bool,
  barSize: PropTypes.number,
  rotateXAxisLabels: PropTypes.bool,
  showAllXAxisLabels: PropTypes.bool,
  sorted: PropTypes.bool
}

export default BarChartGraph
