export default function useHasVendorOptions() {
  return {
    hasVendorOptions: [
      {
        id: 'true',
        labelTid: 'content:yes',
        value: true
      },
      {
        id: 'false',
        labelTid: 'content:no',
        value: false
      }
    ]
  }
}
