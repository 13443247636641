import React, { useContext, useState, useCallback, useMemo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
const DocumentationContext = React.createContext({
  isOpen: false,
  currentSection: null,
  openDocumentation: () => {},
  closeDocumentation: () => {},
  currentPage: 'index'
})

export const useDocumentation = () => {
  const context = useContext(DocumentationContext)
  if (context === undefined) {
    throw new Error('useDocumentation must be used within a DocumentationProvider')
  }
  return context
}

export const DocumentationProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()
  const [currentPage, setCurrentPage] = useState('index')

  useEffect(() => {
    // Remove leading slash and extract first path segment
    const path = location.pathname.substring(1)
    const section = path.split('/')[0] || 'index'
    setCurrentPage(section)
  }, [location.pathname])

  const openDocumentation = useCallback(() => {
    setIsOpen(true)
  }, [])

  const closeDocumentation = useCallback(() => {
    setIsOpen(false)
  }, [])

  const value = useMemo(
    () => ({
      isOpen,
      openDocumentation,
      closeDocumentation,
      currentPage
    }),
    [isOpen, openDocumentation, closeDocumentation, currentPage]
  )

  return (
    <DocumentationContext.Provider value={value}>
      {children}
    </DocumentationContext.Provider>
  )
}
