import React, { useEffect, useState } from 'react'
import { array, func } from 'prop-types'
import AssetsRenderer from './assets-renderer'
import Dropzone from './dropzone'
import { useManageSystemContext } from '../../context'

const BannerSection = ({ onChange, assets }) => {
  const { mediaAssetsData, setMediaAssetsData } = useManageSystemContext()
  const [error, setError] = useState('')

  // Initialize bannerConfig with an array of configurations
  const [bannerConfig, setBannerConfig] = useState(
    assets.map((asset) => ({
      color: asset.config?.color || '#ffffff',
      default: asset.config?.default !== undefined ? asset.config?.default : true // Error here
    }))
  )

  useEffect(() => {
    setBannerConfig(
      assets.map((asset) => ({
        color: asset.config?.color || '#ffffff',
        default: asset.config?.default !== undefined ? asset.config?.default : true
      }))
    )
  }, [assets])

  const updateBannerConfigInMediaAssets = (index, updatedConfig) => {
    const updatedMediaAssetsData = {
      ...mediaAssetsData,
      BANNER: mediaAssetsData.BANNER.map((asset, i) => {
        if (i === index) {
          if (!asset.action) {
            asset.action = 'EDIT'
          }
          return {
            ...asset,
            config: updatedConfig
          }
        }
        return asset
      })
    }
    setMediaAssetsData(updatedMediaAssetsData)
  }

  const onColorChange = (index, color) => {
    const updatedConfig = { ...bannerConfig[index], color }
    const updatedBannerConfig = [...bannerConfig]
    updatedBannerConfig[index] = updatedConfig
    setBannerConfig(updatedBannerConfig)
    updateBannerConfigInMediaAssets(index, updatedConfig)
  }

  const onDefaultChange = (index, e) => {
    const updatedConfig = { ...bannerConfig[index], default: e.target.checked }
    const updatedBannerConfig = [...bannerConfig]
    updatedBannerConfig[index] = updatedConfig
    setBannerConfig(updatedBannerConfig)
    updateBannerConfigInMediaAssets(index, updatedConfig)
  }

  return (
    <div className="flex gap-3 flex-wrap mt-2">
      <AssetsRenderer
        assetType={'BANNER'}
        assets={assets}
        onChange={onChange}
        bannerConfig={bannerConfig}
        onColorChange={onColorChange}
        onDefaultChange={onDefaultChange}
        hideOrderControls
        // hideCountries
      />
      <div className={'flex flex-col'}>
        <Dropzone
          onError={setError}
          assetType={'BANNER'}
          onDrop={onChange}
          // multiple={false}
          width={1250}
          height={300}
        />
        {error && <p className="text-red-600 text-sm mt-2">{error}</p>}
      </div>
    </div>
  )
}

BannerSection.propTypes = {
  onChange: func.isRequired,
  assets: array.isRequired
}

BannerSection.defaultProps = {}

export default BannerSection
