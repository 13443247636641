import React, { useState, useRef } from 'react'
import {
  Editor,
  EditorState,
  ContentState,
  RichUtils,
  getDefaultKeyBinding,
  convertToRaw,
  convertFromHTML,
  SelectionState,
  Modifier
} from 'draft-js'

import { convertToHTML } from 'draft-convert'
import DOMPurify from 'dompurify'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

function escapeHtml(unsafe) {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
}

function unEscapeHtml(safe) {
  return safe
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'")
}

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2
  }
}

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote'
    default:
      return null
  }
}

function StyleButton({ onToggle: onToggleFn, style, active, label }) {
  const onToggle = (e) => {
    e.preventDefault()
    onToggleFn(style)
  }

  let className = 'RichEditor-styleButton'
  if (active) {
    className += ' RichEditor-activeButton'
  }

  return (
    <span className={className} onMouseDown={onToggle}>
      {label}
    </span>
  )
}

const BLOCK_TYPES = [
  { label: 'Title', style: 'header-four' },
  // { label: 'Paragraph', style: 'paragraph' },
  { label: 'List', style: 'unordered-list-item' },
  { label: 'Ordered list', style: 'ordered-list-item' }
]

const BlockStyleControls = (props) => {
  const { editorState, disabled, onToggle } = props
  const selection = editorState.getSelection()
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()

  return (
    <div
      className={classNames('RichEditor-controls', {
        'pointer-events-none opacity-60': disabled
      })}
    >
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  )
}

const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' }
]

const InlineStyleControls = (props) => {
  const { editorState, disabled, onToggle } = props
  const currentStyle = editorState.getCurrentInlineStyle()

  return (
    <div
      className={classNames('RichEditor-controls', {
        'pointer-events-none opacity-60': disabled
      })}
    >
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  )
}

function convertDataToState(data) {
  data = data || ''
  const unEscapeData = unEscapeHtml(data || '')
  const blocksFromHTML = convertFromHTML(unEscapeData)

  return ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  )
}

function WysiwygEditor({
  id,
  className: classNameIn,
  data,
  disabled,
  handleFieldChange
}) {
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(convertDataToState(data))
  )

  const editor = useRef(null)

  function focusEditor() {
    editor.current.focus()
  }

  const convertContentToHTML = () => {
    const raw = convertToRaw(editorState.getCurrentContent())
    const acceptedTypes = [
      'unstyled',
      'header-four',
      // 'paragraph',
      'unordered-list-item',
      'ordered-list-item'
    ]

    let textLength = 0
    const contentWithoutBlocks = raw?.blocks.reduce((newContentState, block) => {
      const { type, key, text } = block
      textLength = textLength + text.length

      if (!acceptedTypes.includes(type)) {
        const selectionState = SelectionState.createEmpty(key)
        const updatedSelection = selectionState.merge({
          focusOffset: 0,
          anchorOffset: text?.length
        })

        return Modifier.setBlockType(newContentState, updatedSelection, 'unstyled')
      }

      return newContentState
    }, contentState)

    if (textLength > 5) {
      const currentContentAsHTMLCleaned = DOMPurify.sanitize(
        convertToHTML(contentWithoutBlocks)
      )
      // console.log({ currentContentAsHTMLCleaned });

      handleFieldChange({
        target: { id, value: escapeHtml(currentContentAsHTMLCleaned) }
      })
    } else {
      handleFieldChange({
        target: { id, value: '' }
      })
    }

    // TODO: What is this?
    // setConvertedContent(currentContentAsHTML);
    // setConvertedContent(currentContentAsHTMLCleaned);
  }

  const onChange = (changedEditorState) => {
    setEditorState(changedEditorState)
    convertContentToHTML()
  }

  const handleKeyCommand = (command, editorState) => {
    console.log('here? handleKeyCommand')
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      onChange(newState)
      return true
    }
    return false
  }

  const mapKeyToEditorCommand = (e) => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */)
      if (newEditorState !== editorState) {
        onChange(newEditorState)
      }
      return
    }
    return getDefaultKeyBinding(e)
  }

  const toggleBlockType = (blockType) => {
    onChange(RichUtils.toggleBlockType(editorState, blockType))
  }

  const toggleInlineStyle = (inlineStyle) => {
    onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle))
  }

  let className = 'RichEditor-editor'
  const contentState = editorState.getCurrentContent()

  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      className += ' RichEditor-hidePlaceholder'
    }
  }

  return (
    <div className={classNames('RichEditor-root', classNameIn)}>
      <BlockStyleControls
        editorState={editorState}
        onToggle={toggleBlockType}
        disabled={disabled}
      />
      <InlineStyleControls
        editorState={editorState}
        onToggle={toggleInlineStyle}
        disabled={disabled}
      />
      <div onClick={focusEditor} className={className}>
        <Editor
          blockStyleFn={getBlockStyle}
          customStyleMap={styleMap}
          editorState={editorState}
          readOnly={disabled}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={mapKeyToEditorCommand}
          ref={editor}
          onChange={onChange}
          spellCheck
        />
      </div>
    </div>
  )
}

const GetTranslatedFieldDataHook = (field) => {
  const { t } = useTranslation()

  if (!field) {
    return {}
  }

  const fieldName = field.nameTid ? t(field.nameTid, field.nameTidData) : field.name
  const fieldText = field.textTid ? t(field.textTid, field.textTidData) : field.text

  return { fieldName, fieldText }
}

const Checkboxes = ({ field, data, handleFieldChange }) => {
  const { fieldName, fieldText } = GetTranslatedFieldDataHook(field)
  const { t } = useTranslation()

  return (
    <div className="mt-6">
      <fieldset>
        <legend className="text-base font-medium text-gray-900">{fieldName}</legend>
        <p className="text-sm text-gray-500">{fieldText}</p>
        <div
          className={classNames('mt-2', {
            'flex flex-wrap': !field?.display || field?.display !== 'multiple-column',
            'grid grid-cols-2 md:grid-cols-3 gap-3': field?.display === 'multiple-column'
          })}
        >
          {field.values.map((valueObj) => {
            const { titleTid, textTid, text, title } = valueObj
            const valueObjTitleText = titleTid ? t(titleTid) : title
            const valueObjText = textTid ? t(textTid) : text

            return (
              <div
                className={classNames('relative mr-4 py-1 flex items-start', {
                  'col-span-1': field?.display === 'multiple-column'
                })}
                key={valueObj.id}
              >
                <div className="flex items-center h-5">
                  <input
                    id={`${field.id}--${valueObj.id}`}
                    name={field.id}
                    type="checkbox"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    checked={!!data?.includes(valueObj.id)}
                    onChange={handleFieldChange}
                  />
                </div>
                <div className="ml-2 text-sm">
                  <label
                    htmlFor={`${field.id}--${valueObj.id}`}
                    className="font-medium text-gray-700"
                  >
                    {valueObjTitleText}
                  </label>
                  <p className="text-gray-500">{valueObjText}</p>
                </div>
              </div>
            )
          })}
        </div>
      </fieldset>
    </div>
  )
}
Checkboxes.propTypes = {
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.arrayOf(PropTypes.string),
  handleFieldChange: PropTypes.func.isRequired
}

Checkboxes.defaultProps = {}

const MultipleCheckboxes = ({ field, data, handleFieldChange }) => {
  console.log('HERE?! -- MultipleCheckboxes -> inputs.jsx')
  const { t } = useTranslation()
  return (
    <div className="mt-6">
      <fieldset>
        <legend className="text-base font-medium text-gray-900">{field.name}</legend>
        <p className="text-sm text-gray-500">{field.text}</p>
        <div className="mt-4 space-y-4">
          {field.values?.map((valueObj) => {
            // console.log(
            //   data,
            //   'id:',
            //   valueObj.id,
            //   valueObj.id === data?.includes(valueObj.id)
            // );
            // TODO: Fix checked, here could it be both true/false and YES,ADD,NO
            // ? Maybe remove YES,ADD,NO
            return (
              <div className="relative flex items-start" key={valueObj.id}>
                <div className="flex items-center h-5">
                  <input
                    id={valueObj.id}
                    name={field.id}
                    type="checkbox"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    // checked={!!data?.includes(valueObj.id)}
                    onChange={handleFieldChange}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor={valueObj.id} className="font-medium text-gray-700">
                    {valueObj.titleId ? t(valueObj.titleId) : valueObj.title}
                  </label>
                  <p className="text-gray-500">{valueObj.text}</p>
                </div>
              </div>
            )
          })}
        </div>
      </fieldset>
    </div>
  )
}

const Radiobuttons = ({ field, data, handleFieldChange }) => {
  const { t } = useTranslation()
  const { fieldName, fieldText } = GetTranslatedFieldDataHook(field)

  return (
    <div className="mt-6">
      <fieldset className="mt-6">
        <div>
          <legend className="text-base font-medium text-gray-900">{fieldName}</legend>
          <p className="text-sm text-gray-500">{fieldText}</p>
        </div>
        <div className="mt-2 flex flex-wrap">
          {field.values.map((valueObj) => {
            const { titleTid, title, textTid, text } = valueObj
            const valueObjTitle = titleTid ? t(titleTid) : title
            const valueObjText = textTid ? t(textTid) : text

            return (
              <div className="mr-4 py-1 flex items-center" key={valueObj.id}>
                <input
                  id={`${field.id}--${valueObj.id}`}
                  name={field.id}
                  value={valueObj.id}
                  checked={valueObj.id === data?.toString()}
                  type="radio"
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  onChange={handleFieldChange}
                  readOnly={!handleFieldChange}
                />
                <label
                  htmlFor={`${field.id}--${valueObj.id}`}
                  className="ml-3 block text-sm font-medium text-gray-700"
                >
                  {valueObjTitle}
                </label>
                <p className="text-gray-500">{valueObjText}</p>
              </div>
            )
          })}
        </div>
      </fieldset>
    </div>
  )
}

const RadiobuttonsRows = ({ field, data, type, category, handleFieldChange }) => {
  const { fieldName, fieldText } = GetTranslatedFieldDataHook(field)
  const { t } = useTranslation()

  if (!field || !field.values) {
    return null
  }

  return (
    <div className="mt-6">
      <fieldset className="mt-6">
        <div>
          <legend className="text-base font-medium text-gray-900">{fieldName}</legend>
          <p className="text-sm text-gray-500">{fieldText}</p>
        </div>
        <div className="mt-4 space-y-8">
          {field.values.map((valueObj) => {
            let inputs

            if (type === 'multiple') {
              inputs = (
                <div
                  className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10"
                  key={valueObj.id}
                >
                  <div className="flex items-center">
                    <input
                      id={`${field.id}--${valueObj.id}--YES`}
                      name={`${field.id}--${valueObj.id}`}
                      value={`${field.id}--${valueObj.id}--YES`}
                      checked={valueObj.value === 'YES'}
                      type="radio"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      onChange={handleFieldChange}
                    />
                    <label
                      htmlFor={`${field.id}--${valueObj.id}--YES`}
                      className="ml-1 mr-5 block text-sm font-medium text-gray-700"
                    >
                      {t('exists')}
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id={`${field.id}--${valueObj.id}--NO`}
                      name={`${field.id}--${valueObj.id}`}
                      value={`${field.id}--${valueObj.id}--NO`}
                      checked={valueObj.value === 'NO'}
                      type="radio"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      onChange={handleFieldChange}
                    />
                    <label
                      htmlFor={`${field.id}--${valueObj.id}--NO`}
                      className="ml-1 block text-sm font-medium text-gray-700"
                    >
                      {t('doesNotExist')}
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id={`${field.id}--${valueObj.id}--ADD`}
                      name={`${field.id}--${valueObj.id}`}
                      value={`${field.id}--${valueObj.id}--ADD`}
                      checked={valueObj.value === 'ADD'}
                      type="radio"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      onChange={handleFieldChange}
                    />
                    <label
                      htmlFor={`${field.id}--${valueObj.id}--ADD`}
                      className="ml-1 mr-5 block text-sm font-medium text-gray-700"
                    >
                      {t('canBeAdded')}
                    </label>
                  </div>
                </div>
              )
            }

            if (type === 'boolean') {
              inputs = (
                <div
                  className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10"
                  key={valueObj.id}
                >
                  <div className="flex items-center">
                    <input
                      id={`${field.id}--${valueObj.id}--true`}
                      name={`${field.id}--${valueObj.id}`}
                      value={`${field.id}--${valueObj.id}--true`}
                      checked={valueObj.value?.toString() === 'true'}
                      type="radio"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      onChange={handleFieldChange}
                    />
                    <label
                      htmlFor={`${field.id}--${valueObj.id}--true`}
                      className="ml-1 mr-5 block text-sm font-medium text-gray-700"
                    >
                      {t('yes')}
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id={`${field.id}--${valueObj.id}--false`}
                      name={`${field.id}--${valueObj.id}`}
                      value={`${field.id}--${valueObj.id}--false`}
                      checked={valueObj.value?.toString() === 'false'}
                      type="radio"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      onChange={handleFieldChange}
                    />
                    <label
                      htmlFor={`${field.id}--${valueObj.id}--false`}
                      className="ml-1 mr-5 block text-sm font-medium text-gray-700"
                    >
                      {t('no')}
                    </label>
                  </div>
                </div>
              )
            }

            let translation
            let translationHelpText

            if (field.id === 'systemAreas') {
              translation = t(`prodArea:${category}.${valueObj.title}`)
            } else if (field.id === 'systemGuideProps') {
              translation = t([`prodGuide:${category}.${valueObj.title}`])
            } else {
              translation = t([
                `prodFunc:${category}.${valueObj.title}`,
                `prodFunc:common.${valueObj.title}`
              ])

              translationHelpText = t([
                `prodFuncDesc:${category}.${valueObj.title}`,
                `prodFuncDesc:common.${valueObj.title}`
              ])
            }

            return (
              <div key={valueObj.id}>
                <label className="text-base font-normal text-gray-900">
                  {translation}
                </label>
                <p className="text-gray-500">{valueObj.text}</p>
                <p className="text-sm text-gray-500">
                  {field.id === 'systemProps' &&
                    !translationHelpText?.includes('prodFunc') &&
                    translationHelpText}
                </p>
                <div className="mt-2">{inputs}</div>
              </div>
            )
          })}
        </div>
      </fieldset>
    </div>
  )
}

const File = ({ field }) => {
  const { t } = useTranslation()
  const { fieldName } = GetTranslatedFieldDataHook(field)

  return (
    <div>
      <label htmlFor={field.id}>{fieldName}</label>
      <input type="file" name={field.id} id={field.id} disabled={field.disabled} />
      <button type="button">{t('content:save')}</button>
    </div>
  )
}

const Email = ({ field }) => {
  return (
    <div className="sm:col-span-4">
      <label htmlFor={field.id} className="block text-sm font-medium text-gray-700">
        Email address - fix this
      </label>
      <div className="mt-1">
        <input
          id={field.id}
          name="email"
          type="email"
          autoComplete="email"
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>
  )
}

const Wysiwyg = ({ field, data, isAdmin, isBasic, handleFieldChange }) => {
  const { t } = useTranslation()
  const { fieldName, fieldText } = GetTranslatedFieldDataHook(field)
  const disabled = field.disabled || (isBasic && field.paid)

  return (
    <div className="mt-4 sm:col-span-6">
      <label htmlFor={field.id} className="block text-sm font-medium text-gray-700">
        {fieldName}
      </label>
      <p className="text-sm text-gray-500">{fieldText}</p>
      {isBasic && field.paid && (
        <p className="text-sm text-red-500">
          {t('content:warning.onlyUsableByPaidCustomers')}
        </p>
      )}
      <div className="mt-1">
        {typeof window !== 'undefined' && (
          <WysiwygEditor
            key={`${field.id}-${field.key}`}
            className={classNames({
              'opacity-50': disabled && !isAdmin
            })}
            id={field.id}
            data={data}
            disabled={disabled && !isAdmin}
            handleFieldChange={handleFieldChange}
          />
        )}
      </div>
    </div>
  )
}

const Textarea = ({ field, data, isBasic, isAdmin, handleFieldChange }) => {
  const { t } = useTranslation()
  const { fieldName, fieldText } = GetTranslatedFieldDataHook(field)
  const disabled = field.disabled || (isBasic && field.paid)

  return (
    <div className="mt-4 sm:col-span-6">
      <label htmlFor={field.id} className="block text-sm font-medium text-gray-700">
        {fieldName}
      </label>
      <p className="text-sm text-gray-500">{fieldText}</p>
      {isBasic && field.paid && (
        <p className="text-sm text-red-500">
          {t('content:warning.onlyUsableByPaidCustomers')}
        </p>
      )}
      <div className="mt-1">
        <textarea
          id={field.id}
          name={field.id}
          rows={5}
          className={classNames(
            'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md',
            {
              'disabled:opacity-50': disabled && !isAdmin
            }
          )}
          value={data}
          disabled={disabled && !isAdmin}
          onChange={handleFieldChange}
        />
      </div>
    </div>
  )
}

const Select = ({ field, handleFieldChange, value, classNames }) => {
  const { t } = useTranslation()
  const { fieldName, fieldText } = GetTranslatedFieldDataHook(field)

  return (
    <div className={classNames || 'mt-4 sm:col-span-4'} key={field.id}>
      {fieldName && (
        <label htmlFor={field.id} className="block text-sm font-medium text-gray-700">
          {fieldName}
        </label>
      )}
      {fieldText && <p className="text-sm text-gray-500">{fieldText}</p>}
      <div className={classNames || 'mt-1'}>
        <select
          id={field.id}
          name={field.id}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          onChange={handleFieldChange}
          value={value || undefined}
        >
          <option id="" value="">
            -- {t('content:placeholders.select.select')} --
          </option>
          {field.values?.map((valueObj) => {
            const title = valueObj.titleId ? t(valueObj.titleId) : valueObj.title
            return (
              <option value={valueObj.id} key={valueObj.id}>
                {title}
              </option>
            )
          })}
        </select>
      </div>
    </div>
  )
}

const Number = ({ field, data, isAdmin, isBasic, handleFieldChange }) => {
  const { t } = useTranslation()
  const { fieldName, fieldText } = GetTranslatedFieldDataHook(field)
  const disabled = field.disabled || (isBasic && field.paid)

  const handleWheel = (e) => {
    e.target?.blur()
    e.stopPropagation()
  }

  return (
    <div className="mt-4 sm:col-span-4" key={field.id}>
      <label htmlFor={field.id} className="block text-sm font-medium text-gray-700">
        {fieldName}
      </label>
      <p className="text-sm text-gray-500">{fieldText}</p>
      {isBasic && field.paid && (
        <p className="text-sm text-red-500">
          {t('content:warning.onlyUsableByPaidCustomers')}
        </p>
      )}
      <div className="mt-1">
        <input
          id={field.id}
          name={field.id}
          type="number"
          disabled={disabled && !isAdmin}
          value={data}
          step={field.step}
          onChange={handleFieldChange}
          className={classNames(
            'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md',
            {
              'disabled:opacity-50': disabled && !isAdmin
            }
          )}
          onWheel={handleWheel}
        />
      </div>
    </div>
  )
}

Number.propTypes = {
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.number,
  handleFieldChange: PropTypes.func.isRequired
}

Number.defaultProps = {
  data: undefined
}

const Text = ({ field, data, isAdmin, isBasic, handleFieldChange }) => {
  const { t } = useTranslation()
  const { fieldName, fieldText } = GetTranslatedFieldDataHook(field)
  const disabled = field.disabled || (isBasic && field.paid)
  const fieldDisabled = field.forceDisabled || (disabled && !isAdmin)

  return (
    <div className="mt-4 sm:col-span-4" key={field.id}>
      <label htmlFor={field.id} className="block text-sm font-medium text-gray-700">
        {fieldName}
      </label>
      <p className="text-sm text-gray-500">{fieldText}</p>
      {isBasic && field.paid && (
        <p className="text-sm text-red-500">
          {t('content:warning.onlyUsableByPaidCustomers')}
        </p>
      )}
      <div className="mt-1">
        <input
          id={field.id}
          name={field.id}
          type="text"
          disabled={fieldDisabled}
          value={data || ''}
          onChange={handleFieldChange}
          className={classNames(
            'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md',
            {
              'disabled:opacity-50': fieldDisabled
            }
          )}
        />
      </div>
    </div>
  )
}

Text.propTypes = {
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.string,
  handleFieldChange: PropTypes.func.isRequired
}

Text.defaultProps = {
  data: ''
}

export {
  Checkboxes,
  MultipleCheckboxes,
  Radiobuttons,
  RadiobuttonsRows,
  File,
  Email,
  Wysiwyg,
  Textarea,
  Select,
  Number,
  Text
}
