import React, { createContext, useState } from 'react'
import { useFetchAdminBadges } from '../hooks/useFetchAdminBadges'
import useCategoriesOptions from '../../../hooks/useCategoriesOptions'
import { DEFAULT_ATTRIBUTES, locales } from '../constants/badge-constants'
import { useUpdateBadge } from '../hooks/useUpdateBadge'

const BadgeContext = createContext()

export function BadgeProvider({ children }) {
  const [badgeType, setBadgeType] = useState('')
  const [expiration, setExpiration] = useState('')
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedCategoryLocale, setSelectedCategoryLocale] = useState('en_GB')
  const [year, setYear] = useState(new Date().getFullYear().toString())
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(12)
  const [rating, setRating] = useState(2.5)
  const [activeFilters, setActiveFilters] = useState({
    categories: []
  })

  const [attributesByLocale, setAttributesByLocale] = useState(() => {
    const initial = {}
    locales.forEach((locale) => {
      initial[locale] = DEFAULT_ATTRIBUTES
    })
    return initial
  })

  const currentAttributes = attributesByLocale[selectedCategoryLocale]

  const updateAttributes = (attributeKey, newValues) => {
    setAttributesByLocale((prev) => ({
      ...prev,
      [selectedCategoryLocale]: {
        ...prev[selectedCategoryLocale],
        [attributeKey]: {
          ...prev[selectedCategoryLocale][attributeKey],
          ...newValues
        }
      }
    }))
  }

  const { categoriesOptions } = useCategoriesOptions(true, selectedCategoryLocale)
  const badges = useFetchAdminBadges(limit, page, activeFilters)
  const { updateBadge } = useUpdateBadge()

  return (
    <BadgeContext.Provider
      value={{
        attributes: currentAttributes,
        badges,
        badgeType,
        setBadgeType,
        expiration,
        setExpiration,
        categoriesOptions,
        selectedCategory,
        setSelectedCategory,
        selectedCategoryLocale,
        setSelectedCategoryLocale,
        page,
        limit,
        setLimit,
        setPage,
        year,
        setYear,
        updateBadge,
        updateAttributes,
        attributesByLocale,
        setAttributesByLocale,
        activeFilters,
        setActiveFilters,
        rating,
        setRating
      }}
    >
      {children}
    </BadgeContext.Provider>
  )
}

export default BadgeContext
