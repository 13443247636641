import { gql, useMutation } from '@apollo/client'

const CREATE_CUSTOM_LOG = gql`
  mutation CreateCustomLog($data: Json!, $action: String!) {
    appCreateCustomLog(data: $data, action: $action)
  }
`

export function useCreateCustomLog() {
  const [appCreateCustomLog, { loading, error, data }] = useMutation(CREATE_CUSTOM_LOG)

  const createCustomLog = async ({ data, action }) => {
    const response = await appCreateCustomLog({
      variables: { data, action }
    })
    return response.data?.appCreateCustomLog
  }

  return {
    createCustomLog,
    loading,
    error,
    data
  }
}
