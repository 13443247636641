export function getGlobalApprovalStatus(approvalStatusByLocale) {
  if (!approvalStatusByLocale || Object.keys(approvalStatusByLocale).length === 0) {
    return 'PENDING'
  }
  const statuses = Object.values(approvalStatusByLocale)

  if (statuses.includes('REJECTED')) {
    return 'REJECTED'
  }

  if (statuses.every((status) => status === 'APPROVED')) {
    return 'APPROVED'
  }
  return 'PENDING'
}
