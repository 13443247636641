import cx from 'classnames'
import {
  CalendarIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  PencilIcon,
  QuestionMarkCircleIcon,
  XCircleIcon
} from '@heroicons/react/solid'
import React, { useContext, useState } from 'react'
import { DateTime } from 'luxon'
import useGetCategoryName from '../../../../hooks/useGetCategoryName'
import { ClockIcon } from '@heroicons/react/outline'
import Flag from '../../../shared/flag'
import BadgeTemplate from '../badge-generator/BadgeTemplate'
import Button from '../../../shared/button'
import BadgeContext from '../../context/BadgeContext'
import { locales } from '../../constants/badge-constants'
import { getGlobalApprovalStatus } from '../../helper/global-badge.helper'
import BadgeSystemConnection from '../badge-generator/BadgeSystemConnection'
import EditBadgeDialog from './EditBadgeDialog'
import AdminField from '../../../shared/admin-field'
import { useDeleteBadge } from '../../hooks/useDeleteBadge'
import { useTranslation } from 'react-i18next'

const BadgeAdminListItem = ({ badge }) => {
  const { t } = useTranslation('adminPages')
  const { updateBadge, badges } = useContext(BadgeContext)
  const { deleteBadge } = useDeleteBadge()
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen((prev) => !prev)
  const { getCategoryName } = useGetCategoryName()
  const [selectedLocale, setSelectedLocale] = useState('en_GB')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const currentAttributes = badge.attributes[selectedLocale]

  const handleApprovalStatus = async (type) => {
    await updateBadge({
      badgeId: badge.id,
      data: {
        approvalStatusByLocale: {
          ...badge.approvalStatusByLocale,
          [selectedLocale]: type
        }
      }
    })
    await badges.refetch()
  }
  const globalMarkupStatus = getGlobalApprovalStatus(badge?.approvalStatusByLocale)
  return (
    <li className={'bg-white'}>
      <div
        className={cx('block cursor-pointer hover:bg-gray-50 border-l-8', {
          'border-grey-600': globalMarkupStatus === 'PENDING',
          'border-green-600': globalMarkupStatus === 'APPROVED',
          'border-red-600': globalMarkupStatus === 'REJECTED'
        })}
        type="button"
        onClick={toggleOpen}
      >
        <div className={'flex items-center  p-4 sm:px-6'}>
          <div className="min-w-0 flex-1 flex gap-10 items-center">
            <div className="flex-shrink-0">
              <p className={'text-sm uppercase font-medium text-gray-500'}>
                {badge.attributes['en_GB'].headlineAttribute.title}
              </p>
              <p
                title={t('allBadges.createdAt')}
                className="mt-2 flex items-center text-sm text-gray-500"
              >
                <CalendarIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                {DateTime.fromISO(badge.createdAt).toFormat('yyyy-MM-dd HH:mm:ss')}
              </p>
            </div>

            <div className="min-w-0 flex-1 flex items-center">
              <p
                className={'text-sm font-semibold bg-gray-400 text-white p-1 rounded-sm'}
              >
                {getCategoryName(badge.category)}
              </p>
            </div>
            <div className="min-w-0 flex-1 flex items-center   max-md:hidden">
              <p className="mt-2 flex items-center text-sm text-gray-500">
                {t('allBadges.connectedSystems')} {badge.badgeToSystem?.length}
              </p>
            </div>
            <div className="min-w-0 flex-1 flex items-center  max-md:hidden">
              <p
                title={t('allBadges.expiresAt')}
                className="mt-2 flex items-center text-sm text-gray-500"
              >
                <ClockIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                {DateTime.fromISO(badge.expiresAt).toFormat('yyyy-MM-dd')}
              </p>
            </div>
          </div>
          <div>
            {isOpen ? (
              <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            ) : (
              <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            )}
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="mx-5 my-5 sm:mx-10">
          <div className={'grid grid-cols-5 max-md:grid-cols-1 items-start gap-4'}>
            <div className={"col-span-2 max-md:col-span-1'"}>
              <div className={'flex justify-between items-center relative'}>
                <p className={'text-sm text-gray-500'}> {t('allBadges.badgePreview')}</p>
                <PencilIcon
                  onClick={() => setIsModalOpen(true)}
                  className={
                    'border border-gray-300 p-1 rounded-md w-8 h-8 text-gray-500 absolute right-0 top-[-5px] cursor-pointer hover:bg-indigo-600 hover:text-white'
                  }
                />
              </div>
              <hr className={'my-5'} />
              <div className="flex justify-center gap-2">
                {locales.map((locale) => {
                  return (
                    <div
                      key={locale}
                      className={'flex flex-col justify-center items-center gap-2'}
                    >
                      <div
                        onClick={() => setSelectedLocale(locale)}
                        className={cx(
                          'flex-shrink-0 flex justify-center items-center p-3 border-2 rounded-md cursor-pointer hover:border-indigo-600',
                          {
                            'border-indigo-600': locale === selectedLocale
                          }
                        )}
                      >
                        <div>
                          <div className="flex  rounded-full bg-cover bg-center">
                            <Flag height={'30px'} locale={locale} />
                          </div>
                        </div>
                      </div>
                      {badge.approvalStatusByLocale[locale] === 'APPROVED' ? (
                        <CheckCircleIcon className="h-5 w-5 text-green-400" />
                      ) : badge.approvalStatusByLocale[locale] === 'REJECTED' ? (
                        <XCircleIcon className="h-5 w-5 text-red-400" />
                      ) : (
                        <QuestionMarkCircleIcon className="h-5 w-5 text-gray-400" />
                      )}
                    </div>
                  )
                })}
              </div>
              <hr className={'my-5'} />
              <BadgeTemplate
                className={'shadow-none !p-0'}
                width={300}
                height={300}
                rating={badge.rating}
                attributes={currentAttributes}
                category={badge.category}
                categoryLocale={selectedLocale}
                isDraggable={false}
                badgeType={badge.type}
                headline={badge.headline}
                subHeadline={badge.subHeadline}
                year={badge.year}
              />
            </div>
            <div className={'col-span-3 max-md:col-span-1'}>
              <p className={'text-sm text-gray-500'}> {t('allBadges.badgeInfo')}</p>
              <hr className={'my-5'} />
              <div
                className={
                  '[&_p]:uppercase [&_p]:text-gray-700 [&_p]:text-sm [&_p]:font-medium flex gap-3 '
                }
              >
                <p className={'flex gap-3 items-center p-3 shadow-md rounded-md'}>
                  <span> {t('allBadges.badgeType')}</span>
                  <span className={'text-white bg-blue-500 p-1 rounded-md'}>
                    {badge.type}
                  </span>
                </p>
                <p
                  className={'flex gap-3 items-center p-3 shadow-md rounded-md bg-white'}
                >
                  <span> {t('allBadges.status')}</span>
                  <span
                    className={cx('text-white bg-gray-600 p-1 rounded-md', {
                      'bg-red-700':
                        badge.approvalStatusByLocale[selectedLocale] === 'REJECTED',
                      'bg-green-500':
                        badge.approvalStatusByLocale[selectedLocale] === 'APPROVED'
                    })}
                  >
                    {badge.approvalStatusByLocale[selectedLocale]}
                  </span>
                </p>
              </div>
              <hr className={'my-6'} />
              <div>
                <p className={'text-sm text-gray-500 my-2'}>
                  {t('allBadges.connectSystemToBadge')}
                </p>
                <BadgeSystemConnection badge={badge} refetchBadges={badges.refetch} />
              </div>
              <hr className={'my-6'} />
              <div>
                <div className={'flex gap-3 items-center justify-end'}>
                  <Button
                    onClick={() => {
                      handleApprovalStatus('REJECTED')
                    }}
                    disabled={badge.approvalStatusByLocale[selectedLocale] === 'REJECTED'}
                    mode={'error'}
                  >
                    {t('allBadges.button.reject')}
                  </Button>
                  <Button
                    onClick={() => {
                      handleApprovalStatus('APPROVED')
                    }}
                    disabled={badge.approvalStatusByLocale[selectedLocale] === 'APPROVED'}
                    mode={'primary'}
                  >
                    {t('allBadges.button.approve')}
                  </Button>
                </div>
              </div>
              <AdminField>
                <p> {t('allBadges.actionCantBeUndone')}</p>
                <div className={'flex justify-end'}>
                  <Button
                    onClick={async () => {
                      await deleteBadge(badge.id)
                      await badges.refetch()
                    }}
                    className={'text-end'}
                    mode={'error'}
                  >
                    {t('allBadges.button.delete')}
                  </Button>
                </div>
              </AdminField>
            </div>
          </div>
          <EditBadgeDialog
            locale={selectedLocale}
            badge={badge}
            selectedAttributes={currentAttributes}
            isOpen={isModalOpen}
            onClose={setIsModalOpen}
          />
        </div>
      )}
    </li>
  )
}

export default BadgeAdminListItem
