import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuthContext, useUserContext } from '../contexts'
import { isAdmin } from '../utils'

const ProtectedRoute = ({
  children,
  requireIsAdmin,
  allowNotAcceptedTerms,
  allowFirstLogin,
  allowNoVendor = false,
  allowMR
}) => {
  // Identify authenticated user
  const { auth } = useAuthContext()
  const { user } = useUserContext()
  const isAuthenticated = auth?.isLoggedIn
  const isFirstLogin = user?.isFirstLogin
  const isAccepted = user?.accepted
  const hasVendor = !!user?.company
  const hasAdmin = isAdmin(user?.role)
  const isMR = user?.role === 'MARKET_RESEARCHER'
  const location = useLocation()

  if (!isAuthenticated) {
    const { pathname } = location

    if (pathname !== '/login') {
      const tempStorage = window.sessionStorage
      tempStorage.setItem('requestedPath', pathname)
    }

    return <Navigate to="/login" />
  }

  if (isFirstLogin && !allowFirstLogin) {
    return <Navigate to="/update-password" />
  }

  if (!isAccepted && !allowNotAcceptedTerms) {
    return <Navigate to="/accept" />
  }

  if (isMR && !allowMR) {
    return <Navigate to="/market-researcher" />
  }

  if (!hasVendor && !hasAdmin && !allowNoVendor && !isMR) {
    return <Navigate to="/no-vendor" />
  }

  if (requireIsAdmin && !hasAdmin) {
    return <Navigate to="/" />
  }

  return children
}

export default ProtectedRoute
