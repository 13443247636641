import React from 'react'
import { useDocumentation } from '../../../contexts/documentationContext'
import { pageHasDocumentation } from './documentationContent'

import { BookOpenIcon } from '@heroicons/react/outline'
const DocumentationButton = () => {
  const { openDocumentation, currentPage } = useDocumentation()

  if (!pageHasDocumentation(currentPage)) {
    return null
  }

  return (
    <div
      className={`fixed ${
        currentPage == 'system'
          ? 'flex items-center justify-center h-screen right-5'
          : 'bottom-6 right-6'
      }  z-5`}
    >
      <button
        onClick={openDocumentation}
        className={`flex items-center justify-center w-12 h-12 rounded-full bg-white shadow-lg hover:shadow-xl transition-all duration-300 ease-out transform hover:-translate-y-1 border border-slate-200 text-slate-500`}
        aria-label="Open Documentation"
      >
        <BookOpenIcon
          className="h-6 w-6 rounded-full text-gray-400 hover:text-gray-500"
          aria-hidden="true"
        ></BookOpenIcon>
        <span className="sr-only">Open Documentation</span>
      </button>
    </div>
  )
}

export default DocumentationButton
