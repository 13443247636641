import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import BuyingIntentFilters from './BuyingIntentFilters'
import BuyingIntentCsvExport from './BuyingIntentCsvExport'
import BuyingIntent from './BuyingIntent'
import Loader from '../loader'
import Pagination from '../shared/pagination'
import useGetSystemName from '../../hooks/useGetSystemName'
import { useUserContext } from '../../contexts'
import { isAdmin } from '../../utils'
import { useBuyingIntents } from './hooks/useBuyingIntents'

const BuyingIntentActivities = ({ systemId }) => {
  const { t } = useTranslation('buyingIntent')
  const [activeFilters, setActiveFilters] = useState([])
  const { user } = useUserContext()
  const hasAdmin = isAdmin(user?.role)
  const { getSystemName } = useGetSystemName()

  const {
    page,
    setPage,
    limit,
    setLimit,
    totalCount,
    buyingIntents,
    hasAccess,
    applyFilters,
    resetFilters,
    loading,
    error,
    fetchAllForExport
  } = useBuyingIntents(systemId)

  if (!hasAccess && !hasAdmin && !loading) {
    return <div>{t('content:warning.noData')}</div>
  }

  return (
    <div>
      <div>{hasAdmin && !loading ? `Has access: ${hasAccess}` : ''}</div>

      <div>
        <BuyingIntentFilters
          buyingIntents={buyingIntents}
          applyFilters={applyFilters}
          resetFilters={resetFilters}
          onActiveFiltersChange={setActiveFilters}
          isAdmin={hasAdmin}
        />
      </div>

      <div className="text-end">
        <BuyingIntentCsvExport
          fetchAllForExport={fetchAllForExport}
          getSystemName={getSystemName}
          activeFilters={activeFilters}
        />
      </div>

      <div className="bg-white shadow overflow-hidden mt-3 sm:rounded-md">
        <ul className="divide-y divide-gray-200">
          {!loading ? (
            buyingIntents?.length === 0 ? (
              `${t('content:warning.noData')} ${error?.message || ''}`
            ) : (
              buyingIntents.map((buyingIntent) => {
                return (
                  <BuyingIntent
                    key={buyingIntent.id}
                    signal={buyingIntent}
                    systemId={systemId}
                  />
                )
              })
            )
          ) : (
            <Loader />
          )}
        </ul>
      </div>

      {buyingIntents?.length > 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalCount={totalCount}
          t={t}
        />
      )}

      <div className="w-full flex flex-col gap-3"></div>
    </div>
  )
}

export default BuyingIntentActivities
