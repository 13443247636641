const FIELD_DATA_COMPANY = {
  id: 'company',
  nameTid: 'content:company',
  textTid: 'adminPages:sharedVendorForm.companyText',
  type: 'text'
}

const FIELD_DATA_PIPEDRIVE_ID = {
  id: 'pipedriveId',
  nameTid: 'adminPages:sharedVendorForm.pipedriveId',
  textTid: null,
  type: 'text'
}

const FIELD_DATA_ACCESS_RIGHTS = {
  id: 'accessRights',
  nameTid: 'content:privileges',
  textTid: 'adminPages:sharedVendorForm.companyPrivilegesText',
  type: 'multiple',
  values: [
    {
      id: 'SYSTEMGUIDE',
      titleTid: 'adminPages:sharedVendorForm.privilegesSystemGuide',
      text: null
    },
    {
      id: 'FORMS',
      titleTid: 'adminPages:sharedVendorForm.privilegesContactForm',
      text: null
    },
    {
      id: 'BUYER_INTENT',
      titleTid: 'adminPages:sharedVendorForm.privilegesBuyerIntent',
      text: null
    },
    {
      id: 'BUYER_SESSION_INTENT',
      titleTid: 'adminPages:sharedVendorForm.privilegesBuyerSessionIntent',
      text: null
    },
    {
      id: 'BADGES',
      titleTid: 'adminPages:sharedVendorForm.privilegesBadges',
      text: null
    }
  ]
}

const FIELD_DATA_BUYING_INTENT_BOOST = {
  id: 'buyingIntentBoost',
  nameTid: 'adminPages:sharedVendorForm.buyingIntentBoostText',
  text: null,
  type: 'single',
  values: [
    {
      id: 'true',
      titleTid: 'content:yes',
      text: null
    },
    {
      id: 'false',
      titleTid: 'content:no',
      text: null
    }
  ]
}

const FIELD_DATA_MARKET_REGIONS = {
  id: 'appLocales',
  nameTid: 'content:marketsRegions',
  textTid: 'adminPages:sharedVendorForm.marketsText',
  type: 'multiple',
  values: []
}

const VENDOR_FORM = [
  FIELD_DATA_COMPANY,
  FIELD_DATA_PIPEDRIVE_ID,
  FIELD_DATA_ACCESS_RIGHTS,
  FIELD_DATA_MARKET_REGIONS,
  FIELD_DATA_BUYING_INTENT_BOOST
]

export { VENDOR_FORM }
