import React, { useEffect, useRef } from 'react'
import { useDocumentation } from '../../../contexts/documentationContext'
import {
  getPageDocumentation,
  getPageDocumentationKeys,
  pageHasDocumentation
} from './documentationContent'
import { XCircleIcon } from '@heroicons/react/outline'
import Dialog from '../dialog'

const DocumentationModal = () => {
  const { isOpen, closeDocumentation, currentPage } = useDocumentation()
  const modalContentRef = useRef(null)

  useEffect(() => {
    if (isOpen && modalContentRef.current) {
      const items = modalContentRef.current.querySelectorAll('.doc-item')
      items.forEach((item, index) => {
        setTimeout(() => {
          item.classList.add('animate-in')
        }, 100 + index * 50)
      })
    }
  }, [isOpen, currentPage])

  if (!pageHasDocumentation(currentPage)) {
    return null
  }

  const sectionKeys = getPageDocumentationKeys(currentPage)
  const heading = getPageDocumentation(currentPage, 'heading')
  const tag = getPageDocumentation(currentPage, 'tag')

  return (
    <Dialog
      className="!max-w-4xl"
      isOpen={isOpen}
      loading={false}
      noBackgroundClose={false}
    >
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-xs font-medium uppercase tracking-wider px-2 py-1 rounded-full bg-slate-100 text-slate-600">
            {tag}
          </span>
          <h2 className="text-2xl font-medium mt-2">{heading}</h2>
        </div>

        <button
          onClick={closeDocumentation}
          className="h-10 w-10 rounded-full flex items-center justify-center text-gray-500 hover:text-slate-800 border-0 hover:bg-slate-100 transition-colors"
          aria-label="Close Documentation"
        >
          <XCircleIcon className="h-8 w-8" aria-hidden="true" />
        </button>
      </div>
      <div className="flex flex-col space-y-6">
        <div
          ref={modalContentRef}
          className="overflow-y-auto max-h-[calc(80vh-8rem)] pr-2 "
          style={{
            scrollbarWidth: 'thin',
            scrollbarColor: 'rgba(100, 116, 139, 0.2) transparent'
          }}
        >
          {sectionKeys.map((key) => {
            const content = getPageDocumentation(currentPage, key)
            const isTitle = key.includes('title')

            return (
              <div key={key} className={` ${isTitle ? ' first:mt-0' : ' max-w-2xl'}`}>
                {isTitle ? (
                  <h3 className="text-xl mb-2 mt-4 font-medium  text-gray-700  border-b border-gray-100">
                    {content}
                  </h3>
                ) : (
                  <p className={'text-gray-600'}>{content}</p>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </Dialog>
  )
}

export default DocumentationModal
