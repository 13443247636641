import React from 'react'

function AdminDocumentationPage() {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-6xl font-bold">Admin Documentation Page</h1>
      <p className="text-xl">Open the book icon to read more</p>
    </div>
  )
}

export default AdminDocumentationPage
