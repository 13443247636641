import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useDebouncedCallback } from 'use-debounce'
import { Select } from '../../../shared/select'
import Button from '../../../shared/button'
import Flag from '../../../shared/flag'
import { TrashIcon, XIcon } from '@heroicons/react/outline'
import { QUERY_ADMIN_RESOUCE_SEARCH } from '../../../admin/admin-search-select-dialog'
import { getSystemName } from '../../../../helpers/systems.helper'
import useGetCategoryName from '../../../../hooks/useGetCategoryName'
import { useTranslation } from 'react-i18next'
import { useDisconnectBadgeFromSystem } from '../../hooks/useDisconnectBadgeFromSystem'
import Dialog from '../../../shared/dialog'
import { DocumentTextIcon } from '@heroicons/react/solid'
import { useConnectBadgeToSystem } from '../../hooks/useConncetBadgeToSystem'
import { useFetchSystemsConnectedToBadge } from '../../hooks/useFetchSystemsConnectedToBadge'
import Pagination from '../../../shared/pagination'

const BadgeSystemConnection = ({ badge, refetchBadges, renderListItem }) => {
  const { t } = useTranslation('adminPages')
  const [selectedSystem, setSelectedSystem] = useState(undefined)
  const [selectedSystemSiteData, setSelectedSystemSiteData] = useState('')
  const [availableSystemSites, setAvailableSystemSites] = useState([])
  const [error, setError] = useState(null)
  const { disconnectBadgeToSystem } = useDisconnectBadgeFromSystem()
  const { connectBadgeToSystem } = useConnectBadgeToSystem()
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [systemToDisconnect, setSystemToDisconnect] = useState(null)

  const { getCategoryName } = useGetCategoryName()
  const [adminResourceSearch, { data }] = useLazyQuery(QUERY_ADMIN_RESOUCE_SEARCH)
  const [isListOpen, setIsListOpen] = useState(false)

  const {
    data: connectedSystems,
    loading: loadingConnectedSystems,
    totalCount: totalConnectedSystems,
    refetch: refetchConnectedSystems
  } = useFetchSystemsConnectedToBadge(badge?.id, itemsPerPage, currentPage, !isListOpen)

  // Fetch all connected systems for filtering available sites
  const [allConnectedSystemLocales, setAllConnectedSystemLocales] = useState([])

  useEffect(() => {
    // Create a map of all connected system-locale pairs to use for filtering
    if (connectedSystems && connectedSystems.length > 0) {
      const connectedLocales = connectedSystems.map((system) => ({
        systemId: system.systemV2.id,
        locale: system.locale
      }))
      setAllConnectedSystemLocales(connectedLocales)
    }
  }, [connectedSystems])

  useEffect(() => {
    if (isListOpen) {
      refetchConnectedSystems()
    }
  }, [isListOpen, currentPage, refetchConnectedSystems])

  const adminSearchDebounce = useDebouncedCallback((search) => {
    adminResourceSearch({
      fetchPolicy: 'network-only',
      variables: { search }
    })
  }, 800)

  const allSystems = data?.appAdminResourceSearch?.systems || []
  const allSystemsOptions = allSystems.map((system) => ({
    type: 'SYSTEM',
    label: getSystemName(system),
    value: system.id,
    subtitle: getCategoryName(system.defaultCategory)
  }))

  useEffect(() => {
    if (selectedSystem) {
      const system = allSystems.find((system) => system.id === selectedSystem)
      let availableSites =
        system?.systemSiteData?.map((site) => ({
          label: t('shared:localeToCountry.' + site.locale),
          value: site.locale
        })) || []

      // Filter out sites that are already connected to this system
      availableSites = availableSites.filter((site) => {
        return !allConnectedSystemLocales.some(
          (connected) =>
            connected.systemId === selectedSystem && connected.locale === site.value
        )
      })

      // Add default option as the first item in the dropdown if not already connected
      const isDefaultConnected = allConnectedSystemLocales.some(
        (connected) => connected.systemId === selectedSystem && connected.locale === ''
      )

      const sitesWithDefault = [
        ...(isDefaultConnected ? [] : [{ label: 'Default - English', value: '' }]),
        ...availableSites
      ]

      setAvailableSystemSites(sitesWithDefault)

      // Set default option when system is selected
      setSelectedSystemSiteData('')
    } else {
      setAvailableSystemSites([])
      setSelectedSystemSiteData('')
    }
  }, [selectedSystem, allConnectedSystemLocales])

  const handleOnConnectBadgeToSystem = async () => {
    if (!selectedSystem || !badge.id) {
      setError(t('allBadges.errorSelectSystem'))
      return
    }
    try {
      const isApprovedByLocale =
        badge.approvalStatusByLocale[selectedSystemSiteData || 'en_GB'] === 'APPROVED'

      if (!isApprovedByLocale) {
        throw new Error(t('allBadges.badgeNotApproved'))
      }
      await connectBadgeToSystem({
        badgeId: badge.id,
        locale: selectedSystemSiteData,
        systemId: selectedSystem
      })

      // Update local state by adding the newly connected system-locale pair
      setAllConnectedSystemLocales((prev) => [
        ...prev,
        { systemId: selectedSystem, locale: selectedSystemSiteData }
      ])

      await refetchBadges()
      if (isListOpen) {
        await refetchConnectedSystems()
      }
      resetConnectionForm()
    } catch (err) {
      setError(err.message)
    }
  }

  const handleOnDisconnectBadgeToSystem = async (systemId, locale) => {
    if (!systemId || !badge.id) {
      setError(t('allBadges.errorSomethingWentWrong'))
      return
    }
    await disconnectBadgeToSystem({
      badgeId: badge.id,
      systemId,
      locale
    })

    // Update local state by removing the disconnected system-locale pair
    setAllConnectedSystemLocales((prev) =>
      prev.filter((item) => !(item.systemId === systemId && item.locale === locale))
    )

    setError(null)
    await refetchBadges()
    if (isListOpen) {
      await refetchConnectedSystems()
    }
    setIsConfirmDialogOpen(false)
    setSystemToDisconnect(null)
  }

  const resetConnectionForm = () => {
    setSelectedSystem(undefined)
    setSelectedSystemSiteData('')
    setError(null)
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleDialogOpen = () => {
    setCurrentPage(1)
    setIsListOpen(true)
    // Explicitly trigger the refetch after setting isListOpen to true
    setTimeout(() => {
      if (badge?.id) {
        refetchConnectedSystems()
      }
    }, 0)
  }

  const handleDialogClose = () => {
    setIsListOpen(false)
    setCurrentPage(1)
  }

  const openConfirmationDialog = (systemId, locale, systemName) => {
    setSystemToDisconnect({ id: systemId, locale, name: systemName })
    setIsConfirmDialogOpen(true)
  }

  const closeConfirmationDialog = (e) => {
    // Add this to prevent event propagation to parent dialogs
    if (e && e.stopPropagation) {
      e.stopPropagation()
    }
    setIsConfirmDialogOpen(false)
    setSystemToDisconnect(null)
  }

  return (
    <div className="flex flex-col gap-4">
      <div className={'flex justify-between items-center gap-3'}>
        <div className={'col-span-3'}>
          <Select
            value={selectedSystem}
            onChange={(value) => setSelectedSystem(value)}
            options={allSystemsOptions}
            renderListItem={renderListItem}
            freeInput={true}
            rawChangeEvent={false}
            isClearable={true}
            onInputChange={(input) => adminSearchDebounce(input)}
          />
        </div>
        <div className={'col-span-3'}>
          <Select
            value={selectedSystemSiteData}
            onChange={setSelectedSystemSiteData}
            options={availableSystemSites}
            isClearable={true}
            rawChangeEvent={false}
          />
        </div>
        <div className={'col-span-2 gap-3 flex '}>
          <div>
            <DocumentTextIcon
              onClick={handleDialogOpen}
              className={
                'cursor-pointer hover:bg-indigo-600 hover:text-white w-10 h-10 p-1 text-gray-500 rounded-md border'
              }
            />
          </div>
          <Button onClick={handleOnConnectBadgeToSystem} disabled={!selectedSystem}>
            {t('allBadges.button.add')}
          </Button>
        </div>
      </div>

      {error && <div className={'text-red-600 text-sm my-2'}>{error}</div>}
      <Dialog
        className="!max-w-3xl"
        onClose={handleDialogClose}
        isOpen={isListOpen}
        title={t('allBadges.connectedSystemList')}
      >
        <div className={'grid grid-cols-1 gap-3'}>
          {loadingConnectedSystems ? (
            <div className="text-center py-4">{t('common:loading')}</div>
          ) : connectedSystems.length === 0 && isListOpen ? (
            <div className="text-center py-4">{t('allBadges.noConnectedSystems')}</div>
          ) : (
            connectedSystems.map((system, index) => {
              return (
                <div
                  key={index}
                  className={
                    ' flex justify-between items-center border border-gray-200 p-3 rounded-md'
                  }
                >
                  <div>
                    <p className={'w-52'}>{getSystemName(system.systemV2)}</p>
                    <p className={'text-xs font-semibold italic text-gray-300'}>
                      Awarded at {new Date(system.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                  <Flag locale={system.locale} height={'35px'} />
                  <TrashIcon
                    onClick={(e) => {
                      // Stop event propagation
                      e.stopPropagation()
                      openConfirmationDialog(
                        system.systemV2.id,
                        system.locale,
                        getSystemName(system.systemV2)
                      )
                    }}
                    width={35}
                    height={35}
                    color={'red'}
                    className="cursor-pointer p-2 border border-red-500 rounded-md"
                  />
                </div>
              )
            })
          )}
        </div>
        {totalConnectedSystems > itemsPerPage && (
          <div className="mt-4">
            <Pagination
              currentPage={currentPage}
              totalItems={totalConnectedSystems}
              pageSize={itemsPerPage}
              onPageChange={handlePageChange}
            />
          </div>
        )}

        {isConfirmDialogOpen && (
          <div
            className="fixed inset-0 z-50 flex items-center justify-center"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className="absolute inset-0 bg-black bg-opacity-30"
              onClick={closeConfirmationDialog}
            ></div>
            <div className="relative bg-white rounded-lg p-4 max-w-xl w-full shadow-xl">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium">
                  {t('allBadges.confirmDisconnect')}
                </h3>
                <button
                  type="button"
                  onClick={closeConfirmationDialog}
                  className="text-gray-400 hover:text-gray-500 focus:outline-none"
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" />
                </button>
              </div>
              <div className="py-4">
                <p className="mb-4">
                  {t('allBadges.confirmDisconnectMessage', {
                    system: systemToDisconnect?.name
                  })}
                </p>
                <div className="flex justify-end gap-3">
                  <Button mode="default" onClick={closeConfirmationDialog}>
                    {t('allBadges.cancel')}
                  </Button>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      handleOnDisconnectBadgeToSystem(
                        systemToDisconnect?.id,
                        systemToDisconnect?.locale
                      )
                    }}
                  >
                    {t('allBadges.confirm')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  )
}

export default BadgeSystemConnection
