import React, { useContext } from 'react'
import BadgeContext from '../../context/BadgeContext'
import RecentBadgeListItem from './RecentBadgeListItem'
import LoadingSpinner from '../../../shared/loading-spinner'
import { useDeleteBadge } from '../../hooks/useDeleteBadge'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next'

const RecentBadgeList = () => {
  const { t } = useTranslation('adminPages')
  const { badges } = useContext(BadgeContext)
  const { deleteBadge } = useDeleteBadge()

  if (badges?.loading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    )
  }
  if (badges?.error) {
    return (
      <div className={'p-3 bg-red-100 text-red-500 rounded-lg'}>
        {t('badgeGenerator.errorFetchingBadges')}
      </div>
    )
  }
  const hasAnyBadges = badges?.data?.length > 0
  if (!hasAnyBadges) {
    return (
      <div
        className={
          'flex items-center gap-2 text-blue-500 p-3 bg-blue-100 rounded-lg border border-blue-300 my-5'
        }
      >
        <ExclamationCircleIcon width={25} />
        {t('badgeGenerator.noBadgesAtMoment')}
      </div>
    )
  }

  return (
    <div className="rounded-2xl my-2 flex flex-col gap-5">
      {badges?.data?.map((b) => (
        <RecentBadgeListItem
          key={b.id}
          badgeId={b.id}
          attributes={b.attributes}
          category={b.category}
          year={b.year}
          badgesToSystem={b.badgeToSystem}
          type={b.type}
          createdAt={b.createdAt}
          expireAt={b.expiresAt}
          onDelete={async (badgeId) => {
            await deleteBadge(badgeId)
            badges.refetch()
          }}
        />
      ))}
    </div>
  )
}

export default RecentBadgeList
