import React from 'react'
import { bool, func, number, object, string } from 'prop-types'
import classnames from 'classnames'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { CloudUploadIcon } from '@heroicons/react/outline'
import Tile from './tile'

const Dropzone = ({ assetType, onDrop, onError, multiple, accept, width, height }) => {
  const { t } = useTranslation()

  /**
   * Validate image resolution for banner section only
   * @param file
   * @returns {Promise<unknown>}
   */
  const validateImageResolution = (file) => {
    return new Promise((resolve, reject) => {
      const needsValidation = assetType === 'BANNER' && (width != null || height != null)

      if (!needsValidation) {
        return resolve(file)
      }
      const image = new Image()
      image.onload = () => {
        const actualWidth = image.width
        const actualHeight = image.height

        const errors = []

        if (width != null && actualWidth < width) {
          errors.push(`width < ${width}px (actual: ${actualWidth}px)`)
        }

        if (height != null && actualHeight < height) {
          errors.push(`height < ${height}px (actual: ${actualHeight}px)`)
        }

        if (errors.length > 0) {
          if (onError) {
            onError(`Accepted image resolution ${width} x ${height} pixels`)
          }
          reject(`Accepted image resolution ${width} x ${height} pixels`)
        } else {
          resolve(file)
        }
      }
      image.onerror = () => {
        reject(`Could not load image: ${file.name}`)
        if (onError) {
          onError(`Could not load image: ${file.name}`)
        }
      }
      image.src = file.src || URL.createObjectURL(file)
    })
  }

  const handleDrop = async (files) => {
    if (onError) {
      onError('')
    }
    try {
      const validations = files.map((file) => {
        if (file.type && file.type.startsWith('image/')) {
          return validateImageResolution(file)
        }
        return file
      })

      const validFiles = await Promise.all(validations)
      onDrop('UPLOAD', assetType, { files: validFiles })
    } catch (error) {
      if (typeof error === 'string') {
        if (onError) {
          onError(error)
        }
      } else {
        if (onError) {
          onError('Some files failed the image resolution check.')
        }
      }
    }
  }

  const { isDragAccept, isDragReject, getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop: handleDrop,
    multiple
  })

  return (
    <Tile
      className={classnames(
        'flex justify-center items-center flex-col hover:bg-gray-200 active:bg-gray-300 transition-colors cursor-pointer',
        {
          'bg-red-200': isDragReject,
          'bg-green-200': isDragAccept
        }
      )}
      {...getRootProps()}
    >
      <div className="flex flex-col items-center">
        <input {...getInputProps()} />
        <CloudUploadIcon className="w-8 h-8 text-gray-500 mb-1" />

        {isDragAccept ? (
          <span className="text-gray-700 text-sm italic text-center">
            {t('forms:system.assets.dropzone.image.releaseToUpload')}
          </span>
        ) : isDragReject ? (
          <span className="text-gray-700 text-sm italic text-center">
            {t('forms:system.assets.dropzone.image.onlyImageFormats')}
          </span>
        ) : (
          <span className="text-gray-700 text-sm italic text-center">
            {assetType === 'DOCUMENT'
              ? t('forms:system.assets.dropzone.document.upload')
              : t('forms:system.assets.dropzone.image.upload')}
          </span>
        )}
      </div>
    </Tile>
  )
}

Dropzone.propTypes = {
  onDrop: func.isRequired,
  assetType: string.isRequired,
  accept: object.isRequired,
  multiple: bool,
  width: number,
  height: number
}

Dropzone.defaultProps = {
  multiple: true,
  accept: {
    'image/jpeg': [],
    'image/png': [],
    'image/webp': []
  },
  width: null,
  height: null
}

export default Dropzone
