export function measureTextWidth(text, font = '16px sans-serif') {
  const canvas =
    measureTextWidth.canvas ||
    (measureTextWidth.canvas = document.createElement('canvas'))
  const context = canvas.getContext('2d')
  context.font = font
  return context.measureText(text).width
}

export function wrapTextToWidth(text, maxWidthPx, font = '16px sans-serif') {
  const words = text.split(' ')
  const lines = []
  let currentLine = ''

  words.forEach((word, i) => {
    const testLine = currentLine ? `${currentLine} ${word}` : word
    const testWidth = measureTextWidth(testLine, font)
    if (testWidth > maxWidthPx && currentLine) {
      lines.push(currentLine)
      currentLine = word
    } else {
      currentLine = testLine
    }
  })

  if (currentLine) {
    lines.push(currentLine)
  }

  return lines
}
