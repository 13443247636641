import React, { useState } from 'react'
import classNames from 'classnames'

import { gql, useMutation } from '@apollo/client'
import { PencilAltIcon } from '@heroicons/react/solid'
import Dialog from '../../shared/dialog'
import { useTranslation } from 'react-i18next'

import { useFormFields } from '../../../hooks'
import Fields from '../../form/fields'

const UPDATE_EMAIL_STATUS_MANUAL = gql`
  mutation SetEmailStatusManual($email: String!, $comment: String!, $score: Int!) {
    appAdminEmailStatusManualSet(email: $email, comment: $comment, score: $score)
  }
`

const DELETE_EMAIL_STATUS_MANUAL = gql`
  mutation DeleteEmailStatusManual($email: String!) {
    appAdminEmailStatusManualDelete(email: $email)
  }
`
const EmailStatusManual = ({ emailStatus, styleing }) => {
  const { t } = useTranslation('adminPages')

  const FORM_INPUT = [
    {
      id: 'email',
      nameTid: 'content:email',
      type: 'text',
      disabled: true
    },
    {
      id: 'comment',
      nameTid: 'content:comment',
      type: 'text'
    },
    {
      id: 'score',
      nameTid: 'content:score',
      type: 'single',
      value: Number(' ' + emailStatus.score),
      values: [
        { id: '-1', title: 'Black (-1)', text: null },
        { id: '0', title: 'Red (0)', text: null },
        { id: '25', title: 'Dark orange (25)', text: null },
        { id: '50', title: 'Light orange (50)', text: null },
        { id: '100', title: 'Green (100)', text: null }
      ]
    }
  ]

  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [deleteEnabled, setDeleteEnabled] = useState(emailStatus.comment ? true : false)
  const [formInputs, setFormInputs] = useState(FORM_INPUT)
  const [
    emailStatusFieldsData,
    handleFieldChange,
    saveInitialState,
    setValues,
    changedFields
  ] = useFormFields({
    email: emailStatus.email,
    comment: emailStatus.comment,
    score: (' ' + (' ' + emailStatus.score)).trim()
  })

  const [emailStatusManualUpdate, { loading: mutationLoading }] = useMutation(
    UPDATE_EMAIL_STATUS_MANUAL,
    {
      onCompleted: () => {
        setMessage('Email status updated')
      },
      onError: () => {
        setMessage('Error updating email status')
      }
    }
  )

  const [emailStatusManualDelete, { loading: mutationDeleteLoading }] = useMutation(
    DELETE_EMAIL_STATUS_MANUAL,
    {
      onCompleted: () => {
        setMessage('Email status deleted')
      },
      onError: () => {
        setMessage('Error deleting email status')
      }
    }
  )

  const emailStatusFieldsDataValid = (data) => {
    if (data.comment === '' || data.score === '') {
      setMessage('Comment and score are required')
      return false
    }
    return true
  }

  const saveEmailStatusManual = () => {
    setMessage('')
    setDeleteEnabled(true)

    if (emailStatusFieldsDataValid(emailStatusFieldsData)) {
      emailStatusManualUpdate({
        variables: {
          ...emailStatusFieldsData,
          score: Number(' ' + emailStatusFieldsData.score)
        }
      })
    }
  }

  const deleteEmailStatusManual = () => {
    setMessage('')
    setDeleteEnabled(false)
    handleFieldChange({ target: { id: 'comment', value: '', type: 'text' } })
    handleFieldChange({
      target: { id: 'score', value: Number(' ' + emailStatus.score), type: 'number' }
    })
    emailStatusManualDelete({
      variables: {
        email: emailStatusFieldsData.email
      }
    })
  }

  /*
  if (!emailStatus.comment && emailStatus.score == 100) {
    return <></>
  }
  */

  let colorClass = 'text-black-600'

  return (
    <>
      <PencilAltIcon
        className={classNames(`${styleing} ${colorClass}`)}
        aria-hidden="true"
        onClick={() => setEditDialogOpen(!editDialogOpen)}
      />
      <Dialog
        isOpen={editDialogOpen}
        title={t('adminPages:adminAllGuides.emailStatusManual')}
        /* onClose={true} */
      >
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-3 mt-2">
            <Fields
              inputFields={formInputs}
              fieldsData={emailStatusFieldsData}
              handleFieldChange={handleFieldChange}
            />
          </div>
          <div className="col-span-1">
            <button
              className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 border-indigo-600 text-base font-medium text-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              onClick={() => {
                setEditDialogOpen(false)
              }}
            >
              {t('content:close')}
            </button>
          </div>

          <div className="col-span-1">
            {emailStatusFieldsData.comment && deleteEnabled && (
              <button
                className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                onClick={() => deleteEmailStatusManual()}
              >
                {t('content:delete')}
              </button>
            )}
          </div>
          <div className="col-span-1">
            <button
              className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              onClick={() => saveEmailStatusManual()}
            >
              {t('content:save')}
            </button>
          </div>
          <div className="col-span-3">{message}</div>
        </div>
      </Dialog>
    </>
  )
}

export default EmailStatusManual
