import React from 'react'
import { useApolloClient, useQuery, gql } from '@apollo/client'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Menu } from '@headlessui/react'

import { useAuthContext, useUserContext, useAdminFieldsContext } from '../../contexts'
import * as ga from '../../lib/ga'
import { isAdmin } from '../../utils'

import DocumentationButton from '../shared/documentation/documentationButton'
import DocumentationModal from '../shared/documentation/documentationModal'
import MenuTransition from '../shared/dropdown/menu-transition'
import MenuItem from '../shared/dropdown/menu-item'
import LanguageSwitcher from '../nav/languageSwitcher'
import AdminSearchSelectDialog from '../admin/admin-search-select-dialog'

import { logout } from '../../utils/auth'
import { userNavigation, adminNavigation } from '../../constants'
import {
  MenuAlt2Icon,
  UserCircleIcon,
  KeyIcon,
  SearchIcon
} from '@heroicons/react/outline'

const QUERY_VENDOR = gql`
  query Company($id: ID) {
    appAdminCompany(id: $id) {
      id
      company
    }
  }
`

const QUERY_USER = gql`
  query User($id: ID) {
    appAdminUser(id: $id) {
      id
      email
    }
  }
`

function Nav({ setSidebarOpen }) {
  const client = useApolloClient()
  const { auth } = useAuthContext()
  const navigate = useNavigate()
  const { t } = useTranslation('topNav')
  const { user } = useUserContext()
  const { setAdminSearchVisible } = useAdminFieldsContext()

  const isAuthenticated = auth?.isLoggedIn
  const hasAdmin = isAuthenticated && isAdmin(user?.role)

  const { data: vendorData } = useQuery(QUERY_VENDOR, {
    variables: {
      id: user.companyId
    },
    skip: !user.companyId || !hasAdmin
  })

  const { data: userData } = useQuery(QUERY_USER, {
    variables: {
      id: user.userId
    },
    skip: !user.userId || !hasAdmin
  })

  const vendorName = vendorData?.appAdminCompany?.company
  const userName = userData?.appAdminUser?.email
  const systemName = user?.systemName
  const systemCategory = user?.systemCategory

  const handleLogout = () => {
    logout(() => {
      // console.log('is logged out')
      try {
        client.clearStore()
      } catch (error) {
        console.log(error.message)
      }
    })

    ga.event({
      action: 'log_out'
    })

    navigate('/login')
    // TODO: Show not logged in page
  }

  return (
    <>
      <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <AdminSearchSelectDialog />

        <button
          type="button"
          className="px-4 border-0 border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
        </button>

        <div className="flex-1 px-4 flex justify-between">
          <div className="flex-1 flex"></div>
          <div className="ml-4 flex items-center md:ml-6 gap-2">
            {/* Admin dropdown */}
            {hasAdmin && (
              <>
                {(vendorName || userName) && (
                  <div className="text-sm font-light text-gray-500">
                    {vendorName || userName}
                  </div>
                )}

                {systemName && (
                  <div className="text-sm font-light text-gray-500">
                    {systemName} - {systemCategory}
                  </div>
                )}
              </>
            )}

            {hasAdmin && (
              <button
                className="max-w-xs p-1 bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={() => {
                  setAdminSearchVisible(true)
                }}
              >
                <SearchIcon
                  className="h-6 w-6 rounded-full text-gray-400 hover:text-gray-500"
                  aria-hidden="true"
                />
              </button>
            )}

            {hasAdmin && (
              <Menu as="div" className="relative">
                <div>
                  <Menu.Button className="max-w-xs p-1 bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    <span className="sr-only">Open admin menu</span>
                    <KeyIcon
                      className="h-6 w-6 rounded-full text-gray-400 hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <MenuTransition>
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {adminNavigation.map((item) => {
                      if (
                        item.devOnly &&
                        process.env.REACT_APP_BW_ENV !== 'development'
                      ) {
                        return null
                      }

                      return (
                        <MenuItem key={`Menu.Item-Admin-${item.name}`}>
                          <Link to={item.href}>{t(item.name)}</Link>
                        </MenuItem>
                      )
                    })}
                  </Menu.Items>
                </MenuTransition>
              </Menu>
            )}

            {/* Profile dropdown */}
            <Menu as="div" className="relative">
              <div>
                <Menu.Button className="max-w-xs p-1 bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  <span className="sr-only">Open user menu</span>
                  <UserCircleIcon
                    className="h-6 w-6 rounded-full text-gray-400 hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <MenuTransition>
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {userNavigation.map((item) => {
                    if (item.href === 'signOut') {
                      return (
                        <MenuItem
                          key={`Menu.Item-Profile-${item.name}`}
                          onClick={handleLogout}
                        >
                          <a>{t(item.name)}</a>
                        </MenuItem>
                      )
                    }

                    return (
                      <MenuItem key={item.href}>
                        <Link key={item.href} to={item.href}>
                          {t(item.name)}
                        </Link>
                      </MenuItem>
                    )
                  })}
                </Menu.Items>
              </MenuTransition>
            </Menu>

            <LanguageSwitcher />
          </div>
        </div>
      </div>
      {hasAdmin && ( // Show documention button and modal
        <>
          <DocumentationButton />
          <DocumentationModal />
        </>
      )}
    </>
  )
}

export default Nav
