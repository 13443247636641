import React, { useEffect, useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Radiobuttons } from '../components/form/inputs'
import { useUserContext } from '../contexts'
import useUpdateUserSettings from '../hooks/useUpdateUserSettings'
import useFetchUser from '../hooks/useFetchUser'
import { isAdmin } from '../utils'
import * as ga from '../lib/ga'
import { Link } from 'react-router-dom'
import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import useOverlayNotification from '../hooks/useOverlayNotification'
import Checkbox from '../components/shared/checkbox'
import { FIELD_GUIDE_NOTIFICATIONS_USER_SETTINGS } from '../constants/guide-notification-fields'
import Tabs from '../components/shared/tabs'
import { validatePassword, validationRules } from '../validators/password.validator'
import PasswordStrengthChecker from '../components/shared/password-strength-checker'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import PasswordRulesInformation from '../components/form/password-rules-information'

const UPDATE_PASSWORD = gql`
  mutation UpdatePassword(
    $oldPassword: String!
    $newPassword: String!
    $repeatPassword: String!
  ) {
    appUpdatePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      repeatPassword: $repeatPassword
    )
  }
`
const GQL_USER = gql`
  query FetchUser {
    user {
      role
      appRole
      noNotificationsInquiry
      notificationReviewApproved
      guideNotifications
      buyingIntentActivityNotifications
      vendor {
        accessRights
      }
    }
  }
`

function SettingsPage() {
  const { user: userContext } = useUserContext()
  const { t } = useTranslation('userSettings')
  const { refetchUser } = useFetchUser()
  const { showSuccessNotification } = useOverlayNotification()
  const { updateUserSettings } = useUpdateUserSettings()
  const [showPassword, setShowPassword] = useState(false)
  const [errorPass, setErrorPass] = useState(null)
  const [user, setUser] = useState(null)
  const [activeTab, setActiveTab] = useState('INFORMATION')
  const [passwordWarning, setPasswordWarning] = useState('')
  const [password, setPassword] = useState('')
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [validatorResults, setValidatorResults] = useState({})

  const tabOptions = [
    { label: t('tabs.information'), value: 'INFORMATION' },
    { label: t('tabs.password'), value: 'PASSWORD' },
    { label: t('tabs.notifications'), value: 'NOTIFICATIONS' }
  ]
  let formOldPassword
  let formNewPassword
  let formRepeatPassword

  const userCompany = userContext?.company
  const userIsAdmin = isAdmin(userContext?.role)
  const userName = userContext?.name
  const userEmail = userContext?.email
  const userAcceptedDate = userContext?.acceptedDate

  useEffect(() => {
    refetchUser()
  }, [refetchUser])

  const { data, loading } = useQuery(GQL_USER, {
    onCompleted: ({ user }) => {
      setUser(user)
    },
    onError: (error) => {
      console.log('Error while refetching user', error)
    }
  })

  const [appUpdatePassword] = useMutation(UPDATE_PASSWORD, {
    onCompleted: () => {
      showSuccessNotification()
    },
    onError(err) {
      const error = `${err}`.split(':').reverse()[0]
      setErrorPass(error)
    }
  })

  const checkPassword = () => {
    let hasDisabledSubmit = true
    const password = formNewPassword.value
    const repeatPassword = formRepeatPassword.value
    setPassword(password)

    const passwordValidator = validatePassword(password, repeatPassword, t)

    if (!passwordValidator.isValid) {
      if (!passwordValidator.results.identicalMatch) {
        setPasswordWarning(passwordValidator.errors[0])
      } else {
        setPasswordWarning(null)
      }
      hasDisabledSubmit = true
    } else {
      setPasswordWarning(null)
      hasDisabledSubmit = false
    }
    if (password.length !== repeatPassword.length) {
      hasDisabledSubmit = true
    }
    setDisabledSubmit(hasDisabledSubmit)
    setValidatorResults(passwordValidator.results)
  }

  const handleChangePassword = (e) => {
    e.preventDefault()

    // TODO: Add check length of new and old password
    // TODO: Add check for strength of NewPassword

    const variables = {
      oldPassword: formOldPassword.value,
      newPassword: formNewPassword.value,
      repeatPassword: formRepeatPassword.value
    }

    appUpdatePassword({
      variables
    })
    formOldPassword.value = ''
    formNewPassword.value = ''
    formRepeatPassword.value = ''

    setErrorPass(null)
    setPasswordWarning('')
    setShowPassword(false)
    ga.event({
      action: 'update_password'
    })
  }

  const handleChangeSettings = (fieldName, value) => {
    updateUserSettings({
      [fieldName]: value
    })

    setUser({ ...user, [fieldName]: value })
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab.value)
  }
  if (loading) return <div>Loading...</div>
  return (
    <>
      <PageHeader pageName={t('header.title')} />

      <PageBody>
        <Tabs tabs={tabOptions} activeTab={activeTab} onChange={handleTabChange} />

        {activeTab === 'INFORMATION' && (
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {t('cardMyInformation.title')}
              </h3>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('content:company')}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {userCompany}
                    {userIsAdmin && ' (Admin)'}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('content:name')}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {userName}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('content:email')}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {userEmail}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('cardMyInformation.conditionsAcceptedOn')}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {userAcceptedDate && new Date(userAcceptedDate).toLocaleString('sv')}
                    <br />
                    <Link to="/legal">{t('cardMyInformation.readConditions')}</Link>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        )}

        {activeTab === 'NOTIFICATIONS' && (
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {t('notifications.title')}
                </h3>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="shadow overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div className="grid grid-cols-6 gap-2">
                      <div className="col-span-6">
                        <Radiobuttons
                          field={FIELD_GUIDE_NOTIFICATIONS_USER_SETTINGS}
                          nameTid={'userSettings:notifications.notifyAboutSystemGuide'}
                          data={user?.guideNotifications}
                          handleFieldChange={(e) => {
                            handleChangeSettings('guideNotifications', e.target.value)
                          }}
                          value={user?.guideNotifications}
                        />
                      </div>

                      <div className="col-span-6">
                        <Radiobuttons
                          handleFieldChange={(e) => {
                            handleChangeSettings(
                              'noNotificationsInquiry',
                              e.target.value == 'true'
                            )
                          }}
                          data={!user?.noNotificationsInquiry ? 'false' : 'true'}
                          field={{
                            id: 'noNotificationsInquiry',
                            textTid: 'userSettings:notifications.notifyAbouInquiry',
                            values: [
                              {
                                id: 'false',
                                titleTid: 'content:yes'
                              },
                              {
                                id: 'true',
                                titleTid: 'content:no'
                              }
                            ]
                          }}
                        />
                      </div>

                      <div className="col-span-6">
                        <Radiobuttons
                          handleFieldChange={(e) => {
                            handleChangeSettings(
                              'buyingIntentActivityNotifications',
                              e.target.value === 'true'
                            )
                          }}
                          data={user?.buyingIntentActivityNotifications}
                          field={{
                            id: 'buyingIntentActivityNotifications',
                            textTid: 'userSettings:buyingIntentActivityNotifications',
                            values: [
                              {
                                id: 'true',
                                titleTid: 'content:yes'
                              },
                              {
                                id: 'false',
                                titleTid: 'content:no'
                              }
                            ]
                          }}
                        />
                      </div>

                      <div className="col-span-6">
                        {(user?.appRole === 'EDITOR' ||
                          user?.appRole === 'ADMIN' ||
                          isAdmin(user?.role)) && (
                          <Radiobuttons
                            handleFieldChange={(e) => {
                              handleChangeSettings(
                                'notificationReviewApproved',
                                e.target.value === 'true'
                              )
                            }}
                            data={user.notificationReviewApproved}
                            field={{
                              id: 'notificationReviewApproved',
                              textTid: 'userSettings:notificationReviewApproved',
                              values: [
                                {
                                  id: 'true',
                                  titleTid: 'content:yes'
                                },
                                {
                                  id: 'false',
                                  titleTid: 'content:no'
                                }
                              ]
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'PASSWORD' && (
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="m-5 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    <a>{t('changePassword.title')}</a>
                  </h3>
                  <p className="text-sm text-gray-600">{t('changePassword.subtitle')}</p>
                </div>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <form onSubmit={handleChangePassword}>
                  <div className="shadow overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 lg:col-span-3">
                          <label
                            htmlFor="formOldPassword"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t('cardChangePassword.currentPassword')}
                          </label>
                          <input
                            type="password"
                            name="formOldPassword"
                            id="formOldPassword"
                            ref={(node) => {
                              formOldPassword = node
                            }}
                            className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 lg:col-span-3">
                          <label
                            htmlFor="formNewPassword"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t('cardChangePassword.newPassword')}
                          </label>
                          {/* create tailwind grid with two columns */}
                          <div className="grid grid-rows-2 grid-cols-5">
                            <div className="col-span-5">
                              <input
                                type={showPassword ? 'text' : 'password'}
                                name="formNewPassword"
                                id="formNewPassword"
                                ref={(node) => {
                                  formNewPassword = node
                                }}
                                onChange={checkPassword}
                                className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full pr-5 shadow-sm sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>
                            <div className="col-span-5">
                              <Checkbox
                                id="formShowPassword"
                                onChange={() => {
                                  setShowPassword(!showPassword)
                                }}
                                text={t('cardChangePassword.showPassword')}
                                checked={showPassword}
                                rawChangeEvent={false}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-6 lg:col-span-3">
                          <label
                            htmlFor="formRepeatPassword"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t('cardChangePassword.repeatPassword')}
                          </label>
                          {/* create tailwind grid with two columns */}
                          <div className="grid grid-rows-2">
                            <div className="col-span-4">
                              <input
                                type={'password'}
                                name="formRepeatPassword"
                                id="formRepeatPassword"
                                ref={(node) => {
                                  formRepeatPassword = node
                                }}
                                onChange={checkPassword}
                                className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>
                            {passwordWarning && (
                              <div className="row-span-1 sm:row-span-1 text-sm text-red-500 flex gap-1 items-center font-semibold">
                                <ExclamationCircleIcon className={'w-[18px]'} />{' '}
                                {passwordWarning}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <PasswordStrengthChecker password={password} />
                      <PasswordRulesInformation validatorResults={validatorResults} />
                      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button
                          disabled={disabledSubmit}
                          type="submit"
                          className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
                            disabledSubmit ? 'bg-gray-400' : 'bg-blue-600'
                          }  hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                        >
                          {t('cardChangePassword.btnSaveSettings')}
                        </button>
                      </div>
                    </div>
                  </div>
                  {errorPass && (
                    <div style={{ textAlign: 'center', color: 'red', padding: '10px' }}>
                      {errorPass}
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        )}
      </PageBody>
    </>
  )
}

export default SettingsPage
