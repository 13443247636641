import React from 'react'
import { PageBody } from '../components/page-body'
import { PageHeader } from '../components/page-header'
import { BadgeProvider } from '../components/badges/context/BadgeContext'
import BadgeAdminList from '../components/badges/partials/admin-all-badges/BadgeAdminList'
import { useTranslation } from 'react-i18next'
const AdminAllBadgesPage = () => {
  const { t } = useTranslation('adminPages')
  return (
    <>
      <PageHeader pageName={t('allBadges.heading')} />
      <PageBody>
        <BadgeProvider>
          <BadgeAdminList />
        </BadgeProvider>
      </PageBody>
    </>
  )
}

export default AdminAllBadgesPage
