import React, { useRef, useState } from 'react'
import { getSystemName } from '../../../helpers/systems.helper'
import useGetCategoryName from '../../../hooks/useGetCategoryName'
import Button from '../../shared/button'
import { CodeIcon, DownloadIcon, EyeIcon, XIcon } from '@heroicons/react/solid'
import Flag from '../../shared/flag'
import BadgeTemplate from './badge-generator/BadgeTemplate'
import Dialog from '../../shared/dialog'
import { isAdmin, isProd } from '../../../utils'
import { useTranslation } from 'react-i18next'
import { useUserContext } from '../../../contexts'

const BadgeVendorListItem = ({ badge, logAction }) => {
  const { t } = useTranslation('badges')
  const { user } = useUserContext()
  const badgeToSystem = badge?.badgeToSystem
  const { getCategoryName } = useGetCategoryName()
  const [openDialogs, setOpenDialogs] = useState({
    preview: {},
    embed: {}
  })
  const [tocConfirmedDownload, setTocConfirmedDownload] = useState({})
  const [tocConfirmedEmbed, setTocConfirmedEmbed] = useState({})
  const badgeRef = useRef(null)

  const handleDialogToggle = (dialogType, id, isOpen) => {
    setOpenDialogs((prev) => ({
      ...prev,
      [dialogType]: {
        ...prev[dialogType],
        [id]: isOpen
      }
    }))
  }

  const handleDownload = async (type) => {
    if (badgeRef.current && type === 'SVG') {
      badgeRef.current.downloadSVG()
    }
    if (badgeRef.current && type === 'PNG') {
      try {
        await badgeRef.current.downloadPNG()
      } catch (error) {
        console.error('Download failed:', error)
      }
    }
    if (logAction) {
      logAction({
        data: {
          badgeId: badge?.id,
          type,
          category: badge?.category
        },
        action: 'BADGE_DOWNLOAD'
      })
    }
  }

  return (
    <>
      {badgeToSystem?.map((badgeToSystem, index) => {
        const localeAttributes = badge.attributes[badgeToSystem.locale || 'en_GB']
        const systemName = getSystemName(badgeToSystem?.systemV2)
        const itemId = badgeToSystem.id

        return (
          <div key={index} className={'p-3 shadow-md rounded-xl bg-white relative'}>
            <div className={'flex items-center gap-3 justify-between'}>
              <div className={'flex items-center gap-3'}>
                <p className={'text-sm font-semibold'}>{systemName}</p>
                <Flag locale={badgeToSystem?.locale} height={'30px'} />
              </div>
              <div>
                <BadgeTemplate
                  height={108}
                  width={88}
                  rating={badge?.rating}
                  className={
                    '!p-2 shadow-none !m-0 absolute bottom-20 right-[-20px] !bg-transparent'
                  }
                  year={badge?.year}
                  category={badge?.category}
                  headline={localeAttributes?.headlineAttribute.title}
                  subHeadline={localeAttributes?.subHeadlineAttribute.title}
                  attributes={localeAttributes}
                  isDraggable={false}
                  categoryLocale={badgeToSystem?.locale || 'en_GB'}
                  badgeType={badge?.type}
                />
              </div>
            </div>
            <hr className={'my-2'} />
            <div className={'[&_p]:text-sm flex flex-col gap-2'}>
              <p>{badge?.headline}</p>
              <p>
                {getCategoryName(
                  badge?.category,
                  false,
                  badgeToSystem?.locale || 'en_GB'
                )}
              </p>
              <p className={'text-gray-500 text-xs font-semibold my-1'}>
                {t('embedModal.awardedAt')}{' '}
                {new Date(badgeToSystem?.createdAt).toLocaleDateString()}
              </p>
            </div>
            <hr className={'my-2'} />
            <div className={'flex justify-end gap-3'}>
              <Button
                size={'small'}
                mode={'outlined'}
                className={'!border-1 !border-indigo-600'}
                onClick={() => handleDialogToggle('preview', itemId, true)}
              >
                <EyeIcon className={'text-indigo-600'} width={20} height={20} />
              </Button>
              {isAdmin(user?.role) && (
                <Button
                  onClick={() => handleDialogToggle('embed', itemId, true)}
                  size={'small'}
                  className={'flex gap-1 items-center'}
                >
                  <span>{t('embedModal.button.embed')}</span>{' '}
                  <CodeIcon width={20} height={20} />
                </Button>
              )}
            </div>

            {/* Embed Dialog - Only render if admin */}
            {isAdmin(user?.role) && (
              <Dialog
                className={'!max-w-xl'}
                isOpen={!!openDialogs.embed[itemId]}
                title={
                  <div className={'flex items-center gap-3 justify-between'}>
                    <p>{t('embedModal.title')}</p>
                    <XIcon
                      className={'cursor-pointer'}
                      onClick={() => handleDialogToggle('embed', itemId, false)}
                      width={20}
                      height={20}
                    />
                  </div>
                }
                renderFooter={() => null}
                noBackgroundClose
                onClose={() => handleDialogToggle('embed', itemId, false)}
              >
                {!tocConfirmedEmbed[itemId] ? (
                  <div className="flex items-center gap-2">
                    <p className={'text-sm'}>{t('embedModal.termAndConditions')}</p>
                    <Button
                      onClick={() => {
                        setTocConfirmedEmbed((prev) => ({
                          ...prev,
                          [itemId]: true
                        }))
                        if (logAction) {
                          logAction({
                            data: {
                              badgeId: badge?.id,
                              type: badge?.type,
                              category: badge?.category
                            },
                            action: 'BADGE_EMBED'
                          })
                        }
                      }}
                    >
                      OK
                    </Button>
                  </div>
                ) : (
                  <div>
                    <p className={'mb-2 text-sm'}>{t('embedModal.description')}</p>
                    <div className={'text-xs bg-gray-200 p-2 rounded-md font-semibold'}>
                      <code>
                        {`<a class="businesswith-badge businesswith-badge-${
                          badgeToSystem.id
                        }" href="https://${
                          badgeToSystem.categoryLocaleUrl
                        }" rel="noopener" title="${getCategoryName(
                          badge?.category,
                          false,
                          badgeToSystem?.locale || 'en_GB'
                        )} on BusinessWith" aria-label="Visit ${getCategoryName(
                          badge?.category,
                          false,
                          badgeToSystem?.locale || 'en_GB'
                        )} on BusinessWith"></a>
                          <script src="${
                            isProd
                              ? process.env.REACT_APP_SCRIPT_URL
                              : `${process.env.PUBLIC_URL}/embed/badge.dev.js`
                          }" data-embed-id="${badgeToSystem.id}" async></script>`}
                      </code>
                    </div>
                  </div>
                )}
              </Dialog>
            )}

            <Dialog
              isOpen={!!openDialogs.preview[itemId]}
              title={
                <div className={'flex items-center gap-3 justify-between'}>
                  <p>{t('previewModal.title')}</p>
                  <XIcon
                    className={'cursor-pointer'}
                    onClick={() => handleDialogToggle('preview', itemId, false)}
                    width={20}
                    height={20}
                  />
                </div>
              }
              noBackgroundClose
              renderFooter={() => (
                <div>
                  <hr className={'my-4'} />
                  {!tocConfirmedDownload[itemId] ? (
                    <div className="flex items-center gap-2">
                      <p className={'text-sm'}>{t('previewModal.termsAndConditions')}</p>
                      <Button
                        onClick={() =>
                          setTocConfirmedDownload((prev) => ({
                            ...prev,
                            [itemId]: true
                          }))
                        }
                      >
                        {t('previewModal.button.ok')}
                      </Button>
                    </div>
                  ) : (
                    <div className="flex justify-end gap-2">
                      <Button
                        disabled={!tocConfirmedDownload[itemId]}
                        onClick={() => handleDownload('PNG')}
                        mode={'default'}
                      >
                        <DownloadIcon width={20} height={20} />
                        <span>PNG</span>
                      </Button>
                      <Button
                        disabled={!tocConfirmedDownload[itemId]}
                        onClick={() => handleDownload('SVG')}
                        mode={'default'}
                      >
                        <DownloadIcon width={20} height={20} />
                        <span>SVG</span>
                      </Button>
                    </div>
                  )}
                </div>
              )}
              onClose={() => handleDialogToggle('preview', itemId, false)}
            >
              <BadgeTemplate
                badgeRef={badgeRef}
                height={228}
                width={208}
                rating={badge?.rating}
                category={badge?.category}
                headline={badge?.headline}
                subHeadline={badge?.subHeadline}
                year={badge.year}
                attributes={localeAttributes}
                isDraggable={false}
                badgeType={badge?.type}
                categoryLocale={badgeToSystem?.locale || 'en_GB'}
              />
            </Dialog>
          </div>
        )
      })}
    </>
  )
}

export default BadgeVendorListItem
