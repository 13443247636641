import { gql, useMutation } from '@apollo/client'

const CONNECT_SYSTEM_TO_BADGE = gql`
  mutation ConnectBadgeToSystem($badgeId: ID!, $systemId: ID!, $locale: String) {
    appAdminConnectBadgeToSystem(badgeId: $badgeId, systemId: $systemId, locale: $locale)
  }
`
/**
 * Connect system with locale to badge
 * @returns {{connectBadgeToSystem: (function({badgeId: *, systemId: *, locale: *}): Promise<function(*, *, *): Promise<void>>), data: any, loading: boolean, error: ApolloError}}
 */
export function useConnectBadgeToSystem() {
  const [appAdminConnectBadgeToSystem, { data, loading, error }] = useMutation(
    CONNECT_SYSTEM_TO_BADGE
  )

  const connectBadgeToSystem = async ({ badgeId, systemId, locale }) => {
    const response = await appAdminConnectBadgeToSystem({
      variables: {
        badgeId,
        systemId,
        locale
      }
    })
    return response?.data?.appAdminConnectBadgeToSystem
  }
  return {
    connectBadgeToSystem,
    data,
    loading,
    error
  }
}
