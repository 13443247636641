import { gql, useMutation } from '@apollo/client'

const CREATE_BADGE = gql`
  mutation CreateBadge(
    $type: BadgeType!
    $expiresAt: DateTime
    $category: String
    $attributes: Json
    $year: String
    $rating: Float
  ) {
    appAdminCreateBadge(
      attributes: $attributes
      category: $category
      type: $type
      expiresAt: $expiresAt
      year: $year
      rating: $rating
    )
  }
`

/**
 * used for creation of badges on admin
 * @returns {{createBadge: (function({systemSiteDataId: *, type: *, headline: *, subHeadline: *, expiresAt: *}): Promise<(function(*, *, *): Promise<boolean>)|*>), data: any, loading: boolean, error: ApolloError}}
 */
export function useCreateBadge() {
  const [mutate, { data, loading, error }] = useMutation(CREATE_BADGE)
  const createBadge = async ({ type, year, expiresAt, category, attributes, rating }) => {
    const response = await mutate({
      variables: {
        type,
        year,
        attributes,
        expiresAt,
        category,
        rating
      }
    })
    return response.data?.appAdminCreateBadge
  }

  return { createBadge, data, loading, error }
}
