import React from 'react'
import { useFetchVendorBadges } from '../hooks/useFetchVendorBadges'
import BadgeVendorListItem from './BadgeVendorListItem'
import Loader from '../../loader'
import { useCreateCustomLog } from '../../../hooks/useCreateCustomLog'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next'

const BadgeVendorList = () => {
  const { t } = useTranslation('badges')
  const { data: badges, loading, error } = useFetchVendorBadges()
  const { createCustomLog } = useCreateCustomLog()

  if (loading) {
    return (
      <div className={'flex justify-center'}>
        <Loader />
      </div>
    )
  }

  if (error) {
    return (
      <div className={'flex justify-center text-red-500 p-3 bg-red-100 rounded-xl'}>
        {t('errorFetchingBadges')}
      </div>
    )
  }

  if (badges.length <= 0) {
    return (
      <div
        className={
          'flex items-center gap-2 text-blue-500 p-3 bg-blue-100 rounded-xl border border-blue-300'
        }
      >
        <ExclamationCircleIcon width={25} />
        {t('badgesNotAvailable')}
      </div>
    )
  }

  return (
    <div className={'grid grid-cols-3 gap-6 max-md:grid-cols-1 max-lg:grid-cols-2'}>
      {badges?.map((badge) => {
        return (
          <BadgeVendorListItem
            logAction={createCustomLog}
            key={badge?.id}
            badge={badge}
          />
        )
      })}
    </div>
  )
}

export default BadgeVendorList
