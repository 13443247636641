import React, { useState } from 'react'
import PropTypes from 'prop-types'

/**
 * ColorPickerInput Component
 *
 * @param label
 * @param {string} defaultColor - The initial color value in HEX format (e.g., "#ffffff").
 * @param {function} onChange - Callback function that receives the updated color value.
 * @param className
 */
const ColorPickerInput = ({ label, defaultColor = '#ffffff', onChange, className }) => {
  const [color, setColor] = useState(defaultColor)

  const handleChange = (e) => {
    const selectedColor = e.target.value
    setColor(selectedColor)
    if (onChange) {
      onChange(selectedColor)
    }
  }

  return (
    <div className={'flex items-center'}>
      <div
        className={`h-8 w-8 overflow-hidden rounded-full flex-none border border-gray-300 relative ${className}`}
      >
        <input
          type="color"
          className="absolute h-20 w-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 overflow-hidden border-0 m-0 p-0 cursor-pointer"
          aria-label="Color picker"
          value={color}
          onChange={handleChange}
          maxLength={7}
          pattern="#[0-9A-Fa-f]{6}"
        />
      </div>
      {label && <label className={'text-gray-600 text-sm'}>{label}</label>}
    </div>
  )
}

ColorPickerInput.propTypes = {
  label: PropTypes.string,
  defaultColor: PropTypes.string,
  onChange: PropTypes.func
}

export default ColorPickerInput
