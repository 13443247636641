import React, { useState } from 'react'
import { gql } from '@apollo/client'
import useAdminQuery from '../hooks/useAdminQuery'
import useFetchSystems from '../hooks/useFetchSystems'
import useGetSystemName from '../hooks/useGetSystemName'
import { useUserContext } from '../contexts'
import { isAdmin } from '../utils'
import { useTranslation } from 'react-i18next'

import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'

import LeadsForm from '../components/leadsForm'
import LeadsFormsFilter from '../components/leads-forms/filter'
import styles from '../styles/page.module.css'
import AdminWebhookTriggerStatuses from '../components/admin/admin-webhook-trigger-status'
import AdminField from '../components/shared/admin-field'
import WebhookFailedEventAlert from '../components/webhooks/webhook-failed-event-alert'

const QUERY = gql`
  query FormLeads($companyId: ID, $userId: ID) {
    appFormLeads(companyId: $companyId, userId: $userId) {
      id
      company
      companySize
      created
      email
      inquiryType
      isNew
      licenses
      name
      phone
      systemId
      category
      textField
      locale
    }
  }
`

const GET_SYSTEMS_NAV = gql`
  query SystemsData($companyId: ID, $userId: ID, $systemId: ID) {
    appSystemsOnVendor(companyId: $companyId, userId: $userId, systemId: $systemId) {
      id
      active
      systemSiteData {
        systemName
        locale
      }
    }
  }
`

function LeadsFormsPage() {
  const { user } = useUserContext()
  const { t } = useTranslation(['leadsForms', 'content'])
  const hasAdmin = isAdmin(user?.role)
  const [allFormLeads, setAllFormLeads] = useState([])
  const [filteredLeads, setFilteredLeads] = useState(null)
  const { availableSystems } = useFetchSystems(GET_SYSTEMS_NAV, 'cache-first')
  const { getSystemName } = useGetSystemName()
  const { error, loading } = useAdminQuery(QUERY, {
    onCompleted: (data) => {
      setAllFormLeads(data?.appFormLeads || [])
    }
  })

  const systemsArray = availableSystems?.map((system) => ({
    id: system.id,
    name: getSystemName(system)
  }))
  return (
    <>
      <PageHeader pageName={t('header.title')}>
        <WebhookFailedEventAlert eventType={'EVENT_NEW_INQUIRY_LEAD'} t={t} />
        <p>{t('header.subtitle')}</p>
      </PageHeader>
      <PageBody loading={loading}>
        <AdminField>
          <AdminWebhookTriggerStatuses inquiryLead />
        </AdminField>
        {error && (
          <div style={{ textAlign: 'center' }} className={styles.errorMsg}>
            {t('content:error.somethingWentWrongTryAgainLater')}
            <br />
            <i>
              <small>({error.message})</small>
            </i>
          </div>
        )}
        {!!allFormLeads?.length && (
          <LeadsFormsFilter
            allFormLeads={allFormLeads}
            systemsArray={systemsArray}
            onLeadsFiltered={setFilteredLeads}
          />
        )}

        {!loading && (
          <LeadsForm
            leads={filteredLeads}
            systemsArray={systemsArray}
            isAdmin={hasAdmin}
          />
        )}
      </PageBody>
    </>
  )
}

export default LeadsFormsPage
