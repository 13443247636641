import React from 'react'
import StarRating from '../svg/StarRating'

const BadgeTypeHandler = ({ badgeType, rating = 5 }) => {
  const handler = () => {
    switch (badgeType) {
      case 'REVIEW':
        return <StarRating rating={rating} />
      case 'CATEGORY':
        return ''
      default:
        break
    }
  }
  return <>{handler()}</>
}

export default BadgeTypeHandler
