import { gql } from '@apollo/client'
import useAdminQuery from '../../../hooks/useAdminQuery'

const GET_BADGES_BY_COMPANY = gql`
  query GetBadgesByCompany($companyId: ID) {
    appBadges(companyId: $companyId) {
      id
      type
      rating
      category
      expiresAt
      attributes
      year
      badgeToSystem {
        id
        locale
        createdAt
        categoryLocaleUrl
        systemV2 {
          id
          systemSiteData {
            systemName
          }
        }
      }
    }
  }
`

/**
 * Custom hook that fetches badges for a given vendor (company)
 * and optionally a user (if needed).
 *
 * @returns {{ data, loading, error, refetch }}
 */
export function useFetchVendorBadges() {
  const { data, loading, error, refetch } = useAdminQuery(GET_BADGES_BY_COMPANY)
  return {
    data: data?.appBadges ?? [],
    loading,
    error,
    refetch
  }
}
