import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { CSVLink } from 'react-csv'
import styled from 'styled-components'
import { DocumentDownloadIcon } from '@heroicons/react/outline'
import Button from '../shared/button'
import { useTranslation } from 'react-i18next'
import * as ga from '../../lib/ga'

const StyledCSVLink = styled(CSVLink)`
  all: unset;
  pointer-events: ${({ $loading }) => (!$loading ? 'auto' : 'none')};
`

const CSV_HEADERS = [
  { label: 'Company', key: 'name' },
  { label: 'System', key: 'system' },
  { label: 'Category', key: 'category' },
  { label: 'Company Size', key: 'companySize' },
  { label: 'Revenue from', key: 'revenueFrom' },
  { label: 'Revenue to', key: 'revenueTo' },
  { label: 'Website', key: 'site' },
  { label: 'Activity', key: 'activity' },
  { label: 'Stage', key: 'stage' },
  { label: 'Stage Percentage', key: 'stagePercentage' },
  { label: 'Customer Profile Match (%)', key: 'customerProfileMatch' },
  { label: 'Country / Countries', key: 'country' }
]

const BuyingIntentCsvExport = ({ fetchAllForExport, getSystemName, activeFilters }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [csvData, setCsvData] = useState({ headers: CSV_HEADERS, data: [] })
  const csvLinkRef = useRef(null)

  const formatDataForCsv = (buyingIntents) => {
    return buyingIntents.map((item) => {
      const { revenue, buyingIntentActivityToSystem } = item
      return {
        name: item.name || '',
        system: getSystemName(buyingIntentActivityToSystem) || '',
        category: item.category || '',
        site: item.site || '',
        activity:
          item.activity <= 25
            ? 'LOW'
            : item.activity > 25 && item.activity <= 75
            ? 'MEDIUM'
            : item.activity > 75
            ? 'HIGH'
            : 'No Data',
        stage: item.stage || '',
        stagePercentage: item.stagePercentage || '',
        companySize: item.companySize || '',
        revenueFrom: revenue?.min || '',
        revenueTo: revenue?.max || '',
        customerProfileMatch: buyingIntentActivityToSystem?.customerProfileMatch || '',
        country: item.locales?.join(', ') || ''
      }
    })
  }

  const generateFilename = () => {
    let filename = `buying-intents-csv`

    if (activeFilters && activeFilters.length > 0) {
      const otherFilters = activeFilters.filter((f) => f.filter !== 'days')

      if (otherFilters.length > 0) {
        const filterTypes = [...new Set(otherFilters.map((f) => f.filter))]
        filename += `-filtered-by-${filterTypes.join('-')}`
      }
    }

    return `${filename}.csv`
  }

  const triggerDownload = (formattedData) => {
    setCsvData({
      headers: CSV_HEADERS,
      data: formattedData
    })

    ga.event({
      action: 'export_buying_intents_csv'
    })

    setTimeout(() => {
      if (csvLinkRef.current && formattedData.length > 0) {
        csvLinkRef.current.link.click()
      }
      setIsLoading(false)
    }, 300)
  }

  const handleExportClick = async () => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const currentActiveFilters = activeFilters
        ? JSON.parse(JSON.stringify(activeFilters))
        : []

      const allBuyingIntents = await fetchAllForExport(currentActiveFilters)

      if (!allBuyingIntents || allBuyingIntents.length === 0) {
        setIsLoading(false)
        return
      }

      console.log(`Exporting ${allBuyingIntents.length} buying intents records`)

      const formattedData = formatDataForCsv(allBuyingIntents)
      triggerDownload(formattedData)
    } catch (error) {
      console.error('Error preparing export data:', error)
      setIsLoading(false)
    }
  }

  return (
    <>
      <StyledCSVLink
        ref={csvLinkRef}
        filename={generateFilename()}
        headers={csvData.headers}
        data={csvData.data}
        separator=";"
        $loading={isLoading}
        style={{ display: 'none' }}
      />

      <Button
        size="small"
        mode="default"
        onClick={handleExportClick}
        loading={isLoading}
        disabled={isLoading}
      >
        <DocumentDownloadIcon className="h-5 w-5 text-base text-gray-100 cursor-pointer mr-1" />
        {isLoading ? t('content:exporting') : t('content:export')}
      </Button>
    </>
  )
}

BuyingIntentCsvExport.propTypes = {
  fetchAllForExport: PropTypes.func.isRequired,
  getSystemName: PropTypes.func.isRequired,
  activeFilters: PropTypes.array
}

export default BuyingIntentCsvExport
