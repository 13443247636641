import { gql } from '@apollo/client'
import useAdminQuery from '../../../hooks/useAdminQuery'

const GET_APP_BADGES = gql`
  query GetAppBadges(
    $companyId: ID
    $userId: ID
    $limit: Int
    $page: Int
    $activeFilters: Json
  ) {
    appAdminBadges(
      companyId: $companyId
      userId: $userId
      limit: $limit
      page: $page
      activeFilters: $activeFilters
    ) {
      badgeList {
        id
        type
        rating
        expiresAt
        createdAt
        attributes
        category
        year
        approvalStatusByLocale
        badgeToSystem {
          id
          locale
          systemV2 {
            id
            systemSiteData {
              id
              locale
              systemName
            }
          }
        }
      }
      totalCounts
    }
  }
`

/**
 * Custom hook that fetches badges for admin section
 * @returns {{ data, loading, error, refetch }}
 */
export function useFetchAdminBadges(limit = 6, page = 1, activeFilters = {}) {
  const { data, loading, error, refetch } = useAdminQuery(GET_APP_BADGES, {
    variables: {
      limit,
      page,
      activeFilters
    }
  })
  return {
    data: data?.appAdminBadges?.badgeList || [],
    totalCount: data?.appAdminBadges?.totalCounts || 0,
    loading,
    error,
    refetch
  }
}
