export const TRANSFORMED_REVIEW_FRAGMENT = `
    id
    category
    email
    approved
    approvedDate
    createdAt
    ourComment
    emailApproved
    originalLocale
    availableLocales
    title
    commentText
    commentAuthor
    systemV2Id
    verification
    questions {
        questionId
        questionText
        questionType
        answerId
        answerInstanceId
        answerText
    }
`
