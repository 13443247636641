export const convertToPNG = async (svgElement) => {
  return new Promise((resolve, reject) => {
    const svgString = new XMLSerializer().serializeToString(svgElement)
    const svgUrl = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgString)}`

    const img = new Image()
    img.onload = () => {
      try {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d', { alpha: true })

        // Set dimensions
        const scale = 2
        canvas.width = img.naturalWidth * scale
        canvas.height = img.naturalHeight * scale
        ctx.scale(scale, scale)

        // Clear canvas to transparent
        ctx.clearRect(0, 0, canvas.width, canvas.height)

        // Draw SVG image
        ctx.drawImage(img, 0, 0)
        resolve(canvas)
      } catch (error) {
        reject(error)
      }
    }
    img.onerror = reject
    img.src = svgUrl
  })
}
