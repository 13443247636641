import React, { useState, useRef, useCallback, useEffect } from 'react'

export default function DraggableGroup({
  defaultX = 0,
  defaultY = 0,
  groupKey,
  onDrag,
  children,
  disableDrag = false
}) {
  const [pos, setPos] = useState({ x: defaultX, y: defaultY })
  const [isDragging, setIsDragging] = useState(false)

  const groupRef = useRef(null)

  const [bbox, setBbox] = useState({ x: 0, y: 0, width: 0, height: 0 })

  const offsetRef = useRef({ x: 0, y: 0 })
  const svgRef = useRef(null)

  const clientPointToSvgCoords = useCallback((clientX, clientY, svg) => {
    const pt = svg.createSVGPoint()
    pt.x = clientX
    pt.y = clientY
    const ctm = svg.getScreenCTM()
    if (!ctm) return { x: clientX, y: clientY }
    const svgPoint = pt.matrixTransform(ctm.inverse())
    return { x: svgPoint.x, y: svgPoint.y }
  }, [])

  useEffect(() => {
    if (groupRef.current) {
      const bb = groupRef.current.getBBox()
      setBbox(bb)
    }
  }, [children])

  useEffect(() => {
    setPos({ x: defaultX, y: defaultY })
  }, [defaultX, defaultY])

  const handleMouseDown = (e) => {
    if (disableDrag) return

    e.preventDefault()
    e.stopPropagation()

    const svgEl = e.currentTarget.ownerSVGElement
    svgRef.current = svgEl
    const { x, y } = clientPointToSvgCoords(e.clientX, e.clientY, svgEl)

    offsetRef.current = {
      x: x - pos.x,
      y: y - pos.y
    }
    setIsDragging(true)

    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUp)
  }

  const handleMouseMove = useCallback(
    (e) => {
      if (!isDragging || !svgRef.current) return

      e.preventDefault()
      e.stopPropagation()

      const { x, y } = clientPointToSvgCoords(e.clientX, e.clientY, svgRef.current)
      const newPos = {
        x: x - offsetRef.current.x,
        y: y - offsetRef.current.y
      }
      setPos(newPos)

      if (onDrag) {
        onDrag({ key: groupKey, x: newPos.x, y: newPos.y })
      }
    },
    [isDragging, clientPointToSvgCoords, groupKey, onDrag]
  )

  const handleMouseUp = useCallback(
    (e) => {
      if (!isDragging) return

      e.preventDefault()
      e.stopPropagation()
      setIsDragging(false)

      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    },
    [isDragging, handleMouseMove]
  )

  return (
    <g
      ref={groupRef}
      transform={`translate(${pos.x}, ${pos.y})`}
      style={{ cursor: disableDrag ? 'default' : 'move' }}
      onMouseDown={handleMouseDown}
    >
      {isDragging && (
        <rect
          x={bbox.x}
          y={bbox.y}
          width={bbox.width}
          height={bbox.height}
          fill="none"
          stroke="blue"
          strokeDasharray="4"
          pointerEvents="none"
        />
      )}

      {children}
    </g>
  )
}
