import React from 'react'
import { BadgeProvider } from '../../context/BadgeContext'
import BadgeForm from './BadgeForm'
import BadgeTemplate from './BadgeTemplate'
import RecentBadgeList from './RecentBadgeList'
import { useTranslation } from 'react-i18next'

function BadgeGenerator() {
  const { t } = useTranslation('adminPages')
  return (
    <BadgeProvider>
      <div className={'w-full flex gap-5 max-lg:flex-wrap'}>
        <div className={'basis-6/12'}>
          <BadgeForm />
        </div>
        <div className={'basis-6/12'}>
          <BadgeTemplate />
        </div>
      </div>
      <hr className={'my-10'} />
      <div>
        <h3 className={'text-xl font-medium my-2'}>{t('badgeGenerator.mostRecent')}</h3>
        <RecentBadgeList />
      </div>
    </BadgeProvider>
  )
}

export default BadgeGenerator
