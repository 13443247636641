import { useMemo } from 'react'

import useGetCategoryName from './useGetCategoryName'

const EMPTY_ARR = []

export default function useCategoriesOptions(sort, prefferedLocale) {
  const { allCategoriesMap, allCategoriesLoading, getCategoryName } = useGetCategoryName()

  const categoriesOptionsMemo = useMemo(() => {
    if (!allCategoriesMap) {
      return EMPTY_ARR
    }

    const unsortedCategoriesOptions = Object.keys(allCategoriesMap).map((categoryKey) => {
      return {
        value: categoryKey,
        label: prefferedLocale
          ? getCategoryName(categoryKey, false, prefferedLocale) // TODO: Check if this can be only there with default preffered local, this is adjusted to not break just in case...
          : getCategoryName(categoryKey)
      }
    })

    if (sort) {
      return unsortedCategoriesOptions.sort((a, b) => {
        const aLabel = a.label?.trim()?.toLowerCase() || ''
        const bLabel = b.label?.trim()?.toLowerCase() || ''

        return aLabel.localeCompare(bLabel)
      })
    }

    return unsortedCategoriesOptions
  }, [allCategoriesMap, getCategoryName, sort, prefferedLocale])

  return {
    categoriesOptions: categoriesOptionsMemo,
    categoriesOptionsLoading: allCategoriesLoading
  }
}
