import React, { useState } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { InformationCircleIcon } from '@heroicons/react/outline'
import Dialog from '../shared/dialog'
import useMediaQuery from '../../hooks/useMediaQuery'
import {
  POSSIBLE_STATES_ARR,
  STAGE_OBJECTS,
  STATE_NONE
} from '../../constants/lead-guide-journey-stages'

function CustomerStagesExplainer({ stage, noStateDecoration = false }) {
  const [openDialogState, setOpenDialogState] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const { t } = useTranslation()
  const isDesktop = useMediaQuery('(min-width: 1280px)')
  const currentStageInfo = STAGE_OBJECTS[stage] || STATE_NONE
  function handleOpenDialog(stateObj) {
    setOpenDialogState(stateObj)
    setDialogOpen(true)
  }
  if (!currentStageInfo) {
    return null
  }
  return (
    <div className={cx('flex gap-1', { 'flex-1': isDesktop })}>
      {POSSIBLE_STATES_ARR.map((stateObj) => {
        const isCurrentState =
          currentStageInfo?.cardTitleTid === stateObj?.cardTitleTid && !noStateDecoration
        if (!isDesktop && !isCurrentState && !noStateDecoration) {
          return null
        }
        return (
          <div
            key={stateObj?.cardTitleTid}
            className={cx(
              'flex flex-1 justify-center rounded px-1 py-[1px] text-gray-600 gap-1 z-[5] cursor-help',
              currentStageInfo.extraClassName,
              {
                'bg-green-400': isCurrentState,
                'bg-gray-300': !isCurrentState
              }
            )}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleOpenDialog(stateObj)
            }}
          >
            <span className="text-xs uppercase">{t(stateObj?.cardTitleTid)}</span>
            <InformationCircleIcon className="w-4 h-4" />
          </div>
        )
      })}
      <Dialog
        className="!max-w-md"
        title={openDialogState ? t(openDialogState?.modalTitleTid) : ''}
        isOpen={dialogOpen}
        onClose={setDialogOpen}
      >
        {openDialogState && (
          <div className="flex flex-col gap-6">
            {openDialogState?.texts?.map((section, index) => (
              <div className="flex flex-col" key={index}>
                <p className="text-sm font-semibold text-gray-700">
                  {t(section?.headerTid)}
                </p>
                {section?.textTid && (
                  <p className="text-sm text-gray-600">{t(section?.textTid)}</p>
                )}
              </div>
            ))}
          </div>
        )}
      </Dialog>
    </div>
  )
}

export default CustomerStagesExplainer
