import { useState, useEffect } from 'react'
import { buyingIntentFilters } from '../utils/buying-intent-filter.utils'
import { extractRevenueOptions } from '../utils/revenue.utils'
import { useTranslation } from 'react-i18next'

export const useBuyingIntentFilters = (buyingIntents, isAdmin = false) => {
  const { t } = useTranslation('buyingIntent')
  const [filters, setFilters] = useState(buyingIntentFilters)

  useEffect(() => {
    const categories = buyingIntents
      .filter((bi) => !!bi.bwBranch)
      .map((bi) => {
        return { value: bi?.bwBranch, label: t(`forms:system.branch.${bi.bwBranch}`) }
      })
      .filter((bi, index, self) => self.findIndex((t) => t.value === bi.value) === index)

    const minRevenueOptions = extractRevenueOptions(buyingIntents, 'min')
    const maxRevenueOptions = extractRevenueOptions(buyingIntents, 'max')

    setFilters((prevFilters) =>
      prevFilters
        .map((filter) => {
          if (filter.id === 'branch') {
            return { ...filter, options: categories }
          }
          if (filter.id === 'revenueMin') {
            return { ...filter, options: minRevenueOptions }
          }
          if (filter.id === 'revenueMax') {
            return { ...filter, options: maxRevenueOptions }
          }
          return filter
        })
        .sort((a, b) => a.sort - b.sort)
        .filter((filter) => !(filter.id == 'days' && !isAdmin))
    )
  }, [buyingIntents, t])

  return { filters }
}
