import React, { useState } from 'react'
import { TrashIcon } from '@heroicons/react/outline'
import Dialog from '../../../shared/dialog'
import useGetCategoryName from '../../../../hooks/useGetCategoryName'
import { useTranslation } from 'react-i18next'

const RecentBadgeListItem = ({
  badgeId,
  category,
  type,
  attributes,
  expireAt,
  createdAt,
  onDelete
}) => {
  const { t } = useTranslation('adminPages')
  const { getCategoryName } = useGetCategoryName()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const handleDelete = async () => {
    await onDelete(badgeId)
    setIsDeleteDialogOpen(false)
  }

  return (
    <div
      className={
        'w-full px-3 py-5 flex items-center justify-between shadow-md rounded-lg bg-white'
      }
    >
      <div>
        <p className={'text-sm font-semibold'}>
          {attributes['en_GB'].headlineAttribute.title}
        </p>
      </div>

      <div>
        <p className={'text-sm text-gray-400 uppercase font-semibold'}>
          {t('badgeGenerator.category')}
        </p>
        <hr className="my-1" />
        <p
          className={
            'p-1 uppercase text-sm font-semibold bg-green-600 text-white rounded-md text-center'
          }
        >
          {getCategoryName(category)}
        </p>
      </div>
      <div>
        <p className={'text-sm text-gray-400 uppercase font-semibold'}>
          {t('badgeGenerator.badgeType')}
        </p>
        <hr className="my-1" />
        <p
          className={
            'py-1 px-2 uppercase text-sm font-semibold bg-indigo-600 text-white rounded-md text-center'
          }
        >
          {type}
        </p>
      </div>

      <div>
        <p className={'text-sm text-gray-400 uppercase font-semibold'}>
          {t('badgeGenerator.expiresAt')}
        </p>
        <hr className="my-1" />
        <p className={'text-sm '}>{new Date(expireAt).toLocaleDateString()}</p>
      </div>
      <div>
        <p className={'text-sm text-gray-400 uppercase font-semibold'}>
          {t('badgeGenerator.createdAt')}
        </p>
        <hr className="my-1" />
        <p className={'text-sm '}>{new Date(createdAt).toLocaleDateString()}</p>
      </div>
      <div className={'flex items-center gap-3'}>
        <TrashIcon
          onClick={() => setIsDeleteDialogOpen(true)}
          className={'cursor-pointer'}
          width={20}
          height={20}
          color={'red'}
        />
        <Dialog
          isOpen={isDeleteDialogOpen}
          title={t('badgeGenerator.confirmTextDelete')}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={handleDelete}
          confirmText={t('badgeGenerator.button.delete')}
        ></Dialog>
      </div>
    </div>
  )
}
export default RecentBadgeListItem
