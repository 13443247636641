import { gql } from '@apollo/client'
import useAdminQuery from '../../../hooks/useAdminQuery'

const GUIDE_LEAD_INSIGHTS_QUERY = gql`
  query GetBuyingIntentInsights($companyId: ID, $userId: ID, $locale: String) {
    appGuideLeadInsights(companyId: $companyId, userId: $userId, locale: $locale) {
      role {
        label
        count
      }
      country {
        label
        count
      }
      industry {
        label
        count
      }
      buyersJourneyStage {
        label
        count
      }
      companySize {
        label
        count
      }
    }
  }
`

/**
 * Hook to fetch buying intent insights.
 *
 * @param {Object} variables - Variables for the GraphQL query: companyId, userId, systemId.
 * @returns {{
 *   getChartData: function(): {
 *     role: Array<{ label: string, count: number }>,
 *     country: Array<{ label: string, count: number }>,
 *     industry: Array<{ label: string, count: number }>,
 *     buyersJourneyStage: Array<{ label: string, count: number }>,
 *     companySize: Array<{ label: string, count: number }>
 *   } | undefined,
 *   loading: boolean,
 *   error: any
 * }}
 */
const useFetchGuideLeadInsights = (variables) => {
  const { data, loading, error } = useAdminQuery(GUIDE_LEAD_INSIGHTS_QUERY, {
    variables,
    fetchPolicy: 'cache-and-network'
  })

  const getChartData = () => {
    if (loading || error || !data) {
      return
    }

    const insights = data.appGuideLeadInsights

    return {
      role: Array.isArray(insights.role) ? insights.role : [],
      country: Array.isArray(insights.country) ? insights.country : [],
      industry: Array.isArray(insights.industry) ? insights.industry : [],
      buyersJourneyStage: Array.isArray(insights.buyersJourneyStage)
        ? insights.buyersJourneyStage
        : [],
      companySize: Array.isArray(insights.companySize) ? insights.companySize : []
    }
  }

  return { getChartData, loading, error }
}

export default useFetchGuideLeadInsights
