import React from 'react'
import { gql, useMutation } from '@apollo/client'
import { useUserContext } from '../../contexts'
import LoadingSpinner from '../shared/loading-spinner'

export const RESEND_WEBHOOK_EVENT = gql`
  mutation resendWebhookEvent($eventId: ID!, $companyId: ID) {
    resendWebhookEvent(eventId: $eventId, companyId: $companyId)
  }
`
/**
 * A reusable "Retry" button for resending a failed or abandoned webhook event.
 *
 * @param children
 * @param className
 * @param {string} eventId - The ID of the webhook event to resend
 * @param {function} [onSuccess] - Called when the resend completes successfully
 * @param {function} [onError] - Called if the resend fails
 */
const RetryWebhookEventButton = ({
  children,
  className,
  eventId,
  onSuccess,
  onError
}) => {
  const { user } = useUserContext()
  const [resendWebhookEvent, { loading }] = useMutation(RESEND_WEBHOOK_EVENT)
  const handleRetry = async () => {
    try {
      const { data } = await resendWebhookEvent({
        variables: { eventId, companyId: user.companyId }
      })
      if (onSuccess) {
        onSuccess(data?.resendWebhookEvent)
      }
    } catch (error) {
      if (onError) {
        onError(error)
      }
    }
  }
  return (
    <button onClick={handleRetry} disabled={loading} className={`rounded ${className}`}>
      {loading ? <LoadingSpinner /> : children}
    </button>
  )
}

export default RetryWebhookEventButton
