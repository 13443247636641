import { useState, useEffect, useRef } from 'react'
import { gql } from '@apollo/client'
import useAdminQuery from '../../../hooks/useAdminQuery'

export const GET_BUYING_INTENTS = gql`
  query GetBuyingIntent(
    $companyId: ID
    $userId: ID
    $page: Int
    $limit: Int
    $systemId: ID
    $filters: BuyingIntentFiltersInput
  ) {
    appBuyingIntentV2(
      companyId: $companyId
      userId: $userId
      page: $page
      limit: $limit
      systemId: $systemId
      filters: $filters
    ) {
      activities {
        id
        originalId
        status
        name
        activity
        category
        bwBranch
        companySize
        locales
        site
        linkedInUrl
        awareness
        decision
        consideration
        stage
        stagePercentage
        revenue
        lastSeen
        buyingIntentActivityToSystem {
          id
          systemId
          customerProfileMatch
          systemNotificationStatus
          systemSiteData {
            systemName
          }
        }
      }
      totalCount
      hasAccess
    }
  }
`

export const useBuyingIntents = (systemId) => {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(50)
  const [totalCount, setTotalCount] = useState(0)
  const [buyingIntents, setBuyingIntents] = useState([])
  const [hasAccess, setHasAccess] = useState(false)
  const [filters, setFilters] = useState({})
  const [activeFiltersState, setActiveFiltersState] = useState([])
  const isFilterChanging = useRef(false)

  const { loading, error, refetch } = useAdminQuery(GET_BUYING_INTENTS, {
    variables: {
      systemId,
      page,
      limit,
      filters
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ appBuyingIntentV2 }) => {
      setBuyingIntents(appBuyingIntentV2.activities)
      setTotalCount(appBuyingIntentV2.totalCount)
      setHasAccess(appBuyingIntentV2.hasAccess)
    }
  })

  useEffect(() => {
    if (!isFilterChanging.current && activeFiltersState.length > 0) {
      applyFilters(activeFiltersState, false)
    } else if (
      !isFilterChanging.current &&
      Object.keys(filters).length > 0 &&
      activeFiltersState.length === 0
    ) {
      resetFilters()
    }
  }, [limit, page])

  const resetFilters = async () => {
    isFilterChanging.current = true

    setFilters({})
    setActiveFiltersState([])

    try {
      const result = await refetch({
        systemId,
        page: 1,
        limit,
        filters: {}
      })

      if (result?.data?.appBuyingIntentV2) {
        setBuyingIntents(result.data.appBuyingIntentV2.activities)
        setTotalCount(result.data.appBuyingIntentV2.totalCount)
      }

      if (page !== 1) {
        setPage(1)
      }
    } catch (error) {
      console.error('Error during filter reset:', error)
    } finally {
      isFilterChanging.current = false
    }
  }

  /**
   * Applies filters to the buying intents
   * @param {Array} activeFilters - The active filters to apply
   * @param {boolean} updateActiveState - Whether to update the activeFiltersState
   */
  const applyFilters = async (activeFilters, updateActiveState = true) => {
    if (!activeFilters || activeFilters.length === 0) {
      resetFilters()
      return
    }

    isFilterChanging.current = true

    if (updateActiveState) {
      setActiveFiltersState(activeFilters)
    }

    const backendFilters = {}

    // Apply all active filters
    activeFilters.forEach(({ filter: filterId, value }) => {
      switch (filterId) {
        case 'companySize':
          if (!backendFilters.companySize) backendFilters.companySize = []
          backendFilters.companySize.push(value)
          break
        case 'branch':
          if (!backendFilters.branch) backendFilters.branch = []
          backendFilters.branch.push(value)
          break
        case 'revenueMin':
          backendFilters.revenueMin = value
          break
        case 'revenueMax':
          backendFilters.revenueMax = value
          break
        case 'activity':
          if (!backendFilters.activity) backendFilters.activity = []
          backendFilters.activity.push(value)
          break
        case 'buyersJourneyStage':
          if (!backendFilters.buyersJourneyStage) backendFilters.buyersJourneyStage = []
          backendFilters.buyersJourneyStage.push(value)
          break
        case 'customerProfileMatch':
          backendFilters.customerProfileMatch = value
          break
        case 'days':
          backendFilters.days = parseInt(value)
          break
        default:
          break
      }
    })
    setFilters(backendFilters)

    // We need to reset to page 1 when applying filters
    const currentPage = updateActiveState ? 1 : page

    // If we're changing filters and not on page 1, reset to page 1
    if (updateActiveState && page !== 1) {
      setPage(1)
    }

    try {
      const result = await refetch({
        systemId,
        page: currentPage,
        limit,
        filters: backendFilters
      })

      if (result?.data?.appBuyingIntentV2) {
        setBuyingIntents(result.data.appBuyingIntentV2.activities)
        setTotalCount(result.data.appBuyingIntentV2.totalCount)
      }
    } catch (err) {
      console.error('Error during filter apply refetch:', err)
    } finally {
      isFilterChanging.current = false
    }
  }

  /**
   * Fetches all buying intents for export
   * @param {Array} activeFilters - The active filters to apply
   * @returns {Array} - The fetched buying intents
   */
  const fetchAllForExport = async (activeFilters = []) => {
    try {
      isFilterChanging.current = true

      const currentFilters = { ...filters }
      const currentBuyingIntents = [...buyingIntents]
      const currentTotalCount = totalCount
      const currentPage = page
      const currentLimit = limit

      const exportFilters = {}

      activeFilters.forEach(({ filter: filterId, value }) => {
        switch (filterId) {
          case 'companySize':
            if (!exportFilters.companySize) exportFilters.companySize = []
            exportFilters.companySize.push(value)
            break
          case 'branch':
            if (!exportFilters.branch) exportFilters.branch = []
            exportFilters.branch.push(value)
            break
          case 'revenueMin':
            exportFilters.revenueMin = value
            break
          case 'revenueMax':
            exportFilters.revenueMax = value
            break
          case 'activity':
            if (!exportFilters.activity) exportFilters.activity = []
            exportFilters.activity.push(value)
            break
          case 'buyersJourneyStage':
            if (!exportFilters.buyersJourneyStage) exportFilters.buyersJourneyStage = []
            exportFilters.buyersJourneyStage.push(value)
            break
          case 'customerProfileMatch':
            exportFilters.customerProfileMatch = value
            break
          case 'days':
            exportFilters.days = parseInt(value)
            break
          default:
            break
        }
      })

      const result = await refetch({
        systemId,
        page: 1,
        limit: 500, // Using a large number to get all records
        filters: exportFilters
      })

      setTimeout(() => {
        setFilters(currentFilters)
        setBuyingIntents(currentBuyingIntents)
        setTotalCount(currentTotalCount)
        setPage(currentPage)
        setLimit(currentLimit)
        isFilterChanging.current = false
      }, 0)

      if (
        !result ||
        !result.data ||
        !result.data.appBuyingIntentV2 ||
        !result.data.appBuyingIntentV2.activities
      ) {
        return []
      }
      return [...result.data.appBuyingIntentV2.activities]
    } catch (error) {
      console.error('Error during export:', error)
      isFilterChanging.current = false
      return []
    }
  }

  const handleSetLimit = (newLimit) => {
    setLimit(newLimit)
  }

  const handleSetPage = (newPage) => {
    setPage(newPage)
  }

  return {
    page,
    setPage: handleSetPage,
    limit,
    setLimit: handleSetLimit,
    totalCount,
    buyingIntents,
    hasAccess,
    applyFilters,
    resetFilters,
    loading,
    error,
    refetch,
    fetchAllForExport
  }
}

export default useBuyingIntents
