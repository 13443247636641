import React, { useContext, useImperativeHandle, useRef } from 'react'
import BadgeContext from '../../context/BadgeContext'
import useGetCategoryName from '../../../../hooks/useGetCategoryName'
import BadgeTypeHandler from './BadgeTypeHandler'
import SvgWrappedText from '../svg/SvgWrappedText'
import DraggableGroup from '../svg/DraggableGroup'
import { convertToPNG } from '../../helper/svg-png-converter.helper'

function BadgeTemplate({
  badgeType,
  category,
  categoryLocale,
  attributes,
  year,
  rating,
  isDraggable = true,
  disableLocale = false,
  height = 348,
  width = 328,
  className,
  badgeRef,
  onChange
}) {
  const context = useContext(BadgeContext)
  const {
    badgeType: contextBadgeType = '',
    selectedCategory: contextCategory = '',
    attributes: contextAttributes = {},
    selectedCategoryLocale: contextSelectedCategoryLocale = 'en_GB',
    year: contextYear,
    rating: contextRating,
    updateAttributes = () => {}
  } = context || {}

  const { subHeadlineAttribute, badgeTypeAttribute, headlineAttribute, yearAttribute } =
    attributes || contextAttributes

  const { getCategoryName } = useGetCategoryName()
  const locale = !disableLocale
    ? categoryLocale || contextSelectedCategoryLocale
    : 'en_GB'

  const dynamicSubHeadline =
    subHeadlineAttribute.title ||
    getCategoryName(category || contextCategory, false, locale)

  const dynamicHeadline = headlineAttribute.title

  const svgRef = useRef(null)
  useImperativeHandle(badgeRef, () => ({
    downloadSVG: () => {
      if (!svgRef.current) return

      const svgString = new XMLSerializer().serializeToString(svgRef.current)
      const blob = new Blob([svgString], { type: 'image/svg+xml' })
      const url = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.download = 'badge.svg'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    },
    downloadPNG: async () => {
      if (!svgRef.current) return

      try {
        const canvas = await convertToPNG(svgRef.current)
        const pngUrl = canvas.toDataURL('image/png')

        const link = document.createElement('a')
        link.href = pngUrl
        link.download = 'badge.png'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (error) {
        console.error('PNG conversion failed:', error)
      }
    }
  }))

  const handleAttributeUpdate = (key, newAttributes) => {
    updateAttributes(key, newAttributes)
    if (onChange) {
      onChange(key, {
        [key]: newAttributes
      })
    }
  }

  return (
    <div
      className={`flex justify-center shadow-lg rounded-2xl bg-white p-8 mt-5 ${className}`}
    >
      <svg
        ref={svgRef}
        width={width}
        height={height}
        viewBox="0 0 428 448"
        fill="none"
        style={{ background: 'transparent', fontFamily: 'Ubuntu, sans-serif' }}
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-labelledby="svg-title svg-desc"
      >
        <title id="svg-title">BusinessWith Trusted Partner</title>
        <desc id="svg-desc">Official badge linking on BusinessWith.</desc>
        <path
          d="M428 151.919C428 133.551 426.475 115.324 422.122 97.4362C410.687 50.4067 382.421 19.2038 335.096 6.25839C320.552 2.26674 305.636 0.145858 290.56 0.0158037C230.336 -0.52442 173.292 12.7811 119.899 40.5926C82.6053 60.0207 50.3167 85.6913 28.2293 121.996C-3.61798 174.358 -9.36553 229.401 15.3199 286.215C27.9083 315.187 48.5513 338.336 72.5346 358.555C99.216 381.064 128.947 398.871 160.433 413.757C186.362 426.022 212.382 438.198 240.598 444.55C286.418 454.874 324.283 442.089 353.743 405.334C365.499 390.678 374.637 374.471 382.361 357.414C396.574 326.011 405.953 293.087 413.335 259.563C421.139 224.089 427.508 188.394 428 151.899V151.919Z"
          fill="url(#paint0_linear_2313_6)"
        />
        <path
          d="M403.622 106.417L403.622 106.417C407.589 122.738 408.988 139.404 408.99 156.258C408.538 189.773 402.695 222.596 395.503 255.334C388.706 286.24 380.086 316.517 367.048 345.362C359.967 361.02 351.615 375.84 340.9 389.216L340.899 389.217C327.502 405.954 312.269 417.144 295.264 423.008C278.258 428.872 259.337 429.456 238.489 424.752L238.489 424.752C212.699 418.938 188.88 407.787 164.924 396.44L164.923 396.44C135.981 382.739 108.7 366.371 84.2391 345.708L84.2386 345.708C62.2405 327.139 43.4337 305.982 31.9827 279.594C9.47998 227.736 14.6914 177.556 43.7706 129.683C63.9114 96.5339 93.3862 73.0339 127.584 55.1952C176.56 29.651 228.88 17.4294 284.139 17.9257L284.14 17.9257C297.875 18.0444 311.468 19.9791 324.727 23.6227L324.728 23.6232C346.257 29.5198 363.359 39.5456 376.374 53.3794C389.394 67.2179 398.412 84.9601 403.622 106.417Z"
          stroke="white"
          strokeWidth="4.02036"
        />
        <path
          d="M83.2455 347.869C61.1115 329.16 42.0601 307.739 30.4422 280.93C22.1931 261.894 18.5372 242.052 18.6251 222.183C104.518 180.183 301.803 121.983 403.804 225.183C397.804 265.683 382.301 317.755 369.183 346.813C362.055 362.597 353.622 377.594 342.772 391.156C315.584 425.167 280.638 436.997 238.35 427.444C212.31 421.566 188.297 410.3 164.367 398.95C135.308 385.175 107.87 368.698 83.2455 347.869Z"
          fill="white"
        />
        <path
          d="M163.018 149.648C156.538 149.648 149.811 148.651 144.319 143.353C137.839 137.182 135 126.836 135 109.82C135 92.8035 137.839 82.4567 144.257 76.2861C151.477 69.3675 160.673 69.8038 168.757 70.1777C170.67 70.2401 172.706 70.3647 174.62 70.3647C184.679 70.3647 192.393 74.8525 195.725 82.706C199.058 90.5596 197.33 100.408 191.159 109.633C197.021 118.172 198.934 128.145 195.725 136.185C192.516 144.413 184.617 149.274 174.62 149.274C172.645 149.274 170.67 149.337 168.757 149.461C166.905 149.586 164.931 149.648 163.018 149.648ZM162.894 88.5027C160.117 88.5027 157.895 88.752 156.97 89.687C155.859 90.7466 153.39 94.798 153.39 109.82C153.39 124.841 155.921 128.892 156.97 129.952C158.451 131.386 163.079 131.199 167.955 130.949C170.115 130.825 172.275 130.762 174.681 130.762C175.977 130.762 178.261 130.575 178.754 129.391C179.618 127.21 178.384 121.662 173.015 116.613L166.227 110.194L172.583 103.462C178.754 96.9173 179.495 91.4946 178.816 89.9986C178.569 89.4377 177.088 88.8767 174.62 88.8767C172.274 88.8767 170.053 88.752 167.893 88.6897C166.165 88.6274 164.437 88.5027 162.894 88.5027Z"
          fill="white"
        />
        <path
          d="M227.816 97.4162C227.816 98.6628 227.631 99.7847 227.261 100.782C226.89 101.779 226.397 102.652 225.718 103.338C225.039 104.023 224.175 104.584 223.188 104.958C222.2 105.332 221.028 105.519 219.793 105.519C219.238 105.519 218.683 105.519 218.127 105.457C217.572 105.394 216.955 105.332 216.399 105.27C215.844 105.207 215.288 105.083 214.795 104.958C214.301 104.833 213.807 104.709 213.437 104.646V82.831L217.819 82.1454V89.9366C218.312 89.7496 218.806 89.5626 219.361 89.438C219.917 89.3133 220.472 89.251 221.089 89.251C222.2 89.251 223.126 89.438 223.99 89.812C224.854 90.1859 225.533 90.7469 226.088 91.4325C226.644 92.1182 227.076 92.9908 227.384 93.9881C227.693 95.0477 227.816 96.1696 227.816 97.4162ZM223.373 97.2915C223.373 94.4244 222.324 92.9908 220.287 92.9908C219.855 92.9908 219.361 93.0531 218.929 93.1778C218.497 93.3024 218.127 93.4271 217.88 93.6141V101.655C218.065 101.717 218.374 101.717 218.683 101.779C218.991 101.779 219.361 101.842 219.793 101.842C220.966 101.842 221.892 101.405 222.509 100.595C223.064 99.7224 223.373 98.6628 223.373 97.2915Z"
          fill="white"
        />
        <path
          d="M244.355 104.646C243.615 104.833 242.627 105.082 241.455 105.269C240.282 105.456 239.048 105.581 237.752 105.581C236.456 105.581 235.345 105.394 234.481 105.02C233.617 104.646 232.938 104.147 232.383 103.524C231.889 102.9 231.519 102.09 231.272 101.217C231.087 100.345 230.964 99.3476 230.964 98.288V89.6241H235.345V97.7894C235.345 99.2229 235.53 100.22 235.901 100.844C236.271 101.467 236.95 101.778 237.999 101.778C238.307 101.778 238.616 101.778 238.986 101.716C239.357 101.716 239.665 101.654 239.912 101.591V89.6241H244.294V104.646H244.355Z"
          fill="white"
        />
        <path
          d="M252.933 102.028C253.735 102.028 254.291 101.966 254.661 101.779C254.97 101.592 255.155 101.343 255.155 100.844C255.155 100.47 254.97 100.158 254.538 99.909C254.106 99.6597 253.427 99.3481 252.563 98.9741C251.884 98.7248 251.267 98.4755 250.711 98.1638C250.156 97.9145 249.662 97.5405 249.292 97.1665C248.922 96.7925 248.613 96.2939 248.366 95.7953C248.119 95.2966 248.058 94.611 248.058 93.863C248.058 92.4295 248.613 91.2452 249.662 90.3726C250.711 89.5 252.193 89.126 254.106 89.126C255.031 89.126 255.957 89.1883 256.821 89.3753C257.685 89.5623 258.364 89.7493 258.858 89.9363L258.117 93.3644C257.623 93.1774 257.068 93.0528 256.451 92.8658C255.834 92.7411 255.155 92.6788 254.414 92.6788C253.057 92.6788 252.378 93.0528 252.378 93.863C252.378 94.05 252.378 94.1747 252.439 94.3617C252.501 94.4863 252.625 94.611 252.81 94.7357C252.995 94.8603 253.242 94.985 253.55 95.172C253.859 95.2966 254.229 95.4836 254.723 95.6706C255.71 96.0446 256.451 96.3562 257.13 96.7302C257.747 97.1042 258.24 97.4782 258.611 97.8522C258.981 98.2885 259.228 98.7248 259.351 99.2234C259.475 99.7221 259.598 100.283 259.598 100.969C259.598 102.527 259.043 103.649 257.87 104.459C256.698 105.269 255.093 105.643 252.995 105.643C251.637 105.643 250.465 105.519 249.601 105.269C248.675 105.02 248.058 104.833 247.688 104.646L248.428 101.031C249.169 101.343 249.909 101.53 250.711 101.717C251.39 101.966 252.131 102.028 252.933 102.028Z"
          fill="white"
        />
        <path
          d="M267.497 85.0748C267.497 85.8851 267.25 86.5083 266.695 87.007C266.201 87.4433 265.584 87.6926 264.843 87.6926C264.164 87.6926 263.547 87.4433 262.992 87.007C262.498 86.5707 262.19 85.8851 262.19 85.0748C262.19 84.2645 262.437 83.6412 262.992 83.1425C263.486 82.7062 264.103 82.4569 264.843 82.4569C265.522 82.4569 266.139 82.7062 266.695 83.1425C267.25 83.6412 267.497 84.2645 267.497 85.0748ZM267.127 105.207H262.745V89.6248H267.127V105.207Z"
          fill="white"
        />
        <path
          d="M271.2 90.1231C271.94 89.9361 272.928 89.6867 274.1 89.4998C275.273 89.3128 276.507 89.1881 277.803 89.1881C279.099 89.1881 280.21 89.3751 281.074 89.6867C281.938 89.9984 282.617 90.497 283.172 91.1827C283.666 91.806 284.036 92.5539 284.283 93.4265C284.468 94.2992 284.591 95.2964 284.591 96.356V105.145H280.21V96.917C280.21 95.4834 280.025 94.4861 279.654 93.8629C279.284 93.2396 278.605 92.9902 277.556 92.9902C277.248 92.9902 276.939 92.9902 276.569 93.0526C276.198 93.0526 275.89 93.1149 275.643 93.1772V105.207H271.261V90.1231H271.2Z"
          fill="white"
        />
        <path
          d="M287.801 97.5403C287.801 96.1691 287.986 94.9225 288.418 93.9252C288.85 92.8656 289.405 91.993 290.084 91.3073C290.763 90.6217 291.565 90.0607 292.429 89.7491C293.293 89.3751 294.219 89.1881 295.145 89.1881C297.366 89.1881 299.094 89.8737 300.328 91.245C301.563 92.6163 302.242 94.6108 302.242 97.2287C302.242 97.478 302.242 97.7896 302.242 98.1013C302.242 98.4129 302.18 98.6623 302.18 98.9116H292.244C292.368 99.8465 292.738 100.532 293.478 101.093C294.219 101.654 295.206 101.903 296.502 101.903C297.305 101.903 298.107 101.841 298.847 101.654C299.65 101.529 300.267 101.342 300.76 101.093L301.378 104.708C301.131 104.833 300.822 104.958 300.452 105.082C300.082 105.207 299.65 105.332 299.156 105.394C298.662 105.456 298.169 105.581 297.613 105.643C297.058 105.706 296.502 105.706 295.947 105.706C294.527 105.706 293.355 105.519 292.306 105.082C291.257 104.646 290.454 104.085 289.776 103.399C289.097 102.651 288.603 101.841 288.294 100.844C287.924 99.7219 287.801 98.6623 287.801 97.5403ZM298.045 95.8574C298.045 95.4834 297.983 95.1094 297.86 94.7355C297.737 94.3615 297.613 94.0498 297.366 93.8005C297.119 93.5512 296.873 93.3019 296.502 93.1149C296.132 92.9279 295.762 92.8656 295.206 92.8656C294.713 92.8656 294.281 92.9279 293.972 93.1149C293.602 93.3019 293.355 93.4889 293.108 93.8005C292.861 94.0498 292.676 94.4238 292.553 94.7355C292.429 95.0471 292.306 95.4834 292.244 95.8574H298.045Z"
          fill="white"
        />
        <path
          d="M310.079 102.028C310.882 102.028 311.437 101.966 311.807 101.779C312.178 101.592 312.301 101.343 312.301 100.844C312.301 100.47 312.116 100.158 311.684 99.909C311.252 99.6597 310.573 99.3481 309.709 98.9741C309.03 98.7248 308.413 98.4755 307.858 98.1638C307.302 97.9145 306.809 97.5405 306.438 97.1665C306.068 96.7925 305.759 96.2939 305.513 95.7953C305.327 95.2343 305.204 94.611 305.204 93.863C305.204 92.4295 305.759 91.2452 306.809 90.3726C307.858 89.5 309.339 89.126 311.252 89.126C312.178 89.126 313.103 89.1883 313.967 89.3753C314.831 89.5623 315.51 89.7493 316.004 89.9363L315.263 93.3644C314.769 93.1774 314.214 93.0528 313.597 92.8658C312.98 92.7411 312.301 92.6788 311.56 92.6788C310.203 92.6788 309.524 93.0528 309.524 93.863C309.524 94.05 309.524 94.1747 309.586 94.3617C309.647 94.4863 309.771 94.611 309.956 94.7357C310.141 94.8603 310.388 94.985 310.696 95.172C311.005 95.2966 311.375 95.4836 311.869 95.6706C312.856 96.0446 313.597 96.3562 314.276 96.7302C314.893 97.1042 315.387 97.4782 315.757 97.8522C316.127 98.2885 316.374 98.7248 316.497 99.2234C316.621 99.7221 316.744 100.283 316.744 100.969C316.744 102.527 316.189 103.649 315.016 104.459C313.844 105.269 312.239 105.643 310.141 105.643C308.783 105.643 307.611 105.519 306.747 105.269C305.821 105.02 305.204 104.833 304.834 104.646L305.574 101.031C306.315 101.343 307.055 101.53 307.858 101.717C308.537 101.966 309.339 102.028 310.079 102.028Z"
          fill="white"
        />
        <path
          d="M324.335 102.028C325.137 102.028 325.693 101.966 326.063 101.779C326.371 101.592 326.557 101.343 326.557 100.844C326.557 100.47 326.371 100.158 325.939 99.909C325.507 99.6597 324.829 99.3481 323.965 98.9741C323.286 98.7248 322.669 98.4755 322.113 98.1638C321.558 97.9145 321.064 97.5405 320.694 97.1665C320.324 96.7925 320.015 96.2939 319.768 95.7953C319.583 95.2343 319.46 94.611 319.46 93.863C319.46 92.4295 320.015 91.2452 321.064 90.3726C322.113 89.5 323.594 89.126 325.507 89.126C326.433 89.126 327.359 89.1883 328.223 89.3753C329.087 89.5623 329.766 89.7493 330.259 89.9363L329.519 93.3644C329.025 93.1774 328.47 93.0528 327.853 92.8658C327.235 92.7411 326.557 92.6788 325.816 92.6788C324.458 92.6788 323.78 93.0528 323.78 93.863C323.78 94.05 323.78 94.1747 323.841 94.3617C323.903 94.4863 324.026 94.611 324.212 94.7357C324.397 94.8603 324.644 94.985 324.952 95.172C325.261 95.2966 325.631 95.4836 326.125 95.6706C327.112 96.0446 327.853 96.3562 328.531 96.7302C329.149 97.1042 329.642 97.4782 330.013 97.8522C330.383 98.2885 330.63 98.7248 330.753 99.2234C330.877 99.7221 331 100.283 331 100.969C331 102.527 330.445 103.649 329.272 104.459C328.099 105.269 326.495 105.643 324.397 105.643C323.039 105.643 321.866 105.519 321.002 105.269C320.077 105.02 319.46 104.833 319.089 104.646L319.83 101.031C320.57 101.343 321.311 101.53 322.113 101.717C322.792 101.966 323.594 102.028 324.335 102.028Z"
          fill="white"
        />
        <path
          d="M222.878 127.771C222.508 129.267 222.076 130.763 221.644 132.259C221.212 133.755 220.719 135.251 220.225 136.747H216.831C216.46 135.937 216.09 134.939 215.658 133.755C215.226 132.633 214.794 131.387 214.362 130.015C213.93 128.644 213.498 127.21 213.004 125.715C212.572 124.219 212.079 122.66 211.647 121.164H216.275C216.46 121.85 216.584 122.66 216.769 123.471C216.954 124.343 217.139 125.154 217.386 126.088C217.571 126.961 217.818 127.896 218.065 128.769C218.312 129.641 218.497 130.514 218.744 131.387C218.991 130.514 219.237 129.641 219.484 128.706C219.731 127.771 219.978 126.899 220.163 126.026C220.41 125.154 220.595 124.281 220.78 123.471C220.965 122.66 221.15 121.912 221.336 121.164H224.483C224.668 121.85 224.792 122.66 225.038 123.471C225.224 124.281 225.47 125.154 225.656 126.026C225.841 126.899 226.088 127.771 226.334 128.706C226.581 129.641 226.828 130.514 227.075 131.387C227.322 130.576 227.569 129.704 227.754 128.769C228.001 127.896 228.186 126.961 228.433 126.088C228.618 125.216 228.865 124.343 229.05 123.471C229.235 122.66 229.42 121.85 229.543 121.164H234.11C233.678 122.723 233.246 124.219 232.752 125.715C232.32 127.21 231.827 128.644 231.395 130.015C230.963 131.387 230.531 132.633 230.099 133.755C229.667 134.877 229.297 135.874 228.926 136.747H225.532C225.038 135.251 224.545 133.755 224.113 132.259C223.681 130.763 223.249 129.267 222.878 127.771Z"
          fill="white"
        />
        <path
          d="M241.269 116.614C241.269 117.424 241.022 118.048 240.467 118.546C239.973 118.983 239.356 119.232 238.615 119.232C237.936 119.232 237.319 118.983 236.764 118.546C236.27 118.11 235.962 117.424 235.962 116.614C235.962 115.804 236.209 115.181 236.764 114.682C237.258 114.246 237.875 113.996 238.615 113.996C239.294 113.996 239.911 114.246 240.467 114.682C241.022 115.181 241.269 115.804 241.269 116.614ZM240.837 136.684H236.455V121.102H240.837V136.684Z"
          fill="white"
        />
        <path
          d="M244.848 117.238L249.23 116.552V121.102H254.476V124.78H249.168V130.265C249.168 131.2 249.353 131.948 249.662 132.509C249.97 133.07 250.649 133.319 251.637 133.319C252.13 133.319 252.562 133.257 253.118 133.194C253.612 133.132 254.044 133.007 254.476 132.82L255.093 136.248C254.537 136.498 253.982 136.685 253.303 136.809C252.686 136.996 251.884 137.059 250.896 137.059C249.724 137.059 248.736 136.872 247.934 136.56C247.132 136.248 246.515 135.75 246.083 135.189C245.651 134.628 245.28 133.88 245.095 133.07C244.91 132.259 244.787 131.324 244.787 130.327V117.238H244.848Z"
          fill="white"
        />
        <path
          d="M257.993 136.685V114.433L262.375 113.747V121.227C262.683 121.102 263.053 121.04 263.485 120.915C263.917 120.853 264.411 120.79 264.843 120.79C266.077 120.79 267.126 120.977 267.99 121.289C268.793 121.663 269.471 122.099 269.965 122.785C270.459 123.408 270.829 124.156 271.014 125.029C271.199 125.902 271.323 126.899 271.323 127.958V136.685H266.941V128.457C266.941 127.023 266.756 126.026 266.386 125.403C266.016 124.78 265.337 124.53 264.349 124.53C263.979 124.53 263.609 124.593 263.239 124.655C262.868 124.717 262.56 124.78 262.313 124.904V136.747H257.993V136.685Z"
          fill="white"
        />
        {/* HEADLINE */}
        <DraggableGroup
          disableDrag={!isDraggable}
          defaultX={headlineAttribute.x}
          defaultY={headlineAttribute.y}
          groupKey={'headlineAttribute'}
          onDrag={(data) =>
            handleAttributeUpdate('headlineAttribute', { x: data.x, y: data.y })
          }
        >
          <SvgWrappedText
            renderAsPath
            text={dynamicHeadline?.toUpperCase()}
            textAnchor="middle"
            fontSize={parseInt(headlineAttribute.fontSize)}
            x={0}
            y={0}
            fontWeight="normal"
            fill="url(#paint0_linear_2313_6)"
            maxWidth={380}
            lineHeight={1.2}
          />
        </DraggableGroup>
        {/* SUBHEADLINE */}
        <DraggableGroup
          disableDrag={!isDraggable}
          defaultX={subHeadlineAttribute.x}
          defaultY={subHeadlineAttribute.y}
          groupKey={'subHeadlineAttribute'}
          onDrag={(data) =>
            handleAttributeUpdate('subHeadlineAttribute', { x: data.x, y: data.y })
          }
        >
          <SvgWrappedText
            renderAsPath
            text={dynamicSubHeadline?.toUpperCase()}
            textAnchor="middle"
            fontSize={subHeadlineAttribute.fontSize}
            x={0}
            y={0}
            fontWeight="bold"
            fill="url(#paint0_linear_2313_6)"
            maxWidth={380}
            lineHeight={1.2}
          />
        </DraggableGroup>

        {/* BADGE TYPE */}
        <DraggableGroup
          disableDrag={!isDraggable}
          defaultX={badgeTypeAttribute.x}
          defaultY={badgeTypeAttribute.y}
          groupKey={'badgeTypeAttribute'}
          onDrag={(data) =>
            handleAttributeUpdate('badgeTypeAttribute', { x: data.x, y: data.y })
          }
        >
          <BadgeTypeHandler
            badgeType={badgeType || contextBadgeType}
            rating={rating || contextRating}
          />
        </DraggableGroup>

        {/* YEAR */}
        <DraggableGroup
          disableDrag={!isDraggable}
          defaultX={yearAttribute.x}
          defaultY={yearAttribute.y}
          groupKey={'yearAttribute'}
          onDrag={(data) =>
            handleAttributeUpdate('yearAttribute', { x: data.x, y: data.y })
          }
        >
          <SvgWrappedText
            text={year || contextYear}
            x={0}
            y={0}
            fill="#000000"
            renderAsPath={true}
            textAnchor="middle"
            fontSize={22}
            fontWeight="bold"
          />
        </DraggableGroup>
        <defs>
          {/* Existing gradients */}
          <linearGradient
            id="paint0_linear_2313_6"
            x1="0"
            y1="0"
            x2="257.897"
            y2="26.3434"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00C9BB" />
            <stop offset="1" stopColor="#0098CB" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2313_6"
            x1="71.6235"
            y1="262.578"
            x2="371.549"
            y2="262.578"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00BDC9" />
            <stop offset="1" stopColor="#0098CB" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}

export default BadgeTemplate
