import { gql } from '@apollo/client'
import useAdminQuery from '../../../hooks/useAdminQuery'

const GET_BADGE_CONNECTED_SYSTEMS = gql`
  query GetBadgeConnectedSystems(
    $userId: ID
    $companyId: ID
    $badgeId: String!
    $page: Int!
    $limit: Int!
  ) {
    appAdminGetBadgeConnectedSystems(
      userId: $userId
      companyId: $companyId
      badgeId: $badgeId
      page: $page
      limit: $limit
    ) {
      systems {
        id
        createdAt
        systemV2 {
          id
          systemSiteData {
            locale
            systemName
          }
        }
        locale
      }
      totalCount
    }
  }
`

/**
 * Custom hook that fetches systems connected to a badge
 * @param {number|string} badgeId - The badge ID
 * @param {number} limit - Number of items per page
 * @param {number} page - Current page
 * @param {boolean} skip - Whether to skip the query
 * @returns {{ data, totalCount, loading, error, refetch }}
 */
export function useFetchSystemsConnectedToBadge(badgeId, limit = 10, page = 1, skip) {
  const { data, loading, error, refetch } = useAdminQuery(GET_BADGE_CONNECTED_SYSTEMS, {
    variables: {
      badgeId,
      limit,
      page
    },
    skip,
    fetchPolicy: 'network-only' // Always fetch from network to ensure latest data
  })

  return {
    data: data?.appAdminGetBadgeConnectedSystems?.systems || [],
    totalCount: data?.appAdminGetBadgeConnectedSystems?.totalCount || 0,
    loading,
    error,
    refetch
  }
}
