import React from 'react'
import { PageHeader } from '../components/page-header'
import { useTranslation } from 'react-i18next'
import { PageBody } from '../components/page-body'
import BuyingIntentActivitySettings from '../components/buying-intents/BuyingIntentActivitySettings'

import { useUserContext } from '../contexts'
import { isAdmin, isAppAdmin } from '../utils'

const BuyingIntentV2SettingsPage = () => {
  const { t } = useTranslation('buyingIntent')

  const { user } = useUserContext()
  const admin = isAdmin(user?.role)
  const appAdmin = isAppAdmin(user?.appRole)

  if (!(admin || appAdmin)) {
    return
  }

  return (
    <>
      <PageHeader
        pageName={`${t('buyingIntentSettings:header.subtitle')} - Buying intent signals`}
        headerClassName="flex items-end gap-1"
      >
        <p
          className={
            'my-2 text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400'
          }
        >
          {t('buyingIntentSettings:header.subtitle_1')}
        </p>
        <p className={'my-2'}>{t('buyingIntentSettings:header.text_1')}</p>
        <p className={'my-2'}>{t('buyingIntentSettings:header.text_2')}</p>
      </PageHeader>
      <PageBody>
        <BuyingIntentActivitySettings />
      </PageBody>
    </>
  )
}

export default BuyingIntentV2SettingsPage
