import React, { useContext } from 'react'
import PieChartGraph from '../../../graphs/PieChartGraph'
import { BuyingIntentInsightContext } from '../../context/BuyingIntentInsightContext'

const ICPChart = ({ t }) => {
  const { icpMatch } = useContext(BuyingIntentInsightContext)

  const data = icpMatch
    .map((match) => ({
      name: `${match.bucket}%`,
      count: match.count
    }))
    .filter((match) => match?.count > 0)
  return (
    <PieChartGraph
      title={t('buyingIntent:filters.customerProfileMatchShort')}
      data={data}
      dataKey={'count'}
      nameKey={'name'}
    />
  )
}

export default ICPChart
