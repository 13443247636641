import React, {
  Fragment,
  useState,
  useMemo,
  memo,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback
} from 'react'
import { array, bool, oneOf, func } from 'prop-types'
import classNames from 'classnames'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'
import { usePrevious } from '../../hooks/usePrevious'

import Button from './button'
import Input from '../shared/input'
import { fixBoolean } from '../../helpers/general.helper'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import { AdjustmentsIcon } from '@heroicons/react/outline'
import { XIcon, XCircleIcon } from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'

const MAX_WIDTH_MAP = {
  normal: 380
}

const StyledPopoverPanel = styled(Popover.Panel)`
  ${({ $maxWidth, $searchable }) => {
    if ($searchable) {
      return `
        max-width: ${MAX_WIDTH_MAP.normal}px;
        width: ${MAX_WIDTH_MAP.normal}px;
      `
    }

    if ($maxWidth) {
      return `max-width: ${MAX_WIDTH_MAP[$maxWidth]}px`
    }
  }}
`

const isChecked = (activeFilters, id) => {
  return activeFilters.some((filter) => filter.id === id)
}

const isSingleValue = (filter) => {
  return filter?.singleValue || filter?.type === 'datepicker'
}

const getShouldTranslate = (string) => {
  return string.includes(':')
}

const FilterOptions = memo(({ filter, onChange, activeFilters }) => {
  const { t } = useTranslation()
  const singleValue = isSingleValue(filter)
  const [searchValue, setSearchValue] = useState('')

  const filterOptionsMemo = useMemo(() => {
    if (!filter.options) {
      return []
    }

    if (!searchValue) {
      return filter.options
    }

    return filter.options.filter((option) =>
      option.label.toLowerCase().includes(searchValue.toLowerCase())
    )
  }, [searchValue, filter.options])

  const renderItemContent = useCallback(
    (index, option) => {
      /**
       * Temporary solution for DOM optimization before Virtuoso is implemented here.
       * Virtuoso is already implemented in the select field (see Admin company/user/vendor select)
       */
      if (filter.searchable && index >= 100) {
        return null
      }

      return (
        <div
          key={index || `${filter.id}--${option.value}`}
          onChange={onChange.bind(null, option, filter)}
        >
          <input
            id={`${filter.id}--${option.value}`}
            name={filter.id}
            type="checkbox"
            checked={isChecked(activeFilters, `${filter.id}--${option.value}`)}
            onChange={onChange.bind(null, option, filter)}
            className={classNames(
              'h-4 w-4 border-gray-300 rounded text-blue-600 focus:ring-blue-500 cursor-pointer',
              {
                'rounded-full': singleValue
              }
            )}
          />
          <label
            htmlFor={`${filter.id}--${option.value}`}
            className="pl-3 text-sm font-medium text-gray-900 max-w-max cursor-pointer"
          >
            {option.labelTid ? t(option.labelTid) : option.label}
          </label>
        </div>
      )
    },
    [activeFilters, filter, onChange, singleValue, t]
  )

  if (filter.type === 'datepicker') {
    const todayDate = new Date().toISOString().split('T')[0]
    const currFilterData = activeFilters.find(
      (activeFilter) => activeFilter.id === filter.id
    )

    return (
      <input
        id={filter.id}
        type="date"
        max={todayDate}
        value={currFilterData?.value || ''}
        onChange={(event) => {
          const fakeOption = {
            label: event.target.value,
            value: event.target.value
          }

          onChange(fakeOption, filter, event)
        }}
      />
    )
  }

  return (
    <div>
      {filter.searchable && (
        <Input
          className="m-2 pb-2 border-b"
          placeholder={t('content:search')}
          value={searchValue}
          onChange={setSearchValue}
          autoFocus={true}
        />
      )}

      <div
        className={classNames('space-y-3', {
          'max-h-96 overflow-y-scroll p-4': filter.searchable
        })}
      >
        {filterOptionsMemo.map((option, index) => renderItemContent(index, option))}
      </div>
    </div>
  )
})
FilterOptions.displayName = 'FilterOptions'

const ActiveFilterItem = ({ activeFilter, onClear }) => {
  const { t } = useTranslation()
  const singleValue = isSingleValue(activeFilter)
  const translatedLabel = activeFilter.labelTid
    ? t(activeFilter.labelTid)
    : activeFilter.label
  let textLabel = translatedLabel

  if (singleValue) {
    const filterName = activeFilter.nameTid ? t(activeFilter.nameTid) : activeFilter.name
    textLabel = `${filterName} - ${translatedLabel}`
  }

  return (
    <span
      key={activeFilter.id}
      className="inline-flex rounded-full border border-gray-200 items-center py-1.5 pl-3 pr-3 text-sm font-medium bg-white text-gray-900 gap-2"
    >
      <span>{textLabel}</span>

      {onClear && (
        <div>
          <XCircleIcon
            className="w-5 h-5 text-gray-600 cursor-pointer"
            aria-hidden="true"
            onClick={() => {
              const option = {
                value: activeFilter.value,
                label: activeFilter.label
              }

              const event = {
                target: {
                  id: activeFilter.id,
                  value: activeFilter.value
                }
              }

              onClear(option, activeFilter, event)
            }}
          />
        </div>
      )}
    </span>
  )
}

const ActiveFilters = ({ activeFilters, onFilterChange }) => {
  const { t } = useTranslation()

  const singleFiltersMemo = useMemo(() => {
    return activeFilters.filter((filter) => isSingleValue(filter))
  }, [activeFilters])

  const multiFiltersToNameMapMemo = useMemo(() => {
    const map = {}

    activeFilters.forEach((activeFilter) => {
      const singleValue = isSingleValue(activeFilter)

      if (singleValue) {
        return
      }

      const filterName = activeFilter.nameTid || activeFilter.name

      if (!map[filterName]) {
        map[filterName] = []
      }

      map[filterName].push(activeFilter)
    })

    return map
  }, [activeFilters])

  return (
    <div className="flex flex-col gap-2">
      {singleFiltersMemo.length > 0 && (
        <div className="flex items-center gap-2 flex-wrap">
          <h3 className="text-xs font-semibold uppercase tracking-wide text-gray-500">
            {t('buyingIntent:filters.filters')}
            <span className="sr-only">, active</span>
          </h3>

          <div aria-hidden="true" className="hidden w-px h-5 bg-gray-300 sm:block" />

          {singleFiltersMemo.map((activeFilter) => {
            return (
              <ActiveFilterItem
                key={activeFilter.id}
                activeFilter={activeFilter}
                onClear={onFilterChange}
              />
            )
          })}
        </div>
      )}

      {Object.entries(multiFiltersToNameMapMemo).map(([name, filtersArr]) => {
        // Not a perfect solution but should work for now...
        const sectionName = getShouldTranslate(name) ? t(name) : name

        return (
          <div key={sectionName} className="flex items-center gap-2 flex-wrap">
            <h3 className="text-xs font-semibold uppercase tracking-wide text-gray-500">
              {sectionName}
              <span className="sr-only">, active</span>
            </h3>

            <div aria-hidden="true" className="hidden w-px h-5 bg-gray-300 sm:block" />

            {filtersArr.map((activeFilter) => {
              return (
                <ActiveFilterItem
                  key={activeFilter.id}
                  activeFilter={activeFilter}
                  onClear={onFilterChange}
                />
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

const Filters = forwardRef(
  (
    {
      filters,
      onChangeFilters,
      onRemovedFilter,
      enableDynamicFilters,
      maxWidth: propsMaxWidth,
      defaultFilters,
      isFlex = true
    },
    forwardedRef
  ) => {
    const { t } = useTranslation('buyingIntent')
    const [activeFilters, setActiveFilters] = useState(defaultFilters || [])
    const [open, setOpen] = useState(false)
    const prevFilters = usePrevious(filters)

    const getMappedFilters = useCallback((newActiveFilters) => {
      return newActiveFilters.reduce((acc, filter) => {
        const singleValue = isSingleValue(filter)
        const filterName = filter.filter
        const filterValue = fixBoolean(filter.value)

        // Handle singleValue setting
        if (singleValue) {
          acc[filterName] = filterValue
          return acc
        }

        // Multiple choices by default
        if (!acc[filterName]) {
          acc[filterName] = []
        }
        acc[filterName].push(filterValue)
        return acc
      }, {})
    }, [])

    /**
     * Sometimes we send in dynamic filters that can change while filters are applied.
     * One of the cases we need to handle is when filter changes from multiple choices to only
     * a single one allowed (singleValue = true). Below useEffect recalculates active filters
     * and filters out those that are active but are no longer allowed.
     */
    useEffect(() => {
      if (!enableDynamicFilters) {
        return
      }

      if (filters === prevFilters || !activeFilters.length) {
        return
      }

      let updatedActiveFilters = []

      activeFilters.forEach((activeFilter) => {
        const filterInstr = filters.find((filter) => filter.id === activeFilter.filter)

        /**
         * Filter option for the active filter was removed.
         */
        if (!filterInstr) {
          return false
        }

        /**
         * Filter type was changed from multiple to singleValue
         */
        const filterRequiresSingleValue = isSingleValue(filterInstr)
        const activeFilterIsSingleValue = isSingleValue(activeFilter)

        /**
         * If one active filter/value was already moved to updatedActiveFilters array,
         * do not move any other values.
         */
        const oneSingleValueAlreadyMoved = updatedActiveFilters.some((uActiveFilter) => {
          return filterRequiresSingleValue && uActiveFilter.filter === activeFilter.filter
        })

        if (oneSingleValueAlreadyMoved) {
          return false
        }

        if (filterRequiresSingleValue && !activeFilterIsSingleValue) {
          updatedActiveFilters.unshift({ ...activeFilter, singleValue: true })
        } else {
          updatedActiveFilters.push({ ...activeFilter, singleValue: false })
        }
      })

      const mappedFilters = getMappedFilters(updatedActiveFilters)

      onChangeFilters(updatedActiveFilters, mappedFilters)
      setActiveFilters(updatedActiveFilters)
    }, [
      activeFilters,
      enableDynamicFilters,
      getMappedFilters,
      onChangeFilters,
      prevFilters,
      filters
    ])

    /**
     * Exposed ref interface
     */
    useImperativeHandle(forwardedRef, () => ({}), [])

    const handleFilterChange = (option, filterInstr, event) => {
      const {
        target: { id: filterId, value: targetValue }
      } = event
      const [filterName, filterValue] = filterId.split('--')
      const fixedFilterValue = fixBoolean(filterValue || targetValue)
      const newActiveFilters = [...activeFilters]

      const shouldRemoveValue = newActiveFilters.some((activeFilter) => {
        if (filterInstr.type === 'datepicker') {
          // If the datepicker value is empty => remove
          return !fixedFilterValue
        }

        // If there's already an activeFilter with the same value => remove
        return activeFilter.value === fixedFilterValue
      })

      const singleValue = isSingleValue(filterInstr)

      // If it's singleValue, remove the old one first
      if (singleValue && !shouldRemoveValue) {
        const prevSingleValueIndex = newActiveFilters.findIndex((filter) => {
          return filter.filter === filterName
        })

        if (prevSingleValueIndex !== -1) {
          newActiveFilters.splice(prevSingleValueIndex, 1)
        }
      }

      const foundFilterIndex = newActiveFilters.findIndex(
        (filter) => filter.id === filterId
      )

      // If we found it already in the array => remove it
      if (foundFilterIndex !== -1) {
        const removedFilter = newActiveFilters[foundFilterIndex]
        newActiveFilters.splice(foundFilterIndex, 1)

        // Call onRemovedFilter if provided, passing the removed filter AND the updated list
        if (onRemovedFilter) {
          // NOTE: We are using the array *after* removal
          onRemovedFilter(removedFilter, newActiveFilters)
        }
      } else {
        // Otherwise, add it
        newActiveFilters.push({
          id: filterId,
          name: filterInstr.name,
          nameTid: filterInstr.nameTid,
          filter: filterName,
          value: fixedFilterValue !== undefined ? fixedFilterValue : targetValue,
          label: option.label,
          labelTid: option.labelTid,
          singleValue: singleValue || false,
          rawOption: option
        })
      }

      const mappedFilters = getMappedFilters(newActiveFilters)

      onChangeFilters(newActiveFilters, mappedFilters)
      setActiveFilters(newActiveFilters)
    }

    return (
      <div className="">
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 sm:hidden"
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-12 flex flex-col overflow-y-auto">
                <div className="px-4 flex items-center justify-between">
                  <h2 className="text-lg font-medium text-gray-900">
                    {t('filters.filters')}
                  </h2>
                  <button
                    type="button"
                    className="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Filters */}
                <form className="mt-4">
                  {filters.map((filter) => {
                    const filterName = filter.nameTid ? t(filter.nameTid) : filter.name

                    return (
                      <Disclosure
                        as="div"
                        key={filterName}
                        className="border-t border-gray-200 px-4 py-6"
                      >
                        {({ open }) => (
                          <>
                            <h3 className="-mx-2 -my-3 flow-root">
                              <Disclosure.Button className="px-2 py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400">
                                <span className="font-medium text-gray-900">
                                  {filterName}
                                </span>
                                <span className="ml-6 flex items-center">
                                  <ChevronDownIcon
                                    className={classNames(
                                      open ? '-rotate-180' : 'rotate-0',
                                      'h-5 w-5 transform'
                                    )}
                                    aria-hidden="true"
                                  />
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel
                              className={classNames('pt-6', {
                                '': filter.searchable
                              })}
                            >
                              <FilterOptions
                                filter={filter}
                                onChange={handleFilterChange}
                                activeFilters={activeFilters}
                              />
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    )
                  })}
                </form>
              </div>
            </Transition.Child>
          </Dialog>
        </Transition.Root>

        <section aria-labelledby="filter-heading">
          <h2 id="filter-heading" className="sr-only">
            {t('filters.filters')}
          </h2>

          <div className="relative border-b border-gray-200 pb-2">
            <div className="max-w-7xl mx-auto px-4 flex items-center justify-end sm:px-6 lg:px-8">
              <Button
                className="sm:hidden"
                mode="outlined"
                onClick={() => setOpen(true)}
                Icon={AdjustmentsIcon}
              >
                {t('filters.filters')}
              </Button>

              <div className="hidden sm:block">
                <div className="flow-root">
                  <Popover.Group
                    className={`-mx-4 ${
                      isFlex ? 'flex' : ''
                    } items-center divide-x divide-gray-200`}
                  >
                    {filters.map((filter) => {
                      const filterName = filter.nameTid ? t(filter.nameTid) : filter.name

                      return (
                        <Popover
                          key={filterName}
                          className="px-2 relative inline-block text-left"
                        >
                          <Popover.Button className="group inline-flex justify-center p-2 text-sm font-medium text-gray-700 hover:text-gray-900 border-0">
                            <span>{filterName}</span>

                            <ChevronDownIcon
                              className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                          </Popover.Button>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <StyledPopoverPanel
                              $maxWidth={propsMaxWidth || filter.maxWidth}
                              $searchable={filter.searchable}
                              className={classNames(
                                'origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl ring-1 ring-black ring-opacity-5 z-10 focus:outline-none w-max p-4',
                                {
                                  'p-0': filter.searchable
                                }
                              )}
                            >
                              <FilterOptions
                                filter={filter}
                                onChange={handleFilterChange}
                                activeFilters={activeFilters}
                              />
                            </StyledPopoverPanel>
                          </Transition>
                        </Popover>
                      )
                    })}
                  </Popover.Group>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classNames(
              Object.keys(activeFilters).length ? 'opacity-1' : 'opacity-0',
              'bg-gray-100 p-4'
            )}
          >
            <ActiveFilters
              activeFilters={activeFilters}
              onFilterChange={handleFilterChange}
            />
          </div>
        </section>
      </div>
    )
  }
)

Filters.displayName = 'Filters'

Filters.propTypes = {
  filters: array,
  maxWidth: oneOf(['normal']),
  enableDynamicFilters: bool,
  onRemovedFilter: func,
  onChangeFilters: func.isRequired
}

Filters.defaultProps = {
  filters: [],
  maxWidth: 'normal',
  enableDynamicFilters: false,
  onRemovedFilter: null
}

export default Filters
