import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { PieChart, Pie, ResponsiveContainer, Sector } from 'recharts'

import { ChartColors } from './helpers/chartcolors'

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
    containerWidth
  } = props

  const isSmall = containerWidth < 400
  const isMedium = containerWidth >= 400 && containerWidth < 600
  const labelScale = isSmall ? 0.5 : isMedium ? 0.7 : 1
  const lineScale = isSmall ? 0.4 : isMedium ? 0.6 : 1
  const textScale = isSmall ? 0.7 : isMedium ? 0.9 : 1

  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)

  // For small containers, shorten the lines and position labels closer
  let sx, sy, mx, my, ex, ey

  if (isSmall) {
    sx = cx + outerRadius * cos
    sy = cy + outerRadius * sin
    mx = cx + (outerRadius + 10) * cos
    my = cy + (outerRadius + 10) * sin
    ex = mx + (cos >= 0 ? 1 : -1) * 10
    ey = my
  } else {
    sx = cx + (outerRadius + 10 * labelScale) * cos
    sy = cy + (outerRadius + 10 * labelScale) * sin
    mx = cx + (outerRadius + 20 * labelScale) * cos
    my = cy + (outerRadius + 20 * labelScale) * sin
    ex = mx + (cos >= 0 ? 1 : -1) * 22 * labelScale
    ey = my
  }

  const textAnchor = cos >= 0 ? 'start' : 'end'
  const fontSize = isSmall ? '10px' : isMedium ? '12px' : '15px'
  const centerFontSize = isSmall ? '9px' : isMedium ? '12px' : '15px'

  const valueText = isSmall
    ? `${(percent * 100).toFixed(0)}%`
    : `${value} (${(percent * 100).toFixed(1)}%)`

  const lines = payload.name.split(' ')
  return (
    <g>
      {lines.map((line, index) => (
        <text
          x={cx}
          y={cy - (7 * lines.length * textScale) / 2}
          dy={12 * index * textScale}
          textAnchor="middle"
          style={{ fontSize: centerFontSize, fontWeight: 'bold' }}
          fill={'#4b5563'}
          key={index}
        >
          {line}
        </text>
      ))}

      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />

      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6 * lineScale}
        outerRadius={outerRadius + 10 * lineScale}
        fill={fill}
      />

      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />

      <circle cx={ex} cy={ey} r={2 * labelScale} fill={fill} stroke="none" />

      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12 * labelScale}
        y={ey}
        textAnchor={textAnchor}
        fill={'#333'}
        style={{ fontSize, fontWeight: 'bold' }}
      >
        {valueText}
      </text>
    </g>
  )
}

/**
 * Renders a bar chart component displaying buying intent data.
 *
 * @param {Object} props - Component properties.
 * @param {Array} props.data - Array of data points used to render the bar chart.
 * @param {string} [props.title] - Optional title text to be displayed above the chart.
 * @param {string} [props.barColor='#3b82f6'] - Color of the bars in the chart.
 * @param {string} [props.barColorText='#fff'] - Color of the text displayed on the bars.
 * @param {string} props.barKey - Key from the data set used as the value for the bar heights.
 * @param {string} props.dataKey - Key from the data set used for categories or labels.
 * @param {boolean} [props.showBarKeyXAxis=false] - Determines whether the bar key is displayed on the X-Axis.
 * @param {boolean} [props.showBarKeyInBar=false] - Determines whether the bar key values are shown inside the bars.
 * @param {number} [props.mobileHeight] - Optional height in pixels for mobile screens.
 * @returns {JSX.Element} A styled bar chart component.
 */
const PieChartGraph = ({
  data,
  title,
  height = 400,
  nameKey,
  dataKey,
  innerRadius = 90,
  outerRadius = 130,
  mobileHeight
}) => {
  const coloredData = data.map((d, index) => ({
    ...d,
    fill: ChartColors[index % ChartColors?.length]
  }))

  const highestIndex = data.reduce(
    (acc, item, index) => {
      if (acc[dataKey] < item[dataKey]) {
        return { [dataKey]: item[dataKey], index }
      }

      return acc
    },
    {
      [dataKey]: 0,
      index: 0
    }
  )

  const containerRef = useRef(null)
  const [activeIndex, setActiveIndex] = useState(highestIndex.index)
  const [containerWidth, setContainerWidth] = useState(600) // Default fallback

  // Get and track the container dimensions
  useEffect(() => {
    const updateContainerWidth = () => {
      if (containerRef.current) {
        const { width } = containerRef.current.getBoundingClientRect()
        setContainerWidth(width)
      }
    }

    updateContainerWidth()

    window.addEventListener('resize', updateContainerWidth)

    return () => window.removeEventListener('resize', updateContainerWidth)
  }, [])

  const isSmallContainer = containerWidth < 400
  const isMediumContainer = containerWidth >= 400 && containerWidth < 600

  const chartHeight = isSmallContainer && mobileHeight ? mobileHeight : height

  // Calculate adjusted radius based on container width
  const adjustedInnerRadius = isSmallContainer
    ? innerRadius * 0.5
    : isMediumContainer
    ? innerRadius * 0.7
    : innerRadius
  const adjustedOuterRadius = isSmallContainer
    ? outerRadius * 0.5
    : isMediumContainer
    ? outerRadius * 0.7
    : outerRadius

  return (
    <div className={'shadow-lg rounded-lg pb-5 bg-white'} ref={containerRef}>
      {title && (
        <h3
          className={
            'shadow bg-white font-semibold rounded-t-lg capitalize text-sm text-gray-500 p-3'
          }
        >
          {title}
        </h3>
      )}
      <ResponsiveContainer width="100%" height={chartHeight}>
        {!!data.length ? (
          <PieChart width={chartHeight} height={chartHeight}>
            <Pie
              activeIndex={activeIndex}
              activeShape={(props) => renderActiveShape({ ...props, containerWidth })}
              data={coloredData}
              dataKey={dataKey}
              nameKey={nameKey}
              innerRadius={adjustedInnerRadius}
              outerRadius={adjustedOuterRadius}
              cx="50%"
              cy="50%"
              label={false}
              onMouseEnter={(_, index) => {
                setActiveIndex(index)
              }}
            />
          </PieChart>
        ) : (
          <div
            className={'flex items-center flex-col text-gray-400 font-semibold text-sm'}
          >
            No Insight available at the moment, come back later.
          </div>
        )}
      </ResponsiveContainer>
    </div>
  )
}

PieChartGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  height: PropTypes.number,
  nameKey: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number,
  mobileHeight: PropTypes.number
}

export default PieChartGraph
