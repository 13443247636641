import React, { useState } from 'react'
import { gql } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import { useTranslation } from 'react-i18next'
import Table from '../components/shared/table'
import Pagination from '../components/shared/pagination'
import useAdminQuery from '../hooks/useAdminQuery'
import Loader from '../components/loader'
import WebhookRetryButton from '../components/webhooks/webhook-retry-button'
import { RefreshIcon } from '@heroicons/react/outline'

const GET_WEBHOOK_LOGS = gql`
  query appWebhookLogs(
    $companyId: ID
    $userId: ID
    $webhookId: ID
    $page: Int
    $limit: Int
  ) {
    appWebhookLogs(
      companyId: $companyId
      userId: $userId
      webhookId: $webhookId
      page: $page
      limit: $limit
    ) {
      logs {
        eventId
        name
        status
        event
        createdAt
      }
      totalCount
    }
  }
`

const WebhookStatusPill = ({ status, t = {} }) => {
  const statusColorMap = {
    NEW: 'bg-blue-100 text-blue-800',
    SUCCEEDED: 'bg-green-100 text-green-800',
    FAILED: 'bg-yellow-100 text-red-800',
    ABANDONED: 'bg-red-100 text-yellow-800'
  }
  const colorClasses = statusColorMap[status] || 'bg-gray-100 text-gray-800'
  return (
    <span
      className={`inline-block py-1 px-2 rounded-sm text-xs font-semibold ${colorClasses}`}
    >
      {t(`status.${status}`)}
    </span>
  )
}

const WebhookLogsPage = () => {
  const { t } = useTranslation('webhooks')
  const { webhookId } = useParams()
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)

  if (!webhookId) {
    navigate('/integration-settings')
  }

  // Destructure refetch from your custom query hook so we can call it on success
  const { data, loading, error, refetch } = useAdminQuery(GET_WEBHOOK_LOGS, {
    variables: { webhookId, page, limit }
  })

  const columns = [
    { header: t('log.name'), accessor: 'name' },
    {
      header: t('log.event.type'),
      accessor: 'event',
      render: (value) => (
        <div className="bg-gray-100 p-1 rounded-sm text-xs font-medium">
          {t(`vendorSettings:webhooks.triggers.${value}`)}
        </div>
      )
    },
    {
      header: t('log.event.status'),
      accessor: 'status',
      render: (value, record) => (
        <div className="flex gap-1 justify-center">
          <WebhookStatusPill status={value} t={t} />
          {record.status === 'ABANDONED' && (
            <WebhookRetryButton
              eventId={record.eventId}
              className="p-1 flex items-center justify-center bg-gray-400 hover:bg-gray-500"
              onSuccess={() => {
                refetch()
              }}
            >
              <RefreshIcon title="Resend" color="#fff" className="w-3 h-3" />
            </WebhookRetryButton>
          )}
        </div>
      )
    },
    {
      header: t('log.event.createdAt'),
      accessor: 'createdAt',
      render: (value) => new Date(value).toLocaleString()
    }
  ]

  const logs = data?.appWebhookLogs?.logs || []
  const totalCount = data?.appWebhookLogs?.totalCount || 0

  if (loading) {
    return (
      <div className="flex justify-center">
        <Loader />
      </div>
    )
  }

  if (error) {
    return (
      <div className="text-center">
        {t('common.error')}: {error.message}
      </div>
    )
  }

  return (
    <>
      <PageHeader pageName={t('log.header.title')} />
      <PageBody>
        <div className="p-5 bg-white shadow-md rounded-md">
          <Table rowClassname="text-center" columns={columns} data={logs} />
          <Pagination
            className="mt-10"
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalCount={totalCount}
            t={t}
          />
        </div>
      </PageBody>
    </>
  )
}

export default WebhookLogsPage
