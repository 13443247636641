export const STATE_AWARENESS = {
  cardTitleTid: 'buyersJourney:awerness.cardTitle',
  modalTitleTid: 'buyersJourney:awerness.modalTitle',
  extraClassName: '',
  texts: [
    {
      headerTid: 'buyersJourney:general.header1',
      textTid: 'buyersJourney:awerness.text1'
    },
    {
      headerTid: 'buyersJourney:general.header2',
      textTid: 'buyersJourney:awerness.text2'
    },
    {
      headerTid: 'buyersJourney:general.header3',
      textTid: 'buyersJourney:awerness.text3'
    },
    {
      headerTid: 'buyersJourney:general.header4',
      textTid: 'buyersJourney:awerness.text4'
    }
  ]
}

export const STATE_CONSIDERATION = {
  cardTitleTid: 'buyersJourney:consideration.cardTitle',
  modalTitleTid: 'buyersJourney:consideration.modalTitle',
  extraClassName: '',
  texts: [
    {
      headerTid: 'buyersJourney:general.header1',
      textTid: 'buyersJourney:consideration.text1'
    },
    {
      headerTid: 'buyersJourney:general.header2',
      textTid: 'buyersJourney:consideration.text2'
    },
    {
      headerTid: 'buyersJourney:general.header3',
      textTid: 'buyersJourney:consideration.text3'
    },
    {
      headerTid: 'buyersJourney:general.header4',
      textTid: 'buyersJourney:consideration.text4'
    }
  ]
}

export const STATE_DECISION = {
  cardTitleTid: 'buyersJourney:decision.cardTitle',
  modalTitleTid: 'buyersJourney:decision.modalTitle',
  extraClassName: '',
  texts: [
    {
      headerTid: 'buyersJourney:general.header1',
      textTid: 'buyersJourney:decision.text1'
    },
    {
      headerTid: 'buyersJourney:general.header2',
      textTid: 'buyersJourney:decision.text2'
    },
    {
      headerTid: 'buyersJourney:general.header3',
      textTid: 'buyersJourney:decision.text3'
    },
    {
      headerTid: 'buyersJourney:general.header4',
      textTid: 'buyersJourney:decision.text4'
    }
  ]
}

export const STATE_NONE = {
  cardTitleTid: 'buyersJourney:none.cardTitle'
}

export const STAGE_OBJECTS = {
  STATE_AWARENESS,
  STATE_CONSIDERATION,
  STATE_DECISION,
  NONE: STATE_NONE
}

export const POSSIBLE_STATES_ARR = [STATE_AWARENESS, STATE_CONSIDERATION, STATE_DECISION]
