import React, { useEffect, useState } from 'react'
import { wrapTextToWidth } from '../../helper/svg-text-wrap.helper'
import opentype from 'opentype.js'

function SvgWrappedText({
  text,
  x,
  y,
  maxWidth = 300,
  fontSize = 16,
  fontFamily = 'Ubuntu, sans-serif',
  fontWeight = 'normal',
  lineHeight = 1.2,
  renderAsPath = false,
  ...restProps
}) {
  const [paths, setPaths] = useState([])
  const [fontLoaded, setFontLoaded] = useState(false)

  const canvasFont = `${fontWeight} ${fontSize}px ${fontFamily}`
  const lines = wrapTextToWidth(text, maxWidth, canvasFont)

  const generatePaths = async () => {
    try {
      // Select font based on fontWeight
      const fontPath =
        fontWeight === 'bold'
          ? '/embed/fonts/Ubuntu-Bold.ttf'
          : fontWeight === 'normal'
          ? '/embed/fonts/Ubuntu-Regular.ttf'
          : '/embed/fonts/Ubuntu-Medium.ttf'

      const finalUrl = process.env.PUBLIC_URL + fontPath

      // Fetch the font file first, then load it with opentype.js
      const response = await fetch(finalUrl)
      if (!response.ok) {
        throw new Error(
          `Failed to load font from ${fontPath}: ${response.status} ${response.statusText}`
        )
      }
      const fontData = await response.arrayBuffer()
      const font = await opentype.parse(fontData)

      const linePaths = lines.map((line, i) => {
        // Calculate y position for each line
        const lineY = y + i * lineHeight * fontSize
        // Calculate width for centering
        const lineWidth = font.getAdvanceWidth(line, fontSize)
        // Center the line by adjusting x position
        const lineX = x - lineWidth / 2

        return {
          path: font.getPath(line, lineX, lineY, fontSize),
          line
        }
      })

      setPaths(linePaths)
      setFontLoaded(true)
    } catch (error) {
      console.error('Error loading font:', error)
    }
  }

  useEffect(() => {
    generatePaths()
  }, [text, x, y, fontSize, maxWidth, fontWeight])

  // Render as SVG path when renderAsPath is true and font is loaded
  if (renderAsPath && fontLoaded && paths.length > 0) {
    return (
      <g {...restProps}>
        {paths.map((item, index) => (
          <path
            key={index}
            d={item.path.toPathData()}
            fill={restProps.fill || 'currentColor'}
          />
        ))}
      </g>
    )
  }

  // Otherwise, render as normal SVG text with tspans
  return (
    <text
      x={x}
      y={y}
      fontSize={fontSize}
      fontFamily={fontFamily}
      fontWeight={fontWeight}
      textAnchor="middle"
      {...restProps}
    >
      {lines.map((line, i) => (
        <tspan key={i} x={x} dy={i === 0 ? '0em' : `${lineHeight}em`}>
          {line}
        </tspan>
      ))}
    </text>
  )
}

export default SvgWrappedText
