import React, { useContext, useMemo } from 'react'
import BadgeContext from '../../context/BadgeContext'
import BadgeAdminListItem from './BadgeAdminListItem'
import Pagination from '../../../shared/pagination'
import { useTranslation } from 'react-i18next'
import useCategoriesOptions from '../../../../hooks/useCategoriesOptions'
import Filters from '../../../shared/filters'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import Loader from '../../../loader'

const BadgeAdminList = () => {
  const { t } = useTranslation('adminPages')
  const { categoriesOptions } = useCategoriesOptions(true)
  const { badges, page, setPage, limit, setLimit, setActiveFilters, activeFilters } =
    useContext(BadgeContext)
  const { data, loading, totalCount } = badges
  const filtersMemo = useMemo(() => {
    return [
      {
        id: 'category',
        nameTid: 'content:category',
        options: categoriesOptions,
        searchable: true
      }
    ]
  }, [categoriesOptions])

  const onChangeFilters = (filters) => {
    setActiveFilters({
      ...activeFilters,
      categories: filters?.map((filter) => filter.value)
    })
  }
  return (
    <div>
      <Filters filters={filtersMemo} onChangeFilters={onChangeFilters} />
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        {loading && (
          <div className={'flex justify-center'}>
            <Loader />
          </div>
        )}
        {!data?.length && !loading > 0 && (
          <div
            className={
              'flex items-center gap-2 text-blue-500 p-3 bg-blue-100 rounded-lg border border-blue-300'
            }
          >
            <ExclamationCircleIcon width={25} />
            {t('allBadges.noBadgesAtMoment')}
          </div>
        )}
        <ul role="list" className="divide-y divide-gray-200">
          {data?.map((badge) => (
            <BadgeAdminListItem key={badge.id} badge={badge} />
          ))}
        </ul>
      </div>
      <hr className={'my-5'} />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        limitOptions={[12, 24, 36]}
        setLimit={setLimit}
        totalCount={totalCount}
        t={t}
      />
    </div>
  )
}

export default BadgeAdminList
