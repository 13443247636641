import React from 'react'
import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import BadgeGenerator from '../components/badges/partials/badge-generator/BadgeGenerator'
import { useTranslation } from 'react-i18next'

const AdminBadgeGeneratorPage = () => {
  const { t } = useTranslation('adminPages')
  return (
    <>
      <PageHeader pageName={t('badgeGenerator.heading')} />
      <PageBody>
        <BadgeGenerator />
      </PageBody>
    </>
  )
}

export default AdminBadgeGeneratorPage
