import { useCallback } from 'react'
import { useUserContext } from '../contexts'
import { isAdmin, isResearcher, isVendor } from '../utils'
import { fixBoolean } from '../helpers/general.helper'

const SYSTEM_ASSET_INPUT_FIELDS = [
  'action',
  'file',
  'systemSiteDataIds',
  'url',
  'locales',
  'order',
  'id',
  'config'
]

const fixNumber = (value) => {
  return !isNaN(value) ? Number(value) : undefined
}

const _prepareSystemSiteDataForSave = (siteData, userRole) => {
  const hasAdmin = isAdmin(userRole)
  const hasVendor = isVendor(userRole)
  const hasResearcher = isResearcher(userRole)

  const adminData = {}
  const vendorData = {}
  const researcherData = {}

  // Normal USER (VENDOR) or ADMIN
  if (hasVendor || hasAdmin) {
    vendorData.extraBtnLink = siteData.extraBtnLink
    vendorData.extraBtnText = siteData.extraBtnText
    vendorData.introDescription = siteData.introDescription?.replace(/\u00a0/g, ' ')
    vendorData.systemDescription = siteData.systemDescription
    vendorData.companyDescription = siteData.companyDescription
    vendorData.marketPositionDescription = siteData.marketPositionDescription
    vendorData.whySystemDescription = siteData.whySystemDescription
    vendorData.whyCompanyDescription = siteData.whyCompanyDescription
    vendorData.solvesClientDescription = siteData.solvesClientDescription
    vendorData.goodToKnowDescription = siteData.goodToKnowDescription
    vendorData.price = siteData.price
    vendorData.priceAdditional = siteData.priceAdditional
    vendorData.priceDescription = siteData.priceDescription
  }

  // ADMIN ONLY
  if (hasAdmin) {
    adminData.systemName = siteData.systemName
    adminData.companyName = siteData.companyName
    adminData.visibility = siteData.visibility
    adminData.customerType = siteData.customerType
    adminData.guideBoost = siteData.guideBoost
    adminData.revenueDontKnow = fixBoolean(siteData.revenueDontKnow)

    const licensesMin = fixNumber(siteData.licensesMin)
    const licensesMax = fixNumber(siteData.licensesMax)
    const revenueMin = fixNumber(siteData.revenueMin)
    const revenueMax = fixNumber(siteData.revenueMax)

    if (
      siteData.hasOwnProperty('licensesMin') ||
      siteData.hasOwnProperty('licensesMax')
    ) {
      adminData.licenses = {}
    }

    if (siteData.hasOwnProperty('licensesMin')) {
      adminData.licenses.min = typeof licensesMin === 'number' ? licensesMin : null
    }

    if (siteData.hasOwnProperty('licensesMax')) {
      adminData.licenses.max = typeof licensesMax === 'number' ? licensesMax : null
    }

    if (siteData.hasOwnProperty('revenueMin') || siteData.hasOwnProperty('revenueMax')) {
      adminData.revenue = {}
    }

    if (siteData.hasOwnProperty('revenueMin')) {
      adminData.revenue.min = typeof revenueMin === 'number' ? revenueMin : null
    }

    if (siteData.hasOwnProperty('revenueMax')) {
      adminData.revenue.max = typeof revenueMax === 'number' ? revenueMax : null
    }
  }

  // RESEARCHER ONLY
  if (hasResearcher) {
    researcherData.companyName = siteData.companyName
  }

  return {
    action: siteData.action,
    locale: siteData.locale,
    introDescriptionWhat: siteData.introDescriptionWhat,
    introDescriptionWho: siteData.introDescriptionWho,
    systemExternalUrl: siteData.systemExternalUrl,
    ...vendorData,
    ...researcherData,
    ...adminData
  }
}

const _prepareMediaAsssetsForSave = (mediaAssetsObject) => {
  let cleanAssetsArray = []

  if (!mediaAssetsObject) {
    return []
  }

  Object.entries(mediaAssetsObject).forEach(([assetType, assetArray]) => {
    const formattedAssetsArray = assetArray.map((asset, index) => {
      const assetObject = {}

      SYSTEM_ASSET_INPUT_FIELDS.forEach((field) => {
        if (asset[field] !== null && asset[field] !== undefined) {
          assetObject[field] = asset[field]
        }
      })

      // Delete blob src
      if (assetObject.file?.src) {
        delete assetObject.file.src
      }

      if (asset.order !== index || typeof asset.order !== 'number') {
        assetObject.order = index

        if (!asset.action) {
          assetObject.action = 'EDIT'
        }
      }

      assetObject.type = assetType
      assetObject.subType = asset?.subType

      return assetObject
    })

    cleanAssetsArray = [...cleanAssetsArray, ...formattedAssetsArray]
    cleanAssetsArray = cleanAssetsArray.filter((asset) => asset.action)
  })

  return cleanAssetsArray.length ? cleanAssetsArray : undefined
}

export default function usePrepareSystemData() {
  const { user } = useUserContext()
  const userRole = user?.role
  const hasAdmin = isAdmin(userRole)

  const prepareSystemData = useCallback(
    (systemObject, mediaAssetsObject) => {
      const adminData = {}

      if (hasAdmin) {
        adminData.defaultCategory = systemObject.defaultCategory
        adminData.categories = systemObject.categories
        adminData.subCategories = systemObject.subCategories
        adminData.potentialCategories = systemObject.potentialCategories
        adminData.clientCompanySizeGuide = systemObject.clientCompanySizeGuide
        adminData.systemGuideProps = systemObject.systemGuideProps
        adminData.ratingBoost = systemObject.ratingBoost
        adminData.guideBoost = systemObject.guideBoost
        adminData.vendorId = systemObject.vendorId
        adminData.active = fixBoolean(systemObject.active)
      }

      const buyingIntentRevenueMin = fixNumber(systemObject.buyingIntentRevenueMin)
      const buyingIntentRevenueMax = fixNumber(systemObject.buyingIntentRevenueMax)

      if (
        systemObject.hasOwnProperty('buyingIntentRevenueMin') ||
        systemObject.hasOwnProperty('buyingIntentRevenueMax')
      ) {
        systemObject.buyingIntentRevenue = {}
      }

      if (systemObject.hasOwnProperty('buyingIntentRevenueMin')) {
        systemObject.buyingIntentRevenue.min =
          typeof buyingIntentRevenueMin === 'number' ? buyingIntentRevenueMin : null
      }

      if (systemObject.hasOwnProperty('buyingIntentRevenueMax')) {
        systemObject.buyingIntentRevenue.max =
          typeof buyingIntentRevenueMax === 'number' ? buyingIntentRevenueMax : null
      }
      return {
        branch: systemObject.branch,
        sector: systemObject.sector,
        support: systemObject.support,
        supportIncluded: fixBoolean(systemObject.supportIncluded),
        freeTrial: fixBoolean(systemObject.freeTrial),
        priceVisible: fixBoolean(systemObject.priceVisible),
        education: systemObject.education,
        mobileApps: systemObject.mobileApps,
        priceModel: systemObject.priceModel,
        clientCompanySize: systemObject.clientCompanySize,
        deployment: systemObject.deployment,
        systemStrengths: systemObject.systemStrengths,
        systemProps: systemObject.systemProps,
        systemAreas: systemObject.systemAreas,
        marketRegions: systemObject.marketRegions,
        languages: systemObject.languages,
        buyingIntentRevenue: systemObject.buyingIntentRevenue,
        ...adminData,
        systemSiteData: systemObject.systemSiteData?.map((siteData) => {
          return _prepareSystemSiteDataForSave(siteData, userRole)
        }),
        mediaAssets: _prepareMediaAsssetsForSave(mediaAssetsObject)
      }
    },
    [hasAdmin, userRole]
  )

  return { prepareSystemData }
}
