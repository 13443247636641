import { gql, useMutation } from '@apollo/client'

const DELETE_BADGE = gql`
  mutation DeleteBadge($badgeId: ID!) {
    appAdminDeleteBadge(badgeId: $badgeId)
  }
`

/**
 * Deletes Badge by ID
 * @returns {{deleteBadge: (function(*): Promise<(function(*, {badgeId: *}, *): Promise<boolean>)|*>), loading: boolean, error: ApolloError, data: any}}
 */
export function useDeleteBadge() {
  const [appAdminDeleteBadge, { loading, error, data }] = useMutation(DELETE_BADGE)

  const deleteBadge = async (badgeId) => {
    const response = await appAdminDeleteBadge({
      variables: { badgeId }
    })
    return response.data?.appAdminDeleteBadge
  }

  return {
    deleteBadge,
    loading,
    error,
    data
  }
}
