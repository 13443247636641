import { gql, useMutation } from '@apollo/client'

const UPDATE_BADGE = gql`
  mutation UpdateBadge($badgeId: ID!, $data: Json!, $shouldUpdateBucket: Boolean!) {
    appAdminUpdateBadge(
      badgeId: $badgeId
      data: $data
      shouldUpdateBucket: $shouldUpdateBucket
    )
  }
`

/**
 * used for creation of badges on admin
 * @returns {{updateBadge: (function({badgeId: *, data: *}): Promise<(function(*, *, *): Promise<void>)|*>), data: any, loading: boolean, error: ApolloError}}
 */
export function useUpdateBadge() {
  const [mutate, { data, loading, error }] = useMutation(UPDATE_BADGE)
  const updateBadge = async ({ badgeId, data, shouldUpdateBucket }) => {
    const response = await mutate({
      variables: {
        badgeId,
        data,
        shouldUpdateBucket: shouldUpdateBucket || false
      }
    })
    return response.data?.appAdminUpdateBadge
  }
  return { updateBadge, data, loading, error }
}
