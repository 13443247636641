import {
  ChartBarIcon,
  CollectionIcon,
  HomeIcon,
  UsersIcon,
  CogIcon,
  ChatAltIcon,
  SparklesIcon,
  BadgeCheckIcon
} from '@heroicons/react/outline'

const navigation = (pathname) => [
  {
    name: 'home',
    href: '/',
    icon: HomeIcon,
    current: pathname === '/',
    disabled: false
  },
  {
    name: 'systemGuide',
    href: '/leads-guide',
    icon: CollectionIcon,
    current: pathname === '/leads-guide',
    disabled: false,
    children: [
      {
        name: 'yourGuideLeads',
        href: '/leads-guide',
        current: pathname === '/leads-guide'
      },
      {
        name: 'guideInsights',
        href: '/leads-guide/insights',
        current: pathname === '/leads-guide/insights'
      }
    ]
  },
  {
    name: 'requests',
    href: '/leads-forms',
    icon: CollectionIcon,
    current: pathname === '/leads-forms',
    disabled: false
  },
  {
    name: 'buyingSignals',
    href: '/buying-intent',
    icon: CollectionIcon,
    current: pathname === '/buying-intent',
    disabled: false
  },

  {
    name: 'buyingIntents',
    href: '/buying-intent-v2',
    icon: SparklesIcon,
    current:
      pathname?.includes('/buying-intent-v2') ||
      pathname?.includes('/buying-intent-settings/') ||
      pathname?.includes('/buying-intent-insights/'),
    disabled: false,
    children: []
  },

  {
    name: 'system',
    href: '/system',
    icon: UsersIcon,
    current: pathname?.includes('/system'),
    children: []
  },
  {
    name: 'badges',
    href: '/badges',
    icon: BadgeCheckIcon,
    current: pathname?.includes('/badges'),
    children: false
  },
  {
    name: 'reviews',
    href: '/reviews',
    icon: ChatAltIcon,
    current: pathname?.includes('/reviews'),
    children: []
  },
  {
    name: 'vendorSettings',
    href: '/vendor-settings',
    icon: CogIcon,
    appAdmin: true,
    children: [
      {
        name: 'integration-settings',
        href: '/integration-settings',
        icon: CogIcon,
        current: pathname === '/integration-settings' ? true : false
      },
      {
        name: 'users',
        href: '/users',
        icon: CogIcon,
        current: pathname === '/users' ? true : false
      }
    ]
  }
]

const links = [
  {
    title: 'myLeadsCard.title',
    subTitle: 'myLeadsCard.subTitle',
    link: '/leads-guide',
    disabled: false,
    icon: CollectionIcon
  },
  {
    title: 'myLeadsCard2.title',
    subTitle: 'myLeadsCard2.subTitle',
    link: '/leads-forms',
    disabled: false,
    icon: CollectionIcon
  },
  {
    title: 'buyingIntentSignalsCard.title',
    subTitle: 'buyingIntentSignalsCard.subTitle',
    link: '/buying-intent',
    disabled: false,
    icon: CollectionIcon
  },
  {
    title: 'vendorSettingsCard.title',
    subTitle: 'vendorSettingsCard.subTitle',
    link: '/integration-settings',
    icon: CogIcon
  },
  {
    title: 'mySettingsCard.title',
    subTitle: 'mySettingsCard.subTitle',
    link: '/settings',
    icon: UsersIcon
  },
  {
    title: 'dataCard.title',
    subTitle: 'dataCard.subTitle',
    link: '/',
    disabled: true,
    underDevelopment: true,
    icon: ChartBarIcon
  },
  {
    title: 'analysisCard.title',
    subTitle: 'analysisCard.subTitle',
    link: '/',
    disabled: true,
    underDevelopment: true,
    icon: ChartBarIcon
  }
  // {
  //   title: 'Omdömen',
  //   subTitle: '',
  //   link: '/',
  //   disabled: true,
  //   underDevelopment: true,
  //   icon: StarIcon,
  // },
]

const userNavigation = [
  { name: 'userMenu.settings', href: '/settings' },
  { name: 'userMenu.logout', href: 'signOut' }
]

// Accepts { devOnly: true }
const adminNavigation = [
  { name: 'adminMenu.allRanGuides', href: '/admin-all-guides' },
  { name: 'adminMenu.allRequests', href: '/admin-all-forms' },
  { name: 'adminMenu.allReviews', href: '/admin-all-reviews' },
  { name: 'adminMenu.allUsers', href: '/admin-all-users' },
  { name: 'adminMenu.allBadges', href: '/admin-all-badges' },

  { name: 'adminMenu.latestSystems', href: '/admin-latest-systems' },

  { name: 'adminMenu.addCompany', href: '/admin-add-vendor' },
  { name: 'adminMenu.addUser', href: '/admin-add-user' },
  { name: 'adminMenu.addSystem', href: '/admin-add-system' },
  {
    name: 'adminMenu.addMultipleSystems',
    href: '/admin-add-systems'
  },
  { name: 'adminMenu.editCompany', href: '/admin-edit-vendor' },
  { name: 'adminMenu.editUser', href: '/admin-edit-user' },
  {
    name: 'adminMenu.manageMarketResearchers',
    href: '/admin-manage-market-researchers'
  },
  {
    name: 'adminMenu.manageGuides',
    href: '/admin-manage-guides'
  },
  {
    name: 'adminMenu.manageReviewTemplates',
    href: '/admin-manage-review-templates'
  },
  {
    name: 'adminMenu.goToMarketResearchersPage',
    href: '/market-researcher'
  },
  { name: 'adminMenu.badgesGenerator', href: '/badge-generator' },
  { name: 'adminMenu.deploys', href: '/admin-deploy' },
  { name: 'adminMenu.documentation', href: '/admin-documentation' },
  { name: 'adminMenu.redirects', href: '/admin-redirects' },
  { name: 'adminMenu.simulator', href: '/admin-simulator-guide-v2' },
  { name: 'adminMenu.special', href: '/admin-specials' },
  { name: 'adminMenu.adminAllBi', href: '/admin-all-bi' },
  { name: 'adminMenu.adminBwBi', href: '/admin-bw-bi' }
]

export { navigation, userNavigation, links, adminNavigation }
