import React, { useCallback, useState, useEffect } from 'react'
import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import { useTranslation, Trans } from 'react-i18next'

import Dialog from '../components/shared/dialog'
import Loader from '../components/loader'
import ManageSystem from '../components/manageSystem'
import AdminField from '../components/shared/admin-field'

import useFetchSystem from '../hooks/useFetchSystem'
import Button from '../components/shared/button'

function SystemDataPage() {
  const { t } = useTranslation('yourSystemProfile')
  const { system, systemLoading } = useFetchSystem()
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewUrl, setPreviewUrl] = useState('')
  const [availableDomains, setAvailableDomains] = useState([])

  useEffect(() => {
    if (!system) {
      return
    }

    setAvailableDomains(system.systemSiteData.map((siteData) => siteData.site.domain))
  }, [system])

  const setPreviewUrlFn = (domain) => {
    // const previewDomain = 'businesswith.local'
    // setPreviewUrl(`https://${previewDomain}/preview/system/?id=${system.id}`)
    setPreviewUrl(`https://${domain}/preview/system/?id=${system.id}`)
  }

  const setTabDisabled = useCallback((tab) => {
    return tab.categoryMissing
  }, [])

  return (
    <>
      <PageHeader pageName={t('data.header.title')}>
        <Trans
          ns="yourSystemProfile"
          i18nKey="data.header.subtitle"
          components={{ bold: <strong /> }}
        />
        <AdminField>
          <Button
            className="mt-4"
            mode="default"
            onClick={() => {
              setPreviewOpen(true)
            }}
            disabled={false}
          >
            Preview of system
          </Button>
        </AdminField>
      </PageHeader>

      <PageBody>
        {systemLoading ? (
          <Loader />
        ) : (
          <ManageSystem
            mode="UPDATE"
            systemData={system}
            setTabDisabled={setTabDisabled}
          />
        )}
      </PageBody>
      <Dialog
        className={'w-full !max-w-7xl h-9/10'}
        onClose={() => {
          setPreviewOpen(false)
        }}
        isOpen={previewOpen}
      >
        <div className="flex gap-2 mb-4">
          {availableDomains.map((domain) => {
            return (
              <Button
                key={domain}
                mode={previewUrl.includes(domain) ? 'primary' : 'default'}
                onClick={() => {
                  setPreviewUrlFn(domain)
                }}
              >
                {domain}
              </Button>
            )
          })}
        </div>
        {previewUrl && (
          <div>
            <iframe
              id="inlineFramePreview"
              title="Preview of system"
              src={previewUrl}
              className="w-full max-w-7xl h-[70vh] mx-auto mb-10 border border-gray-700 rounded-lg"
            />
          </div>
        )}
      </Dialog>
    </>
  )
}

export default SystemDataPage
