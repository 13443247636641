import React from 'react'

const Subsection = ({ title, description, children }) => {
  return (
    <div className="mb-4 mt-4">
      <label className="text-md font-medium text-gray-700">{title}</label>
      {description && <p className="text-sm text-gray-500">{description}</p>}
      {children}
    </div>
  )
}

Subsection.propTypes = {}

Subsection.defaultProps = {}

export default Subsection
