import i18n from 'i18next'

export const pageHasDocumentation = (page) => {
  return page !== 'index' && i18n.exists(`content:documentation.${page}`)
}

export const getPageDocumentation = (page, key) => {
  const translationKey = `content:documentation.${page}.${key}`
  return i18n.t(translationKey, { defaultValue: 'Documentation not available' })
}

export const getPageDocumentationKeys = (section) => {
  const resources = i18n.getResourceBundle(i18n.language, 'content')
  const sectionContent =
    (resources && resources?.documentation && resources?.documentation[section]) || {}

  const keys = Object.keys(sectionContent)
  const result = []

  keys
    .filter((key) => key.includes('title'))
    .forEach((titleKey) => {
      const titleNumber = titleKey.replace('title', '')
      const textKeys = keys
        .filter((key) => key.startsWith(`text${titleNumber}-`))
        .sort((a, b) => a.localeCompare(b))

      result.push(titleKey)
      result.push(...textKeys)
    })

  return result
}
