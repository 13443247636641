import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import usePrepareSystemData from './usePrepareSystemData'
import useOverlayNotification from './useOverlayNotification'

import { useUserContext } from '../contexts'
import { isAdmin, isResearcher } from '../utils'

import {
  SYSTEM_FRAGMENT_USER,
  SYSTEM_FRAGMENT_ADMIN,
  SYSTEM_FRAGMENT_RESEARCHER
} from '../constants/gql/systems'
import useBuyingIntent from '../components/manageSystem/hooks/useBuyingIntent'

const UPDATE_SYSTEM_GQL = gql`
  mutation UpdateSystemDataUser($id: ID!, $systemData: UpdateSystemV2Input) {
    appUpdateSystem(id: $id, systemData: $systemData) {
      ${SYSTEM_FRAGMENT_USER}
    }
  }
`

const UPDATE_SYSTEM_ADMIN_GQL = gql`
  mutation UpdateSystemDataAdmin($id: ID!, $systemData: UpdateSystemV2AdminInput, $buyingIntentData: Json) {
    appUpdateSystemAdmin(id: $id, systemData: $systemData, buyingIntentData: $buyingIntentData) {
      ${SYSTEM_FRAGMENT_ADMIN}
    }
  }
`

const UPDATE_SYSTEM_RESEARCHER_GQL = gql`
  mutation UpdateSystemDataMR($id: ID!, $systemData: UpdateSystemInputMR!) {
    mrUpdateSystem(id: $id, systemData: $systemData) {
      ${SYSTEM_FRAGMENT_RESEARCHER}
    }
  }
`

export default function useUpdateSystem() {
  const { user } = useUserContext()
  const hasAdmin = isAdmin(user?.role)
  const hasResearcher = isResearcher(user?.role)
  const { prepareSystemData } = usePrepareSystemData()
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()
  const { collectUpdateData } = useBuyingIntent()
  const [updateSystemMutation, { loading: loadingUser }] = useMutation(UPDATE_SYSTEM_GQL)
  const [updateSystemAdminMutation, { loading: loadingAdmin }] = useMutation(
    UPDATE_SYSTEM_ADMIN_GQL
  )
  const [updateSystemResearcherMutation, { loading: loadingResearcher }] = useMutation(
    UPDATE_SYSTEM_RESEARCHER_GQL
  )

  const updateSystem = useCallback(
    (systemId, changedFields, mediaAssetsData, biConfig, onCompleted) => {
      const preparedSystemData = prepareSystemData(changedFields, mediaAssetsData)
      const biConfigPrepareData = collectUpdateData(
        biConfig.biConfigChangedSiteIds,
        biConfig.biConfigFormState,
        biConfig.biConfigCategoryState
      )

      const requestData = {
        variables: {
          id: systemId,
          systemData: preparedSystemData,
          buyingIntentData: { ...biConfigPrepareData }
        },
        onCompleted: () => {
          showSuccessNotification()
          onCompleted?.()
        },
        onError: showErrorNotification
      }

      // Admin mutation
      if (hasAdmin) {
        return updateSystemAdminMutation(requestData)
      }

      // Researcher mutation
      if (hasResearcher) {
        return updateSystemResearcherMutation(requestData)
      }

      // User mutation
      return updateSystemMutation(requestData)
    },
    [
      prepareSystemData,
      hasAdmin,
      hasResearcher,
      updateSystemMutation,
      updateSystemAdminMutation,
      updateSystemResearcherMutation,
      showSuccessNotification,
      showErrorNotification
    ]
  )

  return {
    updateSystem,
    loading: loadingUser || loadingAdmin || loadingResearcher
  }
}
